/**
 * 横断図拡大ダイアログ部品
 * ------------------------------------------------------------------------------
 * @LIS 2018 新規作成
 * ==============================================================================
 */
export default class {

  /**
   * コンストラクタ
   */
  constructor() {
    // 横断図拡大ダイアログの作成
    this.crossZoomDlgBk = $("<div id=\"crossZoomDlgBk\"></div>").appendTo("body");
    this.crossZoomDlg = $("<div id=\"crossZoomDlg\"></div>").appendTo(this.crossZoomDlgBk);
    this.crossZoomHead = $("<div id=\"crossZoomHead\"></div>").appendTo(this.crossZoomDlg);
    this.crossZoomFrame = $("<div id=\"crossZoomFrame\"></div>").appendTo(this.crossZoomDlg);
    this.crossZoom = $("<div id=\"crossZoom\" class=\"cross-frame\"></div>").appendTo(this.crossZoomFrame);
    this.crossZoomContent = $("<div id=\"crossZoomContent\"></div>").appendTo(this.crossZoom);
    this.crossZoomRangeFrame = $("<div id=\"crossZoomRangeFrame\"></div>").appendTo(this.crossZoomDlg);
    this.crossZoomRange = $("<input id=\"crossZoomRange\" type=\"range\" \/>").appendTo(this.crossZoomRangeFrame);
    this.crossZoomFoot = $("<div id=\"crossZoomFoot\"></div>").appendTo(this.crossZoomDlg);

    // クローズボタンの実装
    this.crossZoomDlgClose = $("<img src=\"img/close.png\">").appendTo(this.crossZoomHead);
    this.crossZoomDlgClose.on("click", {
      win: this.crossZoomdlgBk
    }, this.close);

    $("#crossZoomDlg").setExtVal("instance", this);
  }

  /**
   * 横断図拡大ダイアログの表示
   */
  show(levelList) {
    $("#crossZoomDlg").setExtVal("levelList", levelList);
    var dlgInst = $("#crossZoomDlg").getExtVal("instance");

    $("#crossZoomDlgBk").css("display", "block");

    //幅中心に持っていく
    var scrWidth = $(window).width();
    var dlgWidth = $("#crossZoomDlg").width();
    var xpos = (scrWidth - dlgWidth) / 2;
    $("#crossZoomDlg").css("left", xpos + "px");

    //高さ中心に持っていく
    var scrHeight = $(window).height();
    var dlgHeight = $("#crossZoomDlg").height();
    var ypos = (scrHeight - dlgHeight) / 2;
    $("#crossZoomDlg").css("top", ypos + "px");

    // スライダーバー初期化
    $("#crossZoomRange").attr("min", 0);
    $("#crossZoomRange").attr("max", (levelList.length - 1));
    $("#crossZoomRange").val((levelList.length - 1));
    $("#crossZoomRange").on("change", dlgInst.changeLevelDraw);
  }

  /**
   * 描画水位の変更
   */
  changeLevelDraw(event) {
    var levelList = $("#crossZoomDlg").getExtVal("levelList");
    var index = $("#crossZoomRange").val() - 0;
    //ADD 特殊横断
    var dlgInst = $("#obsDlg").getExtVal("instance");

    try {
      //DEL 特殊横断
      //fillOldRiver(index, "#crossZoomContent");
      //ADD 特殊横断 >>
      if (isEmpty(dlgInst.spSurface)) {
        fillOldRiver(index, "#crossZoomContent");
      } else {
        // 特殊横断の場合
        fillOldRiverSp(index, "#crossZoomContent");
      }
      //ADD 特殊横断 <<
    } catch (e) {
      console.log("水位描失敗:", index, levelList, e);
    }
  }

  /**
   * 横断図拡大ダイアログの非表示
   */
  close() {
    $("#crossZoomDlgBk").css("display", "none");
  }

  /**
   * ダイアログが開いているかどうか確認
   * @return {boolean} true(開いている)、false(閉じている)
   * ----------------------------------------------------------------------------
   */
  isOpen() {
    if ($("#crossZoomDlgBk").css("display") == "block") {
      return true;
    }
    return false;
  }
}