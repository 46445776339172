import { DateFormatUtil } from "./util.js";
import LevelListDlg from "./levellistdlg.js";
import CctvZoomDlg from "./cctvzoomdlg.js";
import Hydoro from "./hydro.js";
import PicDlg from "./obspicdlg.js";
//import hydro from "./hydro.js";
import HydroZoomDlg from "./hydrozoomdlg.js";
import CrossZoomDlg from "./crosszoomdlg.js";
import TwitterErrDlg from "./twittererrdlg.js";
import { Requester } from "./requester.js";
import { _CNST } from "./const.js";
import PageLog from "./pagelog.js";
import { point } from "../../html/leaflet/leaflet-src.js";

var _levelListDlg = null;
var _cctvZoomDlg = null;
var _hydroZoomDlg = null;
var _crossZoomDlg = null;
var _twitterErrorDlg = null;
var _timerId = 0;

/**
 * 観測所ダイアログクラス
 * ------------------------------------------------------------------------------
 * @LIS 20180425 新規作成
 * ==============================================================================
 */
export default class {
  /**
   * コンストラクタ
   * ----------------------------------------------------------------------------
   */
  constructor(map, config) {
    this.mode = null;
    this.map = map;
    this.obs = null;
    this.latlng = null;
    this.tabOrder = 0;
    this.levelList = null;
    this.config = config;
    this.markerA = null;
    this.markerB = null;
    this.line = null;
    this.surface = null; //横断定数データ
    this.picDlg = new PicDlg();
    this.type = null;
    this.msgBox = null;
    this.spSurface = null;

    var bk = $('<div id="obsDlgBk"></div>', {
      css: {
        "z-index": 2000,
      },
    }).appendTo("body");
    bk.setExtVal("instance", this);

    bk.load("./obsdlg.html", function (response, status, xhr) {
      var inst = $("#obsDlgBk").getExtVal("instance");
      //観測所アイコン(通常)
      inst.aPointIcon = L.icon({
        iconUrl: inst.config.crossSettings.point.a,
        iconSize: [20, 30],
        iconAnchor: [10, 28],
        popupAnchor: [0, -31],
      });
      //観測所アイコン(通常)
      inst.bPointIcon = L.icon({
        iconUrl: inst.config.crossSettings.point.b,
        iconSize: [20, 30],
        iconAnchor: [10, 28],
        popupAnchor: [0, -31],
      });

      // 観測値リストオブジェクトの生成
      _levelListDlg = new LevelListDlg();

      // cctv画像拡大ダイアログのオブジェクト作成
      _cctvZoomDlg = new CctvZoomDlg();

      // ハイドログラフ拡大ダイアログのオブジェクト作成
      _hydroZoomDlg = new HydroZoomDlg();

      // 横断図拡大ダイアログのオブジェクト作成
      _crossZoomDlg = new CrossZoomDlg();

      // Twitter投稿エラーダイアログのオブジェクト作成
      _twitterErrorDlg = new TwitterErrDlg();

      //写真ボタン
      $("#obsPic").on(
        "click",
        {
          dlg: inst,
        },
        inst.clickObsPic
      );
      //更新ボタン
      $("#obsDlgRenew").on(
        "click",
        {
          dlg: inst,
        },
        inst.clickRenew
      );
      //閉じるボタン
      $("#obsDlgClose").on(
        "click",
        {
          dlg: inst,
        },
        inst.clickDlgClose
      );
      //横断図ボタン
      $("#crossBtn").on(
        "click",
        {
          dlg: inst,
        },
        inst.clickCrossBtn
      );
      //ハイドロボタン
      $("#hydroBtn").on(
        "click",
        {
          dlg: inst,
        },
        inst.clickHydroBtn
      );
      //カメラ現状ボタン
      $("#camNowBtn").on(
        "click",
        {
          dlg: inst,
        },
        inst.clickCamNowBtn
      );
      //カメラ平常ボタン
      $("#camDefBtn").on(
        "click",
        {
          dlg: inst,
        },
        inst.clickCamVtrBtn
      );
      //観測値一覧ボタン
      $("#numListBtn").on(
        "click",
        {
          dlg: inst,
        },
        inst.clickListBtn
      );

      //LINEボタン
      $("#lineBtn").on(
        "click",
        {
          dlg: inst,
        },
        inst.clickLineBtn
      );

      //LINEボタンカーソル変更
      $("#lineBtn").hover(function () {
        document.getElementById("lineBtn").style.cursor = "pointer";
      });

      //Twitterボタン
      $("#twitterBtn").on(
        "click",
        {
          dlg: inst,
        },
        inst.clickTwitterBtn
      );

      //Twitterボタンカーソル変更
      $("#twitterBtn").hover(function () {
        document.getElementById("twitterBtn").style.cursor = "pointer";
      });

      //Facebookボタン
      $("#facebookBtn").on(
        "click",
        {
          dlg: inst,
        },
        inst.clickFacebookBtn
      );

      //横断図拡大ボタン
      $("#content").on(
        "click",
        {
          dlg: inst,
        },
        inst.dispCrossZoomDlg
      );
      //ハイドロ拡大ボタン
      $("#hydroGraph").on(
        "click",
        {
          dlg: inst,
        },
        inst.dispHydroZoomDlg
      );
      //カメラ拡大ボタン
      $("#cctvFrame").on(
        "click",
        {
          dlg: inst,
        },
        inst.dispCctvZoomDlg
      );

      //品管アイコン
      $("#qtImg").on(
        "click",
        {
          dlg: inst,
        },
        inst.clickQtImg
      );

      $("#toolTip").on(
        "click",
        {
          dlg: inst,
        },
        inst.clickQtToolTip
      );

      //オブジェクトをobsDlgエレメントに登録
      $("#obsDlg").setExtVal("instance", inst);
    });

    this.toolTip = $("#toolTip");
  }

  /**
   * ダイアログ表示処理 (危機管理水位計)
   * パラメータのオブジェクトは以下の通りとなる。
   * prm:
   *   obs: 観測所情報（観測所JSONのproperties）
   *   requester: APIレクエストオブジェクト
   *   latlng: 観測所の緯度経度（観測所JSONの緯度経度情報）
   *   type: 危機管理(swin) or テレメータ(TM)
   * @param {Object} prm 表示パラメータ
   * ----------------------------------------------------------------------------
   */
  showObs(prm) {
    this.mode = "OBS";
    this.tabOrder = 1;
    this.levelList = null;
    this.orgParam = prm;
    this.type = prm.type;
    this.hydoro = null;
    this.obs = prm.obs;
    this.latlng = prm.latlng;
    this.msgBox = prm.msgBox;
    this.spSurface = prm.spSurface;

    $("#obsDlgBk").css("display", "block");

    //観測所名の表示
    $("#obsDlgObsName").html(prm.obs.name);
    if (prm.obs.kana)
      $("#obsDlgObsKana").html(prm.obs.kana);
    else
      $("#obsDlgObsKana").html("　");

    //横断線の描画(特殊横断の場合は表示させない)
    if (isEmpty(this.spSurface)) {
      this.riverWidth = this.getRiverWidth(this.obs);
      var latlangs = this.getABPoint(
        this.obs,
        this.config.crossSettings,
        this.riverWidth
      );

      this.line = L.polyline(latlangs, {
        color: this.config.crossSettings.onMapLine.color,
        weight: this.config.crossSettings.onMapLine.weight,
        opacity: this.config.crossSettings.onMapLine.opacity,
      }).addTo(this.map);

      this.markerA = L.marker(latlangs[0], {
        icon: this.aPointIcon,
      }).addTo(this.map);
      this.markerB = L.marker(latlangs[1], {
        icon: this.bPointIcon,
      }).addTo(this.map);
    }

    //表示切替（観測所写真ボタン）
    if (this.obs.pic != null && this.obs.pic != "") {
      $("#obsPic").css("display", "inline");
    } else {
      $("#obsPic").css("display", "none");
    }

    //表示切替（横断図ボタン）
    if (this.obs.dispSurfFlag == 1) {
      //特殊横断の場合は測量横断データ取得時に表示判断するため非表示
      if (!isEmpty(this.spSurface)) {
        $("#crossBtn").css("display", "none");
      } else {
        $("#crossBtn").css("display", "inline");
      }
    } else {
      $("#crossBtn").css("display", "none");
    }

    //ハイドロボタン
    $("#hydroBtn").css("display", "inline");

    //表示切替（CCTVカメラ画像現状）
    if (this.obs.latestUrl.length != 0 && this.obs.latestUrl != null) {
      $("#camNowBtn").css("display", "inline");
    } else {
      $("#camNowBtn").css("display", "none");
    }

    //表示切替（CCTVカメラ画像平常）
    if (this.obs.usualUrl.length != 0 && this.obs.usualUrl != null) {
      $("#camDefBtn").css("display", "inline");
    } else {
      $("#camDefBtn").css("display", "none");
    }

    //観測値一覧
    $("#numListBtn").css("display", "inline");

    //LINEで送る
    $("#lineBtn").css("display", "inline");
    //Tweet
    $("#twitterBtn").css("display", "inline");
    //Facebook シェア
    $("#facebookBtn").css("display", "inline");

    this.hydoro = new Hydoro({
      htmlId: "#hydoro",
      // 英語化→ph2 簡易水位→swin
      id: "swin",
    });

    // 観測水位の取得、ダイアログの表示
    this.getLevels(prm);
  }

  /**
   * ダイアログ表示処理 (テレメータ水位観測所)
   * パラメータのオブジェクトは以下の通りとなる。
   * prm:
   *   obs: 観測所情報（観測所JSONのproperties）
   *   requester: APIレクエストオブジェクト
   *   latlng: 観測所の緯度経度（観測所JSONの緯度経度情報）
   *   type: 危機管理(swin) or テレメータ(TM)
   * @param {Object} prm 表示パラメータ
   * ----------------------------------------------------------------------------
   */
  showTmObs(prm) {
    this.mode = "OBS";
    this.tabOrder = 1;
    this.levelList = null;
    this.orgParam = prm;
    this.type = prm.type;
    this.hydoro = null;
    this.obs = prm.obs;
    this.latlng = prm.latlng;
    this.msgBox = prm.msgBox;
    this.spSurface = prm.spSurface;

    $("#obsDlgBk").css("display", "block");

    // 観測所名の表示
    $("#obsDlgObsName").html(prm.obs.obsName);

    // ハイドロの設定
    this.hydoro = new Hydoro({
      htmlId: "#hydoro",
      // 英語化→ph2 簡易水位→swin
      id: "ph2",
    });

    // 観測水位の取得、ダイアログの表示
    this.getTmObsData(this.obs);
  }

  /**
   * ダイアログ表示処理 (危機管理水位計)
   * パラメータのオブジェクトは以下の通りとなる。
   * prm:
   *   cctv: 観測所情報（CCTVJSONのproperties）
   *   requester: APIレクエストオブジェクト
   *   latlng: 観測所の緯度経度（観測所JSONの緯度経度情報）
   *   type: "swin"
   * @param {Object} prm 表示パラメータ
   * ----------------------------------------------------------------------------
   */
  showCctv(prm) {
    this.mode = "CCTV";
    this.tabOrder = 2;
    this.levelList = null;
    this.obs = prm.cctv;
    this.latlng = prm.latlng;
    this.type = prm.type;
    this.msgBox = prm.msgBox;

    $("#obsDlgBk").css("display", "block");
    $("#obsDlgObsName").html(prm.cctv.name);
    if (prm.cctv.kana)
      $("#obsDlgObsKana").html(prm.cctv.kana);
    else
      $("#obsDlgObsKana").html("　");
    $("#cctvFrameTime").css("display", "none");
    $("#cctvZoomDlg").setExtVal("cctvTime", null);

    //観測所画像アイコン
    $("#obsPic").css("display", "none");

    //表示切替（横断図ボタン）
    $("#crossBtn").css("display", "none");

    //ハイドロボタン
    $("#hydroBtn").css("display", "none");

    //表示切替（CCTVカメラ画像現状）
    if (this.obs.latestUrl != null && this.obs.latestUrl.length != 0) {
      $("#camNowBtn").css("display", "inline");
    } else {
      $("#camNowBtn").css("display", "none");
    }

    //表示切替（CCTVカメラ画像平常）
    if (this.obs.usualUrl != null && this.obs.usualUrl.length != 0) {
      $("#camDefBtn").css("display", "inline");
    } else {
      $("#camDefBtn").css("display", "none");
    }

    //観測値一覧
    $("#numListBtn").css("display", "none");

    //LINEで送る
    $("#lineBtn").css("display", "inline");
    //Tweet
    $("twitterBtn").css("display", "inline");
    //Facebook シェア
    $("#facebookBtn").css("display", "inline");

    //ダイアログの表示
    this._dlgDraw();

    // ページログの作成処理
    //this.createPageLog(this.config.pageLogUrl.cctvUrl, prm.cctv.code);
    PageLog.log(this.config.pageLogUrl.cctvUrl, prm.cctv.code);
  }

  /**
   * ダイアログ表示処理 (テレメータ)
   * パラメータのオブジェクトは以下の通りとなる。
   * prm:
   *   cctv: 観測所情報（CCTVJSONのproperties）
   *   requester: APIレクエストオブジェクト
   *   latlng: 観測所の緯度経度（観測所JSONの緯度経度情報）
   *   type: "TM"
   * @param {Object} prm 表示パラメータ
   * ----------------------------------------------------------------------------
   */
  showTmCctv(prm) {
    this.mode = "CCTV";
    this.tabOrder = 2;
    this.levelList = null;
    this.obs = prm.cctv;
    this.latlng = prm.latlng;
    this.type = prm.type;
    this.msgBox = prm.msgBox;

    $("#obsDlgBk").css("display", "block");
    $("#obsDlgObsName").html(this.obs.cctvName);
    $("#obsDlgObsKana").html(this.obs.cctvKana);

    var _tmReq = new Requester(this.config.tmApiUrl);
    var tmSbmtr = _tmReq.create();

    tmSbmtr.register(
      "GetCctvDetail",
      {
        cctvCode: this.obs.cctvCode,
      },
      async function (data) {
        var dlgInst = $("#obsDlg").getExtVal("instance");

        //各アイコンの表示判定
        //観測所画像アイコン
        $("#obsPic").css("display", "none");

        //表示切替（横断図ボタン）
        $("#crossBtn").css("display", "none");

        //ハイドロボタン
        $("#hydroBtn").css("display", "none");

        //表示切替（CCTVカメラ画像現状）
        if (data.cctv.latestUrl != null && data.cctv.latestUrl.length != 0) {
          $("#camNowBtn").css("display", "inline");
        } else {
          $("#camNowBtn").css("display", "none");
        }
        //表示切替（CCTVカメラ画像平常）
        if (data.cctv.usualUrl != null && data.cctv.usualUrl.length != 0) {
          $("#camDefBtn").css("display", "inline");
        } else {
          $("#camDefBtn").css("display", "none");
        }

        //観測値一覧
        $("#numListBtn").css("display", "none");

        //LINEで送る
        $("#lineBtn").css("display", "inline");
        //Tweet
        $("#twitterBtn").css("display", "inline");
        //Facebook シェア
        $("#facebookBtn").css("display", "inline");

        // TMCCTVのURL設定 ★★★★★★
        var obsInfo = await dlgInst.getKwbObsData(dlgInst.config, data.obs.obsFcd);
        data = dlgInst.createTmCctvUrl(data, obsInfo);

        //TMCCTV詳細情報の設定
        $("#obsDlg").setExtVal("tmCctvData", data);

        //ダイアログの表示
        dlgInst._dlgDraw();
      }
    );
    tmSbmtr.submit(this);

    // ページログの作成処理
    //this.createPageLog(this.config.pageLogUrl.cctvUrl, this.obs.cctvCode);
    PageLog.log(this.config.pageLogUrl.tmCctvUrl, this.obs.cctvCode);
  }

  /**
   * ダイアログ描画処理
   * ----------------------------------------------------------------------------
   */
  _dlgDraw() {
    //ダイアログの表示位置極め
    var scrWidth = $(window).width();
    var scrHeight = $(window).height();
    var dlgWidth = $("#obsDlg").width();
    var dlgHeight = $("#obsDlg").height();
    var top = 0;
    var left = 0;

    if (scrWidth <= scrHeight) {
      left = (scrWidth - dlgWidth) / 2;
      $("#obsDlg").css("top", "");
      $("#obsDlg").css("bottom", "4px");
      $("#obsDlg").css("left", left);
    } else {
      top = (scrHeight - dlgHeight) / 2;
      left = scrWidth / 2 + (scrWidth / 2 - dlgWidth) / 2;
      $("#obsDlg").css("bottom", "");
      $("#obsDlg").css("top", top);
      $("#obsDlg").css("left", left);
    }

    var contentHeight = $("#content").height();
    var h = contentHeight - $("#levelRangeFrame").height();
    $("#cross").css("height", h);

    setTimeout(function () {
      var dlgInst = $("#obsDlg").getExtVal("instance");
      dlgInst.dispContent();
    }, 400);
  }

  /**
   * 再描画処理
   * 縦横回転や、画面サイズ変更などで発生するイベントで呼び出される。
   * @param {Object} event イベントオブジェクト
   * ----------------------------------------------------------------------------
   */
  redraw(event) {
    var dlgInst = $("#obsDlg").getExtVal("instance");
    // 更新フラグ削除
    $("#obsDlg").setExtVal("renew", null);

    setTimeout(
      function (inst) {
        inst._dlgDraw();
        // cctv拡大ダイアログの表示確認
        if (_cctvZoomDlg.isOpen()) {
          _cctvZoomDlg.redraw();
        }
        // ハイドログラフ拡大ダイアログの表示確認
        if (_hydroZoomDlg.isOpen()) {
          _hydroZoomDlg.redraw();
          dlgInst.dispHydroZoomDlg();
        }
        // 横断図拡大ダイアログの表示確認
        if (_crossZoomDlg.isOpen()) {
          // ダイアログの再描画
          dlgInst.dispCrossZoomDlg();
        }
      },
      200,
      dlgInst
    );

    // 水位計画像再描画
    dlgInst.picDlg.redraw();
  }

  /**
   * 選択された状態に合わせて、コンテンツを表示する。
   * @param {string} cctvLoad cctv読み込みフラグ ("cctvLoad" or null)
   * ----------------------------------------------------------------------------
   */
  dispContent(cctvLoad) {
    var dlgInst = $("#obsDlg").getExtVal("instance");
    //コンテンツを一回全て非表示にする。
    if (dlgInst.tabOrder != 5) {
      $("#crossBtn").attr("src", "img/cross.png");
      $("#hydroBtn").attr("src", "img/hydro.png");
      $("#camNowBtn").attr("src", "img/cctv_now.png");
      $("#camDefBtn").attr("src", "img/cctv_vtr.png");
      $("#numListBtn").attr("src", "img/list.png");
      $("#lineBtn").attr("src", "img/line.png");
      $("#twitterBtn").attr("src", "img/twitter.png");
      $("#facebookBtn").attr("src", this.createFacebookLink);

      $("#contentTitle").css("display", "none");
      $("#content").css("display", "none");
      $("#hydroGraph").css("display", "none");
      $("#cctvFrame").css("display", "none");
    }
    switch (dlgInst.tabOrder) {
      //横断図
      case 0:
        $("#crossBtn").attr("src", "img/cross_on.png");
        $("#contentTitle").css("display", "flex");
        $("#content").css("display", "flex");
        if (!isEmpty(dlgInst.spSurface)) {
          //特殊横断の場合はスクロールバーを非表示
          $("div#content.cross-frame").css("overflow-x", "hidden");
        } else {
          $("div#content.cross-frame").css("overflow-x", "scroll");
        }
        $("#levelRangeFrame").css("display", "block");
        $("#lineBtn").css("display", "inline");
        $("#twitterBtn").css("display", "inline");
        $("#facebookBtn").css("display", "inline");
        dlgInst.dispCross();
        break;
      //ハイドログラフ
      case 1:
        $("#hydroBtn").attr("src", "img/hydro_on.png");
        $("#contentTitle").css("display", "flex");
        $("#levelRangeFrame").css("display", "none");
        $("#lineBtn").css("display", "inline");
        $("#twitterBtn").css("display", "inline");
        $("#facebookBtn").css("display", "inline");
        dlgInst.dispHydroGraph();
        break;
      //CCTV現状
      case 2:
        $("#camNowBtn").attr("src", "img/cctv_now_on.png");
        $("#cctvFrame").css("display", "flex");
        $("#levelRangeFrame").css("display", "none");
        $("#lineBtn").css("display", "inline");
        $("#twitterBtn").css("display", "inline");
        $("#facebookBtn").css("display", "inline");
        dlgInst.dispCCTV("latest", cctvLoad);
        break;
      //CCTV平常
      case 3:
        $("#camDefBtn").attr("src", "img/cctv_vtr_on.png");
        $("#cctvFrame").css("display", "flex");
        $("#levelRangeFrame").css("display", "none");
        $("#lineBtn").css("display", "inline");
        $("#twitterBtn").css("display", "inline");
        $("#facebookBtn").css("display", "inline");
        dlgInst.dispCCTV("usual", cctvLoad);
        break;
      //観測値
      case 4:
        $("#numListBtn").attr("src", "img/list_on.png");
        $("#cross").html("");
        $("#levelRangeFrame").css("display", "none");
        $("#lineBtn").css("display", "none");
        $("#twitterBtn").css("display", "none");
        $("#facebookBtn").css("display", "none");
        dlgInst.dispLevelList();
        break;
    }
  }

  /**
   * 観測所データSetter
   * ----------------------------------------------------------------------------
   */
  set obsData(obs) {
    this.obs = obs;
  }

  /**
   * 観測所データGetter
   * ----------------------------------------------------------------------------
   */
  get obsData() {
    return this.obs;
  }

  /**
   * 観測所緯度経度Setter
   * ----------------------------------------------------------------------------
   */
  set latlngData(latlng) {
    this.latlng = latlng;
  }

  /**
   * 観測所緯度経度Getter
   * ----------------------------------------------------------------------------
   */
  get latlngData() {
    return this.latlng;
  }

  /**
   * ダイアログが開いているかどうか確認する
   * @return {boolean} true(開いている)、false(閉じている)
   * ----------------------------------------------------------------------------
   */
  isOpen() {
    if ($("#obsDlgBk").css("display") == "block") {
      return true;
    }
    return false;
  }

  /**
   * 水位情報取得 (危機管理水位計)
   * @param {Object} prm ダイアログオープンパラメータ
   * ----------------------------------------------------------------------------
   */
  getLevels(prm) {
    var sbmtr = prm.requester.create();
    sbmtr.register(
      "getLevels",
      {
        obscode: prm.obs.code,
        date: prm.date,
      },
      function (data) {
        var dlgInst = $("#obsDlg").getExtVal("instance");
        //levelsが取れない場合は終了する。
        if (data == null || data.levels.length == 0) {
          dlgInst.clickDlgClose(null);
          //メッセージ表示処理
          dlgInst.msgBox.createErrorBox(dlgInst.config.noLevelsDataMsg);
          return;
        }
        dlgInst.levelList = data.levels;

        //時刻・水位およびスライダーバー初期化
        $("#levelRange").attr("min", 0);
        $("#levelRange").attr("max", dlgInst.levelList.length - 1);
        $("#levelRange").val(dlgInst.levelList.length - 1);
        $("#levelRange").on(
          "change",
          {
            obsType: "swin",
          },
          dlgInst.changeLevel
        );

        //タイトルバーの日時設定
        var obsDate = new Date(dlgInst.levelList[0].date);
        if (isNaN(obsDate)) {
          obsDate = new Date(
            dlgInst.levelList[0].date.replace(/-/g, "/").replace(/T/g, " ")
          );
        }
        //console.log("DATE debug:", dlgInst.levelList[0].date, obsDate);
        $("#titleDateTime").html(
          DateFormatUtil.format(obsDate, "YYYY/MM/DD hh:mm")
        );

        //タイトルバーの観測値設定
        var orgVal = dlgInst.levelList[0].level;
        if (orgVal == null) {
          $("#titleCap").html("");
          $("#titleLevel").html("欠測");
        } else {
          $("#titleCap").html("堤防天端からの高さ");
          var levelValue = dlgInst.levelList[0].level - dlgInst.obs.fladLevel;
          levelValue = levelValue * 100;
          levelValue = Math.round(levelValue) / 100;
          levelValue = levelValue.toFixed(2);
          $("#titleLevel").html(levelValue + "m");
        }
        //タイトルバーの色変更
        if (orgVal == null) {
          $("#contentTitle").removeClass("flad");
          $("#contentTitle").removeClass("warn");
          $("#contentTitle").removeClass("start");
          $("#contentTitle").removeClass("normal");
          $("#contentTitle").addClass("missing");
        } else if (orgVal >= dlgInst.obs.fladLevel) {
          $("#contentTitle").addClass("flad");
          $("#contentTitle").removeClass("warn");
          $("#contentTitle").removeClass("start");
          $("#contentTitle").removeClass("normal");
          $("#contentTitle").removeClass("missing");
        } else if (
          dlgInst.obs.warnLevel != null &&
          orgVal >= dlgInst.obs.warnLevel
        ) {
          $("#contentTitle").removeClass("flad");
          $("#contentTitle").addClass("warn");
          $("#contentTitle").removeClass("start");
          $("#contentTitle").removeClass("normal");
          $("#contentTitle").removeClass("missing");
        } else if (orgVal >= dlgInst.obs.startLevel) {
          $("#contentTitle").removeClass("flad");
          $("#contentTitle").removeClass("warn");
          $("#contentTitle").addClass("start");
          $("#contentTitle").removeClass("normal");
          $("#contentTitle").removeClass("missing");
        } else {
          $("#contentTitle").removeClass("flad");
          $("#contentTitle").removeClass("warn");
          $("#contentTitle").removeClass("start");
          $("#contentTitle").addClass("normal");
          $("#contentTitle").removeClass("missing");
        }

        //品質フラグによるアイコンの表示切り替え
        if (dlgInst.levelList[0].qt != null && dlgInst.levelList[0].qt != 0) {
          $("#qtImg").css("display", "inline");
        } else {
          $("#qtImg").css("display", "none");
        }

        //横断データ取得
        var sfUrl =
          dlgInst.config.crossSettings.jsonUrl +
          "/" +
          dlgInst.obs.code +
          ".json";

        $.ajax({
          url: sfUrl,
        })
          .then((gjson, textResponse, jqXHR) => {
            if (gjson.sectionData == null) {
              dlgInst.surface = [];
              dlgInst.surfaceLeftLim = null;
              dlgInst.surfaceRghtLim = null;
              return;
            }
            var tx = 0;
            var values = gjson.sectionData.map((rec) => {
              //tx += rec.x;
              var val = {
                drawLength: rec.x,
                drawHeight: rec.y,
              };
              return val;
            });
            dlgInst.surface = values;
            dlgInst.surfaceLeftLim = gjson.surfaceLeftLim;
            dlgInst.surfaceRghtLim = gjson.surfaceRghtLim;
            //表示切替（横断図ボタン）
            if (!isEmpty(dlgInst.spSurface) && dlgInst.surface.length > 0) {
              $("#crossBtn").css("display", "inline");
            }
          })
          .catch((jqXHR, textStatus, errorThrown) => {
            dlgInst.surface = [];
            dlgInst.surfaceLeftLim = null;
            dlgInst.surfaceRghtLim = null;
          })
          .then(() => {
            //ハイドログラフのパラメータ作成
            var hydroPrm = dlgInst.createHydroPrm();
            $("#obsDlg").setExtVal("hydroPrm", hydroPrm);

            //横断図パラメータ作成
            var riverInfo = dlgInst.createCrossPrm("obs");
            $("#obsDlg").setExtVal("riverInfo", riverInfo);

            //ダイアログ表示
            dlgInst._dlgDraw();
          });
      }
    );
    sbmtr.submit(this);

    // ページログの作成処理
    PageLog.log(this.config.pageLogUrl.obsUrl, prm.obs.code);
  }

  /**
   * 水位スライダーバー変更時イベント
   * @param {Object} event
   */
  changeLevel(event) {
    var dlgInst = $("#obsDlg").getExtVal("instance");
    var index = $("#levelRange").val() - 0;
    try {
      //DEL 特殊横断
      //fillOldRiver(index, "#cross");
      //ADD 特殊横断 >>
      if (isEmpty(dlgInst.spSurface)) {
        fillOldRiver(index, "#cross");
      } else {
        // 特殊横断の場合
        fillOldRiverSp(index, "#cross");
      }
      //ADD 特殊横断 <<
    } catch (e) {
      console.log("水位再描画失敗:", index, dlgInst.levelList, e);
    }

    var obsType = event.data.obsType;
    if (obsType === "swin") {
      var listIdx = dlgInst.levelList.length - 1 - index;

      var obsDate = new Date(dlgInst.levelList[listIdx].date);
      if (isNaN(obsDate)) {
        obsDate = new Date(
          dlgInst.levelList[listIdx].date.replace(/-/g, "/").replace(/T/g, " ")
        );
      }
      //console.log("DATE debug:", dlgInst.levelList[listIdx].date, obsDate);
      $("#titleDateTime").html(
        DateFormatUtil.format(obsDate, "YYYY/MM/DD hh:mm")
      );

      //コンテンツバーの観測値設定
      var targetLevel = dlgInst.levelList[listIdx].level;
      if (targetLevel == null) {
        $("#titleCap").html("");
        $("#titleLevel").html("欠測");
      } else {
        $("#titleCap").html("堤防天端からの高さ");
        var levelValue =
          dlgInst.levelList[listIdx].level - dlgInst.obs.fladLevel;
        levelValue = levelValue * 100;
        levelValue = Math.round(levelValue) / 100;
        levelValue = levelValue.toFixed(2);
        $("#titleLevel").html(levelValue + "m");
      }

      // コンテンツバーの背景色判定
      if (targetLevel == null) {
        $("#contentTitle").removeClass("flad");
        $("#contentTitle").removeClass("warn");
        $("#contentTitle").removeClass("start");
        $("#contentTitle").removeClass("normal");
        $("#contentTitle").addClass("missing");
      } else if (targetLevel >= dlgInst.obs.fladLevel) {
        $("#contentTitle").addClass("flad");
        $("#contentTitle").removeClass("warn");
        $("#contentTitle").removeClass("start");
        $("#contentTitle").removeClass("normal");
        $("#contentTitle").removeClass("missing");
      } else if (
        dlgInst.obs.warnLevel != null &&
        targetLevel >= dlgInst.obs.warnLevel
      ) {
        $("#contentTitle").removeClass("flad");
        $("#contentTitle").addClass("warn");
        $("#contentTitle").removeClass("start");
        $("#contentTitle").removeClass("normal");
        $("#contentTitle").removeClass("missing");
      } else if (targetLevel >= dlgInst.obs.startLevel) {
        $("#contentTitle").removeClass("flad");
        $("#contentTitle").removeClass("warn");
        $("#contentTitle").addClass("start");
        $("#contentTitle").removeClass("normal");
        $("#contentTitle").removeClass("missing");
      } else {
        $("#contentTitle").removeClass("flad");
        $("#contentTitle").removeClass("warn");
        $("#contentTitle").removeClass("start");
        $("#contentTitle").addClass("normal");
        $("#contentTitle").removeClass("missing");
      }

      if (
        dlgInst.levelList[listIdx].qt != null &&
        dlgInst.levelList[listIdx].qt != 0
      ) {
        $("#qtImg").css("display", "inline");
      } else {
        $("#qtImg").css("display", "none");
      }
    } else if (obsType === "TM") {
      var tmObsData = $("#obsDlg").getExtVal("tmObsData");

      //ダイアログ日付の表示
      var latestData = dlgInst.levelList[index];
      var obsDate = new Date(latestData.date);
      if (isNaN(obsDate)) {
        obsDate = new Date(
          latestData.date.replace(/-/g, "/").replace(/T/g, " ")
        );
      }
      //console.log("DATE debug:", latestData.date, obsDate);
      $("#titleDateTime").html(
        DateFormatUtil.format(obsDate, "YYYY/MM/DD hh:mm")
      );

      //ダイアログ水位の表示
      if (
        latestData.stageFlag == null ||
        latestData.stageFlag > 70 ||
        latestData.value == null
      ) {
        const errWord = dlgInst._getStageFlagWord(latestData.stageFlg);
        // データが無効値の場合
        $("#titleCap").html("");
        //$("#titleLevel").html("欠測");
        $("#titleLevel").html(errWord);
      } else {
        $("#titleCap").html("堤防天端からの高さ");
        const levelValue =
          latestData.value +
          tmObsData.obs.zeroHigh -
          Math.min(tmObsData.obs.bankHeight1, tmObsData.obs.bankHeight2);
        $("#titleLevel").html(Math.round(levelValue * 100) / 100 + "m");
      }

      // タイトルバーのカラー設定
      $("#contentTitle").removeClass("flad");
      $("#contentTitle").removeClass("warn");
      $("#contentTitle").removeClass("start");
      $("#contentTitle").addClass("normal");
      $("#contentTitle").removeClass("missing");
    }
  }

  async _loadTmCurrentDate() {
    let result;
    const url = this.config.tmApiUrl + "/system/tmCrntTime.json";
    await $.ajax({
      type: "GET",
      url: url,
      dataType: "json",
    }).then((data, status, jqXHR)=> {
      result = new Date(data.crntObsTime);
    }).catch((e)=> {
      throw "観測時刻の取得に失敗しました。";
    });
    return result;
  }

  async _loadObsInfoData(obscode) {
    let result = null;
    const url = this.config.tmApiUrl + "/files/master/obs/stg/" + obscode + ".json";
    await $.ajax({
      type: "GET",
      url: url,
      dataType: "json",
    }).then((data, status, jqXHR) => {
      result = data;
    }).catch((e) => {
      throw "観測所関連情報の取得に失敗しました。";
    });

    return result;
  }

  async _loadTmObsData(currentDate, obscode) {
    let result = null;
    const ym = DateFormatUtil.format(currentDate, "YYYYMMDD");
    const hm = DateFormatUtil.format(currentDate, "hhmm");
    if (hm.length == 3) {
      hm = "0" + hm;
    }

    const url = this.config.tmApiUrl + "/files/tmlist/stg/" + ym + "/" + hm + "/" + obscode + ".json";
    await $.ajax({
      type: "GET",
      url: url,
      dataType: "json",
    }).then((data, status, jqXHR) => {
      result = data;
    }).catch((e) => {
      //throw "観測水位の値の取得に失敗しました。";
      console.log("観測水位ファイルなし");
      let wrkDate = currentDate;
      const values = [];
      for (let idx = 0; idx < 100; idx ++) {
        const date = DateFormatUtil.format(wrkDate, "YYYY/MM/DD hh:mm");
        values.push({
          stg: null,
          obsTime: date,
          stgCcd: 160,
          stgOvlvl: 0,
          stg10mChg: 0,
          stg10mChgCcd: 0,
          stgOvdeg: 0,
          stgQmflg: null,
          stgHght: null,
          stgCrsHght: null
        });
        wrkDate.setMinutes(wrkDate.getMinutes() - 10);
      }

      result = {
        min10Values: values
      };
    });

    return result;
  }

  _getBankHight(obsData) {
    let bkHight = 0;
    if (obsData.obsInfo.upgate1 == null || obsData.obsInfo.upgate2 == null) {
      let leftBH = 0;
      let rightBH = 0;
      for (point of obsData.crssct) {
        if (point.drawLength == obsData.crs.sfLeftLimit) {
          leftBH = point.drawHigh;
        }
        if  (point.drawLength == obsData.crs.sfRightLimit) {
          rightBH = point.drawHigh;
        }
      }
      bkHight = Math.min(leftBH, rightBH);
    } else {
      bkHight = Math.min(obsData.obsInfo.upgate1, obsData.obsInfo.upgate2);
    }

    return bkHight;
  }

  _findIntersection(p1, p2, p3, p4) {

    /*
    console.log(
      "p1:" + JSON.stringify(p1) + "\n" +
      "p2:" + JSON.stringify(p2) + "\n" +
      "p3:" + JSON.stringify(p3) + "\n" +
      "p4:" + JSON.stringify(p4) + "\n"
    );
    */

    // 線分1の座標
    const x1 = p1.x, y1 = p1.y;
    const x2 = p2.x, y2 = p2.y;
    
    // 線分2の座標
    const x3 = p3.x, y3 = p3.y;
    const x4 = p4.x, y4 = p4.y;
    
    // 各線分の係数を計算
    const denominator = (x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4);
    
    // 平行かどうかを確認
    if (denominator === 0) {
        return null; // 平行な線分には交点がない
    }

    // 交点の座標を計算
    const t = ((x1 - x3) * (y3 - y4) - (y1 - y3) * (x3 - x4)) / denominator;
    const u = -((x1 - x2) * (y1 - y3) - (y1 - y2) * (x1 - x3)) / denominator;

    // 交点が線分内にあるか確認
    if (t >= 0 && t <= 1 && u >= 0 && u <= 1) {
        const intersectionX = x1 + t * (x2 - x1);
        const intersectionY = y1 + t * (y2 - y1);
        return { x: intersectionX, y: intersectionY };
    }

    return null; // 線分上には交点がない
  }

  /**
   * 川防の高アクセス対応ファイルから、英語版APIのデータ形式のデータを生成する。
   * @param {Object} obsData 川防の高アクセス用水位観測所データ
   * @param {Object} tmData 川防の高アクセス用観測所別観測値データ
   * @returns 英語版API形式データ
   */
  _genPh2Data(obsData, tmData) {

    //堤防高を求める
    let leftBH = null;
    let rightBH = null;

    if (obsData.crssct.length != 0 && obsData.crs.sfLeftLimit != null && obsData.crs.sfRightLimit != null) {
      const leftPoint = obsData.crs.sfLeftLimit == 0 ? 0 : Math.round(obsData.crs.sfLeftLimit * 100)/100;
      const rightPoint = obsData.crs.sfRightLimit == 0 ? 0 : Math.round(obsData.crs.sfRightLimit * 100)/100;
      for (const point of obsData.crssct) {
        const pos = Math.round(point.drawLength * 100)/100;
        //console.log(":::pos -- leftPoint: " + pos + " -- " + leftPoint);
        if (pos == leftPoint) {
          if (leftBH == null || leftBH < point.drawHigh) {
            leftBH = point.drawHigh;
          }
        }
        //console.log(":::pos -- rightPoint: " + pos + " -- " + rightPoint);
        if  (pos == rightPoint) {
          if (rightBH == null || rightBH < point.drawHigh){
            rightBH = point.drawHigh;
          }
        }
      }
      if (leftBH == null) {
        const p1 = {x: null, y: null};
        const p2 = {x: null, y: null};
        const p3 = {x: leftPoint, y: -10000};
        const p4 = {x: leftPoint, y: 10000};
        for(const point of obsData.crssct) {
          const pos = Math.round(point.drawLength * 100)/100;
          if (leftPoint > pos) {
            p1.x = pos;
            p1.y = point.drawHigh;
          }else {
            p2.x = pos;
            p2.y = point.drawHigh;
            break;
          }
        }
        const crPoint = this._findIntersection(p1, p2, p3, p4);
        leftBH = crPoint.y;
      }
      if (rightBH == null) {
        const p1 = {x: null, y: null};
        const p2 = {x: null, y: null};
        const p3 = {x: rightPoint, y: -10000};
        const p4 = {x: rightPoint, y: 10000};
        for(const point of obsData.crssct) {
          const pos = Math.round(point.drawLength * 100)/100;
          if (rightPoint > pos) {
            p1.x = pos;
            p1.y = point.drawHigh;
          }else {
            p2.x = pos;
            p2.y = point.drawHigh;
            break;
          }
        }
        if(p2.x == null || p2.y == null) {
          const endPoint = obsData.crssct[obsData.crssct.length - 1];
          rightBH = endPoint.drawHigh;
        } else {
          /*
          console.log("DEBUG: p1-p2-p3-p4:\n" +
            JSON.stringify(p1) + "\n" +
            JSON.stringify(p2) + "\n" +
            JSON.stringify(p3) + "\n" +
            JSON.stringify(p4) + "\n"
          );
          */
          const crPoint = this._findIntersection(p1, p2, p3, p4);
          rightBH = crPoint.y;
        }
      }

    } else {
      leftBH = obsData.obsInfo.upgate1;
      rightBH = obsData.obsInfo.upgate2;
    }
    //川幅計算（川防データには川幅がないので横断データから生成するが・・・）
    let rvrWidth = null;
    if (obsData.crs.sfLeftLimit != null) {
      rvrWidth = obsData.crs.sfRightLimit - obsData.crs.sfLeftLimit;
    }

    const wrnLevel = obsData.obsInfo.spclWarnStg;

    const dngLevel = obsData.obsInfo.dngStg;

    const result = {
      obs: {
        riverCode: obsData.obsInfo.rvrCd,
        riverName: "",            // 英語名なので空にしておく
        bankHeight1: leftBH,
        bankHeight2: rightBH,
        riverWidth: rvrWidth,
        riverBottom: obsData.crs.riverBed,
        crossStage: obsData.obsInfo.rsrvStg,
        bankSct: obsData.obsInfo.bnkSct,
        alititudeStandardCode: obsData.obsInfo.altiStdCd,
        obsFcd: obsData.obsInfo.reprnObsFcd,
        zeroHigh: obsData.obsInfo.zeroHighFix
      },
      cctv: {
        cctvCd: null,
        latestUrl: null,
        usualUrl: null
      },
      stage: {
        warnStage: wrnLevel,
        dangerStage: dngLevel,
        stages: []
      },
      cross: {
        zeroHigh: obsData.obsInfo.zeroHighFix,
        leftLimit: obsData.crs.sfLeftLimit,
        rightLimit: obsData.crs.sfRightLimit,
        rvrBaseHght: obsData.crs.rvrBaseHigh,
        points: []
      }
    };
    if (obsData.crssct.length == 0) {
      result.obs.riverWidth = null;
      result.cross.zeroHigh = null;
      result.cross.leftLimit = null;
      result.cross.rightLimit = null;
      result.cross.rvrBaseHght = null;
    }

    //TMの観測値を設定
    //console.log(JSON.stringify(tmData.min10Values));
    let hit = false;
    for (const tm of tmData.min10Values) {
      if (tm.stgCcd == null && hit == true) {
        result.stage.stages.push({
          date: tm.obsTime,
          value: null,
          stageFlag: 130,
          change: 0
        });
        continue;
      } else if (tm.stgCcd == null && hit == false) {
        continue;
      }

      hit = true;
      let chg = null;
      if (tm.stg10mChgCcd > 70) {
        chg = null;
      } else {
        chg = tm.stg10mChg;
      }
      let stg = null;
      if (tm.stgCcd > 70) {
        stg = null;
      } else {
        stg = tm.stg;
      }
      result.stage.stages.push({
        date: tm.obsTime,
        value: stg,
        stageFlag: tm.stgCcd,
        change: chg
      });
      //console.log("WARN1: " + (tm.stg + obsData.crs.zeroHigh) + "/" + obsData.crs.riverBed);
    }
    result.stage.stages = result.stage.stages.reverse();
    if (result.stage.stages.length == 0) {
      const dmyTime = new Date();
      const diff = dmyTime.getMinutes() % 10;
      dmyTime.setMinutes(dmyTime.getMinutes()  - (70 + diff));
      const dmyTimeStr = DateFormatUtil.format(dmyTime, "YYYY/MM/DD hh:mm");

      result.stage.stages.push({
        date: dmyTimeStr,
        value: null,
        stageFlag: 130,
        change: 0
      });
    }


    //横断情報を設定
    //console.log("obsData.crssct: " + JSON.stringify(obsData.crssct));
    let patchLen = null;
    for (const pos of obsData.crssct) {
      if (pos.drawLength >= obsData.crs.sfLeftLimit && pos.drawLength <= obsData.crs.sfRightLimit) {
        if (patchLen == null) {
          patchLen = 0 - pos.drawLength;
          //console.log("patchLen = " + patchLen);
        }
        result.cross.points.push({
          drawLength: pos.drawLength + patchLen,
          drawHeight: pos.drawHigh - result.cross.zeroHigh
        });
      }
    }

    return result;
  }

  _getStageFlagWord(stageFlg) {
    let result = "";
    switch (stageFlg - 0) {
      case 130:
        result = "未収集";
        break;
      case 140:
        result = "閉局";
        break;
      case 160:
        result = "未収集";
        break;
      case 190:
        result = "欠測";
        break;
      default:
        result = "未収集";
        break;
    }
    //console.log("errWord: " + result + "(" + stageFlg + ")");
    return result;
  }

  /**
   * テレメータ観測所詳細情報取得
   * @param {Object} obs 観測所情報
   */
  async getTmObsData(obs) {

    const dlgInst = $("#obsDlg").getExtVal("instance");
    let tmCurrentDate;
    let obsData;
    let tmData;
    let levelListLen;

    //20240725 川防情報参照対応 >>>>
    //情報取得
    try {
      //TMのカレント観測日時を取得
      tmCurrentDate = await dlgInst._loadTmCurrentDate();
      //console.log("tmCurrentDate: " + tmCurrentDate);

      //観測所関連情報取得
      obsData = await dlgInst._loadObsInfoData(obs.obsCode);
      // 川防GeoJSONにない項目をここで補完する
      dlgInst.obs.rsysName = obsData.obsInfo.rsysNm;
      dlgInst.obs.riverName = obsData.obsInfo.rvrNm;
      dlgInst.obs.obsKana = obsData.obsInfo.obsKana == null? "": obsData.obsInfo.obsKana;
      //console.log("obsData: " + JSON.stringify(obsData));

      //観測値情報取得
      tmData = await dlgInst._loadTmObsData(tmCurrentDate, obs.obsCode);
      //console.log("tmData: " + JSON.stringify(tmData));

      let data = dlgInst._genPh2Data(obsData, tmData);
      //console.log("ph2 data1:\n" + JSON.stringify(data));

      dlgInst.levelList = data.stage.stages;
      levelListLen = dlgInst.levelList.length;
      //console.log("stages: " + JSON.stringify(data.stage.stages));
      //console.log("levelListLen:" + levelListLen);

      // スライダーバー初期化
      $("#levelRange").attr("min", 0);
      $("#levelRange").attr("max", levelListLen - 1);
      $("#levelRange").val(levelListLen - 1);
      $("#levelRange").on(
        "change",
        {
          obsType: "TM",
        },
        dlgInst.changeLevel
      );

      // ダイアログ日付の表示
      const latestData = dlgInst.levelList[levelListLen - 1];
      //console.log("latestData:" + JSON.stringify(latestData));
      const obsDate = latestData ? new Date(latestData.date): new Date();
      if (isNaN(obsDate)) {
        obsDate = new Date(
          latestData.date.replace(/-/g, "/").replace(/T/g, " ")
        );
      }
      //console.log("DATE debug:", latestData.date, obsDate);
      $("#titleDateTime").html(
        DateFormatUtil.format(obsDate, "YYYY/MM/DD hh:mm")
      );

      // ダイアログ水位の表示
      if (
        latestData == null ||
        latestData.stageFlag == null ||
        latestData.stageFlag > 70 ||
        latestData.value == null
      ) {
        const errWord = dlgInst._getStageFlagWord(latestData.stageFlag);
        // データが無効値の場合
        $("#titleCap").html("");
        //$("#titleLevel").html("欠測");
        $("#titleLevel").html(errWord);
      } else {
        $("#titleCap").html("堤防天端からの高さ");

        let bkH = 0;
        if (data.obs.bankHeight1 <= data.obs.riverBottom || data.obs.bankHeight2 <= data.obs.riverBottom) {
          bkH = Math.max(data.obs.bankHeight1, data.obs.bankHeight2);
        }
        else {
          bkH = Math.min(data.obs.bankHeight1, data.obs.bankHeight2);
        }
        /*
        console.log(
          "水位:" + latestData.value + "\n" +
          "零点高:" + data.obs.zeroHigh + "\n" +
          "堤防高:" + bkH
        )
        */
        const levelValue =
          latestData.value +
          data.obs.zeroHigh -
          bkH;
        $("#titleLevel").html(Math.round(levelValue * 100) / 100 + "m");
      }

      // タイトルバーのカラー設定
      $("#contentTitle").removeClass("flad");
      $("#contentTitle").removeClass("warn");
      $("#contentTitle").removeClass("start");
      $("#contentTitle").addClass("normal");
      $("#contentTitle").removeClass("missing");

      // TMCCTVのURL設定 
      data = await dlgInst.createTmCctvUrl(data, obsData.obsInfo);
      //TODO
      //console.log("ph2 data2:\n" + JSON.stringify(data));
      $("#obsDlg").setExtVal("tmObsData", data);
      //TM水位観測所のカナはココで設定!!
      dlgInst.obs.obsKana = data.obs.obsKana;
      $("#obsDlgObsKana").html(data.obs.obsKana);

      // ハイドログラフパラメータ作成
      const tmHydroPrm = dlgInst.createTmHydroPrm(data);
      $("#obsDlg").setExtVal("tmHydroPrm", tmHydroPrm);

      // 横断線の設定、ダイアログの表示
      dlgInst.drawTmCrossRange();
    } catch (e) {
      console.log(e);
    }

    /*
    var _tmReq = new Requester(this.config.tmApiUrl);
    var tmSbmtr = _tmReq.create();

    // 観測所詳細情報取得API
    tmSbmtr.register(
      "GetObsDetail",
      {
        obsCode: obs.obsCode,
      },
      async function (data) {
        var dlgInst = $("#obsDlg").getExtVal("instance");
        var alititudeStandardValue = dlgInst.getAlititudeStandardValue(
          data.obs.alititudeStandardCode
        );
        $.each(data.stage.stages, function (i, v) {
          if (v.value != null) {
            v.value = v.value + alititudeStandardValue;
          }
        });

        dlgInst.levelList = data.stage.stages;
        var levelListLen = dlgInst.levelList.length;

        // スライダーバー初期化
        $("#levelRange").attr("min", 0);
        $("#levelRange").attr("max", levelListLen - 1);
        $("#levelRange").val(levelListLen - 1);
        $("#levelRange").on(
          "change",
          {
            obsType: "TM",
          },
          dlgInst.changeLevel
        );

        // ダイアログ日付の表示
        var latestData = dlgInst.levelList[levelListLen - 1];
        var obsDate = new Date(latestData.date);
        if (isNaN(obsDate)) {
          obsDate = new Date(
            latestData.date.replace(/-/g, "/").replace(/T/g, " ")
          );
        }
        //console.log("DATE debug:", latestData.date, obsDate);
        $("#titleDateTime").html(
          DateFormatUtil.format(obsDate, "YYYY/MM/DD hh:mm")
        );

        // ダイアログ水位の表示
        if (
          latestData.stageFlag == null ||
          latestData.stageFlag > 70 ||
          latestData.value == null
        ) {
          // データが無効値の場合
          $("#titleCap").html("");
          $("#titleLevel").html("欠測");
        } else {
          $("#titleCap").html("堤防天端からの高さ");
          var levelValue =
            latestData.value +
            data.obs.zeroHigh -
            Math.min(data.obs.bankHeight1, data.obs.bankHeight2);
          $("#titleLevel").html(Math.round(levelValue * 100) / 100 + "m");
        }

        // タイトルバーのカラー設定
        $("#contentTitle").removeClass("flad");
        $("#contentTitle").removeClass("warn");
        $("#contentTitle").removeClass("start");
        $("#contentTitle").addClass("normal");
        $("#contentTitle").removeClass("missing");

        // TMCCTVのURL設定 
        data = await dlgInst.createTmCctvUrl(data, dlgInst);
        $("#obsDlg").setExtVal("tmObsData", data);
        //TM水位観測所のカナはココで設定!!
        dlgInst.obs.obsKana = data.obs.obsKana;
        $("#obsDlgObsKana").html(data.obs.obsKana);

        // ハイドログラフパラメータ作成
        var tmHydroPrm = dlgInst.createTmHydroPrm(data);
        $("#obsDlg").setExtVal("tmHydroPrm", tmHydroPrm);

        // 横断線の設定、ダイアログの表示
        dlgInst.drawTmCrossRange();
      }
    );
    tmSbmtr.submit(this);
    */
    //20240725 川水参照対応 <<<<<

    // ページログの作成処理
    PageLog.log(this.config.pageLogUrl.tmUrl, obs.obsCode);
  }
  /**
   * テレメータCCTV情報取得
   * @param {Object} cctvData TMCCTVアイコン情報
   * ----------------------------------------------------------------------------
   */
  getTmCctvData(cctvData) {
    var _tmReq = new Requester(this.config.tmApiUrl);
    var tmSbmtr = _tmReq.create();

    // CCTV詳細情報取得API
    tmSbmtr.register(
      "GetCctvDetail",
      {
        cctvCode: cctvData.cctvCode,
      },
      function (data) {
        $("#obsDlg").setExtVal("tmCctvData", data);

        var dlgInst = $("#obsDlg").getExtVal("instance");
        dlgInst._dlgDraw();
      }
    );
    tmSbmtr.submit(this);
  }

  /**
   * ハイドログラフ描画
   * ----------------------------------------------------------------------------
   */
  dispHydroGraph() {
    $("#hydroGraph").css("display", "inline");
    $("#hydroGraph").css("overflow", "hidden");
    $("#hydoro").css("height", $("#hydroGraph").height());

    var param = null;

    if ($("#obsDlg").getExtVal("hydroPrm") != null) {
      param = $("#obsDlg").getExtVal("hydroPrm");
    } else if ($("#obsDlg").getExtVal("tmHydroPrm") != null) {
      param = $("#obsDlg").getExtVal("tmHydroPrm");
    } else {
      console.log("グラフ描画に必要な情報がありません。");
      return;
    }

    try {
      this.hydoro.draw(param);
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * 日付フォーマット変換
   * @param {String} dStr APIの日付フォーマット文字列
   * @return {String} 日付フォーマット変更後画像
   * ----------------------------------------------------------------------------
   */
  chartDateFormat(dStr) {
    var d = new Date(dStr);
    if (isNaN(d)) {
      d = new Date(dStr.replace(/-/g, "/").replace(/T/g, " "));
    }
    if (isNaN(d)) {
      d = new Date(dStr.split("+")[0] + "+09:00");
    }
    return DateFormatUtil.format(d, "YYYY/MM/DD hh:mm");
  }

  /**
   * ハイドログラフ拡大ダイアログの表示
   * ----------------------------------------------------------------------------
   */
  dispHydroZoomDlg() {
    var dlgInst = $("#obsDlg").getExtVal("instance");

    // ダイアログの表示
    _hydroZoomDlg.show();

    var id = null; // 英語化→ph2 簡易水位→swin
    var param = null;
    // 水位計タイプの判定
    if ($("#obsDlg").getExtVal("hydroPrm") != null) {
      id = "swin";
      param = $("#obsDlg").getExtVal("hydroPrm");
    } else if ($("#obsDlg").getExtVal("tmHydroPrm") != null) {
      id = "ph2";
      param = $("#obsDlg").getExtVal("tmHydroPrm");
    }

    dlgInst.hydroZoom = new Hydoro({
      htmlId: "#hydroZoom",
      id: id,
    });

    $("#hydroZoomFrame").css("display", "inline");
    $("#hydroZoomFrame").css("overflow", "hidden");
    $("#hydroZoom").css("height", $("#hydroZoomFrame").height());

    $("#valueTip").remove();
    // ハイドログラフの描画
    dlgInst.hydroZoom.draw(param);
  }

  /**
   * 観測所写真アイコンクリック
   * 観測所画像を表示させる。
   * @param {Object} evt イベント情報
   * ----------------------------------------------------------------------------
   */
  clickObsPic(evt) {
    var idx = evt.data.dlg.obs.pic.indexOf("/");
    var url =
      evt.data.dlg.config.obsImgUrl +
      evt.data.dlg.obs.pic.substring(idx + 1, evt.data.dlg.obs.pic.length);
    $.ajax({
      url: url,
    }).then(() => {
      evt.data.dlg.picDlg.show(url);
    });
  }

  /**
   * 更新ボタンクリック
   * 水位情報を取得し直して描画し直す。
   * @param {Object} evt イベント情報
   * ----------------------------------------------------------------------------
   */
  clickRenew(evt) {
    //console.log("renew start.");
    var dlgInst = $("#obsDlg").getExtVal("instance");
    // 更新ボタンフラグ
    $("#obsDlg").setExtVal("renew", "renew");

    if (dlgInst.tabOrder == 2 || dlgInst.tabOrder == 3) {
      $("#cctvFrame").css("background-image", "none");
      dlgInst._dlgDraw();
      //ページログ処理
      if (dlgInst.type == "swin") {
        PageLog.log(dlgInst.config.pageLogUrl.cctvUrl, dlgInst.obs.code);
      } else if (dlgInst.type == "TM") {
        PageLog.log(dlgInst.config.pageLogUrl.tmCctvUrl, dlgInst.obs.cctvCode);
      }
    } else if (dlgInst.type == "swin") {
      //console.log("renew getLevels call. prm=", dlgInst.orgParam);
      var areaInfoUrl =
        "/swin/files/area_levels/current/" +
        dlgInst.orgParam.obs.townCode +
        ".json";
      $.ajax({
        url: areaInfoUrl,
        beforeSend: function (jqXHR, settings) {
          jqXHR.requestURL = settings.url;
        },
      })
        .then((json, textResponse, jqXHR) => {
          var dlgInst = $("#obsDlg").getExtVal("instance");
          //console.log("area_info:", json);
          for (var i in json.levels) {
            var level = json.levels[i];
            if (dlgInst.obs.code == level.code) {
              //console.log("area_info date update:", level);
              dlgInst.orgParam.date = level.date;
              break;
            }
          }
          //console.log("getLevels prm=", dlgInst.orgParam);
          dlgInst.getLevels(dlgInst.orgParam);
        })
        .catch((jqXHR, textResponse) => {
          console.log("error:\n", textResponse);
        });
    } else if (dlgInst.type == "TM") {
      dlgInst.getTmObsData(dlgInst.obs);
    }
  }

  /**
   * 横断図ボタンクリック
   * 画面表示を横断図に切り替える。
   * @param {Object} evt イベント情報
   * ----------------------------------------------------------------------------
   */
  clickCrossBtn(evt) {
    evt.data.dlg.tabOrder = 0;
    evt.data.dlg.dispContent();
  }

  /**
   * ハイドログラフボタンクリック
   * 画面表示をハイドログラフに切り替える。
   * @param {Object} evt イベント情報
   * ----------------------------------------------------------------------------
   */
  clickHydroBtn(evt) {
    evt.data.dlg.tabOrder = 1;
    evt.data.dlg.dispContent();
  }

  /**
   * CCTVカメラ現状ボタンクリック
   * 画面表示をカメラ画像（現状）に切り替える。
   * @param {Object} evt イベント情報
   * ----------------------------------------------------------------------------
   */
  clickCamNowBtn(evt) {
    evt.data.dlg.tabOrder = 2;
    evt.data.dlg.dispContent("cctvLoad");
  }

  /**
   * CCTVカメラ平常ボタンクリック
   * 画面表示をカメラ画像（平常）に切り替える。
   * @param {Object} evt イベント情報
   * ----------------------------------------------------------------------------
   */
  clickCamVtrBtn(evt) {
    evt.data.dlg.tabOrder = 3;
    evt.data.dlg.dispContent("cctvLoad");
  }

  /**
   * 観測値一覧ボタン
   * 観測値一覧画面を表示する。
   * @param {Object} evt イベント情報
   * ----------------------------------------------------------------------------
   */
  clickListBtn(evt) {
    evt.data.dlg.tabOrder = 4;
    evt.data.dlg.dispContent();
  }

  /**
   * LINE連携ボタン
   *
   * PC：別ウィンドウが開く。
   * スマホ：アプリが起動する。
   * @param {Object} evt
   * ----------------------------------------------------------------------------
   */
  clickLineBtn(evt) {
    evt.data.dlg.postLine();

    let pageLogUrl = evt.data.dlg.config.pageLogUrl.lineUrl;
    let code = evt.data.dlg.getObsCode(evt);

    PageLog.log(pageLogUrl, code);
  }

  /**
   * Twitter連携ボタン
   *
   * @param {Object} evt
   * ----------------------------------------------------------------------------
   */
  clickTwitterBtn(evt) {
    evt.data.dlg.postTwitter();

    let pageLogUrl = evt.data.dlg.config.pageLogUrl.twitterUrl;
    let code = evt.data.dlg.getObsCode(evt);

    PageLog.log(pageLogUrl, code);
  }

  /**
   * Facebook連携ボタン
   *
   * @param {Object} evt
   * ----------------------------------------------------------------------------
   */
  clickFacebookBtn(evt) {
    let pageLogUrl = evt.data.dlg.config.pageLogUrl.facebookUrl;
    let code = evt.data.dlg.getObsCode(evt);

    PageLog.log(pageLogUrl, code);
  }

  /**
   * 観測所番号取得
   * @param {Object} event イベント情報
   * @return {string} 観測所番号
   * ----------------------------------------------------------------------------
   */
  getObsCode(event) {
    let type = event.data.dlg.type;
    let mode = event.data.dlg.mode;

    let code = null;
    if (type === "TM" && mode === "OBS") {
      code = event.data.dlg.obs.obsCode;
    } else if (type === "TM" && mode === "CCTV") {
      code = event.data.dlg.obs.cctvCode;
    } else if (type === "swin") {
      code = event.data.dlg.obs.code;
    }

    return code;
  }

  /**
   * ダイアログ閉じるボタンクリック
   * @param {Object} event イベント情報
   * ----------------------------------------------------------------------------
   */
  clickDlgClose(event) {
    var dlgInst = $("#obsDlg").getExtVal("instance");

    if (dlgInst.mode == "OBS") {
      //A点B点アイコン・横断線の削除
      if (dlgInst.markerA) {
        dlgInst.markerA.remove();
      }
      if (dlgInst.markerB) {
        dlgInst.markerB.remove();
      }
      if (dlgInst.line) {
        dlgInst.line.remove();
      }

      //ハイドログラフtoolTipの削除
      $("#valueTip").remove();

      //ハイドログラフのprm削除
      $("#obsDlg").setExtVal("hydroPrm", null);
      $("#obsDlg").setExtVal("hydroPrm", null);

      //横断図のprm削除
      $("#obsDlg").setExtVal("riverInfo", null);
      $("#obsDlg").setExtVal("tmRiverInfo", null);
      $("#obsDlg").setExtVal("danmenPointD", null);
    }

    // CCTV画像のクリア
    dlgInst.cctvImgStyle("");

    if ($("#obsDlg").getExtVal("tmObsData") != null) {
      $("#obsDlg").setExtVal("tmObsData", null);
    }
    if ($("#obsDlg").getExtVal("tmCctvData") != null) {
      $("#obsDlg").setExtVal("tmCctvData", null);
    }

    //ダイアログ更新フラグの削除
    $("#obsDlg").setExtVal("renew", null);

    //CCTVURLの削除
    $("#cctvZoomDlg").setExtVal("src", null);
    $("#cctvZoomDlg").setExtVal("time", null);

    $("#obsDlgBk").css("display", "none");

    //ハイドロクリア
    if (dlgInst.hydoro != null) {
      dlgInst.hydoro.clear();
    }

    //横断図クリア
    $("#cross").html("");
  }

  /**
   * 品管アラートアイコンクリック
   * @param {Object} event イベント情報
   */
  clickQtImg(event) {
    var ww = window.innerWidth;
    var wh = window.innerHeight;
    var x = event.pageX;
    var y = event.pageY;
    var dw = event.data.dlg.toolTip.outerWidth();
    var dh = event.data.dlg.toolTip.outerHeight();

    if (ww < x + dw) {
      x = x - (x + dw - ww);
    }
    if (wh < y + dh) {
      y = y - (y + dh - wh);
    }

    event.data.dlg.toolTip.css("display", "block");
    event.data.dlg.toolTip.css("left", x + "px");
    event.data.dlg.toolTip.css("top", y + "px");

    if (_timerId != 0) {
      clearTimeout(_timerId);
    }
    _timerId = setTimeout(
      (toolTip) => {
        toolTip.css("display", "none");
        _timerId = 0;
      },
      3000,
      event.data.dlg.toolTip
    );
  }
  clickQtToolTip(event) {
    clearTimeout(_timerId);
    event.data.dlg.toolTip.css("display", "none");
  }
  /**
   * 川幅算出
   * @param {Object} obs 観測所GEOJSONのproperties部
   * @return {number} 川幅(m)
   * ----------------------------------------------------------------------------
   */
  getRiverWidth(obs) {
    var p1 = [obs.leftBank.coordinate.lon, obs.leftBank.coordinate.lat];
    var p2 = [obs.rightBank.coordinate.lon, obs.rightBank.coordinate.lat];
    var distance =
      turf.distance(p1, p2, {
        units: "kilometers",
      }) * 1000;
    return distance;
  }

  /**
   * 横断両端取得
   * @param {Object} obs 観測所GEOJSONのproperties部
   * @param {Object} conf 設定(mapconfig.yml#crossSettings部)
   * @param {number} width 川幅（m）
   * ----------------------------------------------------------------------------
   */
  getABPoint(obs, conf, width) {
    var distance = conf.riverSideMinWidth;
    if (width > distance) {
      distance = width;
    }

    var p1 = [obs.leftBank.coordinate.lon, obs.leftBank.coordinate.lat];
    var p2 = [obs.rightBank.coordinate.lon, obs.rightBank.coordinate.lat];
    var br1 = turf.bearing(p1, p2);
    var br2 = turf.bearing(p2, p1);
    var resP1 = turf.destination(p1, distance / 1000, br2, {
      units: "kilometers",
    });
    var resP2 = turf.destination(p2, distance / 1000, br1, {
      units: "kilometers",
    });
    return [
      [resP1.geometry.coordinates[1], resP1.geometry.coordinates[0]],
      [resP2.geometry.coordinates[1], resP2.geometry.coordinates[0]],
    ];
  }

  /**
   * CCTV画像表示
   * @param {Object} mode 現状か平常 ("latest" or "usual")
   * @param {string} cctvLoad cctv読み込みフラグ ("src" or null)
   * ----------------------------------------------------------------------------
   */
  dispCCTV(mode, cctvLoad) {
    var dlgInst = $("#obsDlg").getExtVal("instance");

    // 初回ダイアログ表示時と更新ボタンクリック時に、画像の読み込み確認を行う
    if (
      cctvLoad != null ||
      $("#obsDlg").getExtVal("renew") ||
      !$("#cctvZoomDlg").getExtVal("src")
    ) {
      var chUrl = null;
      if (dlgInst.type === "swin") {
        if (mode === "latest") {
          chUrl =
            /* del 20230510 川水固有のカメラ画像消滅により
            dlgInst.config.cctvImgUrl +
            mode +
            "/" +
            dlgInst.obs.code +
            ".png?mode=" +
            */
            dlgInst.obs.latestUrl;
        } else {
          chUrl =
            /* del 20230510 川水固有のカメラ画像消滅により
            dlgInst.config.cctvImgUrl +
            mode +
            "/" +
            dlgInst.obs.code +
            ".png?mode=" +
            */
            dlgInst.obs.usualUrl;
        }
      } else {
        var tmCctvData = null;
        if ($("#obsDlg").getExtVal("tmCctvData")) {
          tmCctvData = $("#obsDlg").getExtVal("tmCctvData");
        } else if ($("#obsDlg").getExtVal("tmObsData")) {
          tmCctvData = $("#obsDlg").getExtVal("tmObsData");
        }

        // キャッシュのURLと本番のUrlを取得し、Urlを作成
        var url = null;
        var subUrl = null;
        if (mode === "latest") {
          url = tmCctvData.cctv.normalLatestUrl;
          subUrl = tmCctvData.cctv.latestUrl;
        } else {
          url = tmCctvData.cctv.normalUsualUrl;
          subUrl = tmCctvData.cctv.usualUrl;
        }

        chUrl = url + "?mode=" + subUrl;
      }

      $.ajax({
        url: chUrl,
        beforeSend: function (jqXHR, settings) {
          jqXHR.requestURL = settings.url;
        },
      })
        .then((gjson, textResponse, jqXHR) => {
          var chacheUrl = jqXHR.requestURL + "?" + new Date().getTime();
          dlgInst.cctvImgStyle(chacheUrl);
          $("#cctvZoomDlg").setExtVal("src", chacheUrl);
        })
        .catch((jqXHR, textResponse) => {
          var qsIndex = jqXHR.requestURL.indexOf("?");
          var subUrl = jqXHR.requestURL.substring(qsIndex + 1);

          // 時刻を繋げるマーク判定
          // jqXHR.requestURL内に「?」が2以上ある場合はマークを「&」に変更
          var qsCount = jqXHR.requestURL.split("?").length;
          var connectMark = "?";
          if (qsCount > 2) {
            connectMark = "&";
          }
          var eqIndex = subUrl.indexOf("=");
          var originalUrl =
            subUrl.substring(eqIndex + 1) + connectMark + new Date().getTime();

          dlgInst.cctvImgStyle(originalUrl);
          $("#cctvZoomDlg").setExtVal("src", originalUrl);
        });
    } else {
      var url = $("#cctvZoomDlg").getExtVal("src");
      dlgInst.cctvImgStyle(url);
    }
  }


  /**
   * cctv画像の表示設定
   * ----------------------------------------------------------------------------
   */
  cctvImgStyle(url) {
    //簡易カメラの場合の時刻表示を行う。
    if (url.match(/cam\.river\.go\.jp/)) {
      $("#cctvFrameTime").css("display", "block");

      //時刻JSON URL生成
      var reg = /(.*)(?:\.([^.]+$))/;
      var timeJsonUrl = url.match(reg)[1] + ".json";

      //時刻JSON取得
      $.ajax({
        url: timeJsonUrl,
        beforeSend: function (jqXHR, settings) {
          jqXHR.requestURL = settings.url;
        },
      })
        .then((json, textResponse, jqXHR) => {
          switch (json.date_type) {
            case 0:
              //取得時刻を出す。
              var tmpDate = new Date(json.get_time);
              if (isNaN(tmpDate)) {
                tmpDate = new Date(
                  json.get_time.replace(/-/g, "/").replace(/T/g, " ")
                );
              }
              var strDate = DateFormatUtil.format(tmpDate, "YYYY/MM/DD hh:mm");
              $("#cctvFrameTime").html(strDate);
              $("#cctvZoomDlg").setExtVal("time", strDate);
              break;
            case 1:
              //画像に書かれているので時刻を消す。
              $("#cctvFrameTime").css("display", "none");
              $("#cctvZoomDlg").setExtVal("time", null);
              break;
            case 2:
              //Last-moddifiedを出す。
              var tmpDate = new Date(json.create_time);
              if (isNaN(tmpDate)) {
                tmpDate = new Date(
                  json.create_time.replace(/-/g, "/").replace(/T/g, " ")
                );
              }
              var strDate = DateFormatUtil.format(tmpDate, "YYYY/MM/DD hh:mm");
              $("#cctvFrameTime").html(strDate);
              $("#cctvZoomDlg").setExtVal("time", strDate);
              break;
            default:
              //時刻区分がおかしいのでとりあえず時刻を消す。
              $("#cctvFrameTime").css("display", "none");
              $("#cctvZoomDlg").setExtVal("time", null);
              break;
          }
        })
        .catch((jqXHR, textResponse) => {
          $("#cctvFrameTime").css("display", "none");
          $("#cctvZoomDlg").setExtVal("cctvTime", null);
        });
    } else {
      $("#cctvFrameTime").css("display", "none");
      $("#cctvZoomDlg").setExtVal("cctvTime", null);
    }

    //画像表示
    $("#cctvFrameImage").css("background-image", "url(" + url + ")");
  }

  /**
   * cctv拡大ダイアログの表示
   * ----------------------------------------------------------------------------
   */
  dispCctvZoomDlg() {
    var url = $("#cctvZoomDlg").getExtVal("src");
    var tm = $("#cctvZoomDlg").getExtVal("time");
    _cctvZoomDlg.create(url, tm);
  }

  /**
   * 横断図表示
   * ----------------------------------------------------------------------------
   */
  dispCross() {
    $("#content").css("display", "flex");

    var riverInfo = null;
    if (this.type == "swin") {
      riverInfo = $("#obsDlg").getExtVal("riverInfo");
    } else if (this.type == "TM") {
      riverInfo = $("#obsDlg").getExtVal("tmRiverInfo");
    }

    if (riverInfo != null) {
      riverInfo.divWidth = $("#cross").width();
      riverInfo.divHeight = $("#cross").height();
    }

    if (isEmpty(this.spSurface)) {
      var points = getEndPoint(riverInfo);
      console.log("points: " + JSON.stringify(points));
      createCossSectionData(points, riverInfo);
    } else {
      // 特殊横断図の場合
      riverInfo.spSurface = this.spSurface;
      var points = getSpEndPoint(riverInfo);
      console.log("points(sp): " + JSON.stringify(points));
      createSpCossSectionData(points, riverInfo);
    }
    //スクロール対応
    setTimeout(function () {
      $("#content").setWidthScrollPos(50);
    }, 400);
  }

  /**
   * 横断図拡大ダイアログ表示
   * ----------------------------------------------------------------------------
   */
  dispCrossZoomDlg() {
    var dlgInst = $("#obsDlg").getExtVal("instance");
    var tmObsData = $("#obsDlg").getExtVal("tmObsData");

    // 横断図拡大ダイアログの表示
    _crossZoomDlg.show(dlgInst.levelList);
    $("#crossZoom").css("display", "flex");

    // 観測所タイプの判定
    var riverInfo = null;
    if ($("#obsDlg").getExtVal("riverInfo") != null) {
      riverInfo = dlgInst.createCrossPrm("obsZoom");
    } else if ($("#obsDlg").getExtVal("tmRiverInfo") != null) {
      var tmObsData = $("#obsDlg").getExtVal("tmObsData");
      riverInfo = dlgInst.createTmCrossPrm(tmObsData, "obsZoom");
    }
    riverInfo.divWidth = $("#crossZoomContent").width();
    riverInfo.divHeight = $("#crossZoomContent").height();

    $("#crossZoomDlg").setExtVal("riverInfo", riverInfo);
    if (isEmpty(dlgInst.spSurface)) {
      $("div#crossZoom.cross-frame").css("overflow-x", "scroll");
      var points = getEndPoint(riverInfo);
      $("#crossZoomDlg").setExtVal("points", points);
      // 横断図の作成
      createCossSectionData(points, riverInfo);
    } else {
      //特殊横断の場合
      $("div#crossZoom.cross-frame").css("overflow-x", "hidden");
      riverInfo.spSurface = dlgInst.spSurface;
      var points = getSpEndPoint(riverInfo);
      $("#crossZoomDlg").setExtVal("points", points);
      createSpCossSectionData(points, riverInfo);
    }
    //スクロール対応
    setTimeout(function () {
      $("#crossZoomContent").setWidthScrollPos(50);
    }, 400);
  }

  /**
   * LINE投稿
   * ----------------------------------------------------------------------------
   */
  postLine() {
    var dlgInst = $("#obsDlg").getExtVal("instance");

    // 現在のURLのパスとクエリ(パラメータ)の取得
    var currentQuery = location.search;
    var currentPath = location.pathname;
    // HTTP referrer対応の為、現在のURLを観測所緯度経度に書き換え
    var obsQuery = dlgInst.changeObsUrl(location.search, this.latlng);
    history.replaceState(null, "", encodeURIComponent(obsQuery));

    // 投稿URLの作成
    var lineUrl = dlgInst.config.snsSettings.lineUrl;
    var lineText = "";
    var deviceType = this.getDevice();
    if (deviceType === "sp" || deviceType === "tab") {
      lineText = dlgInst.createPostContents("lineMb");
      lineUrl = lineUrl + lineText;
    } else {
      lineText = dlgInst.createPostContents("linePc");
      lineUrl = lineUrl + encodeURIComponent(lineText);
    }

    var win = window.open("./loading.html", "_blank");
    win.location.href = lineUrl;

    // 元のURLのパスとクエリを再設定
    history.replaceState(null, "", currentPath + currentQuery);
  }

  /**
   * デバイス判定
   * ----------------------------------------------------------------------------
   */
  getDevice() {
    var ua = navigator.userAgent;
    if (
      ua.indexOf("iPhone") > 0 ||
      ua.indexOf("iPod") > 0 ||
      (ua.indexOf("Android") > 0 && ua.indexOf("Mobile") > 0)
    ) {
      return "sp";
    } else if (ua.indexOf("iPad") > 0 || ua.indexOf("Android") > 0) {
      return "tab";
    } else {
      return "other";
    }
  }

  /**
   * Twitter投稿
   * ----------------------------------------------------------------------------
   */
  postTwitter() {
    // obsDlgオブジェクトに設定している値の初期化
    $("#obsDlg").setExtVal("dispPostImgFlg", null);
    $("#obsDlg").setExtVal("dlgCapData", null);
    $("#obsDlg").setExtVal("dlgCapSize", null);
    $("#obsDlg").setExtVal("iniPostContents", null);

    var dlgInst = $("#obsDlg").getExtVal("instance");

    // 投稿内容初期値の保持
    var iniPostContents = this.createPostContents("twitter");

    // ダイアログ画像の取得
    if (dlgInst.tabOrder === 2 || dlgInst.tabOrder === 3) {
      var cctvUrl = $("#cctvZoomDlg").getExtVal("src");
      if (!cctvUrl) {
        var errorMsg = "カメラ画像取得エラーの為Twitter投稿できません。";
        _twitterErrorDlg.show(errorMsg);
        return;
      }
      var urlCnt = cctvUrl.split("?mode=").length;
      if (urlCnt == 2) {
        this.getDlgCaptionData();
        $("#obsDlg").setExtVal("dispPostImgFlg", true);
      } else {
        var cctvFrame = dlgInst.config.snsSettings.twitterCctvFrame;
        var postContents = cctvFrame.replace("*CCTVURL*", cctvUrl);
        iniPostContents += postContents;
      }
    } else {
      this.getDlgCaptionData();
      $("#obsDlg").setExtVal("dispPostImgFlg", true);
    }
    $("#obsDlg").setExtVal("iniPostContents", iniPostContents);

    // ポップアップブロック対応
    var subWin = window.open("./loading.html", "APPROVAL");

    // AccessTokenの保持判定
    var acsToken = $("#obsDlg").getExtVal("acsToken");
    var acsTokenSecret = $("#obsDlg").getExtVal("acsTokenSecret");
    if (acsToken && acsTokenSecret) {
      window.open("./twitterCheck.html?oauth_verifier=oauth", "APPROVAL");
    } else {
      var sbmtr = new Requester(_CNST.API_URL).create();
      sbmtr.register("reqTwitter", {}, (data) => {
        if (data && data.reqTokenInfo) {
          $("#obsDlg").setExtVal("reqTokenInfo", data.reqTokenInfo);
          subWin.location.href = data.url;
        } else {
          window.open("", "APPROVAL").close();
          var errorMsg = "Twitterに接続できません。";
          _twitterErrorDlg.show(errorMsg);
        }
      });
      sbmtr.submit(dlgInst).catch((e) => {
        window.open("", "APPROVAL").close();
        var errorMsg = "Twitterに接続できません。";
        _twitterErrorDlg.show(errorMsg);
      });
    }
  }

  /**
   * 投稿内容初期値作成
   * @param {String} type "line" or "twitter"
   * ----------------------------------------------------------------------------
   */
  createPostContents(type) {
    var dlgInst = $("#obsDlg").getExtVal("instance");

    var name = "";
    var riverName = "";

    // 河川名、水位計名 or カメラ名の取得
    if (dlgInst.type === "swin") {
      // 危機管理型水位計 or 河川カメラ
      name = dlgInst.obs.name;
      if (dlgInst.mode === "OBS") {
        riverName = dlgInst.obs.rvrName;
      }
    } else if (dlgInst.mode === "OBS") {
      // 通常水位計
      name = dlgInst.obs.obsName;
      riverName = dlgInst.obs.riverName;
    } else {
      // 通常河川カメラ
      name = dlgInst.obs.cctvName;
    }

    var postFrame = "";
    var positionUrl = "";
    var postContents = "";
    if (type === "lineMb") {
      postFrame = dlgInst.config.snsSettings.linePostMbFrame;
      positionUrl = this.changeObsUrl(location.href, this.latlng);
      postContents = postFrame
        .replace("*RIVER*", riverName)
        .replace("*NAME*", name)
        .replace("*URL*", positionUrl);
    } else if (type === "linePc") {
      postFrame = dlgInst.config.snsSettings.linePostPcFrame;
      postContents = postFrame
        .replace("*RIVER*", riverName)
        .replace("*NAME*", name);
    } else {
      postFrame = dlgInst.config.snsSettings.twitterPostFrame;
      positionUrl = this.changeObsUrl(location.href, this.latlng);
      postContents = postFrame
        .replace("*RIVER*", riverName)
        .replace("*NAME*", name)
        .replace("*URL*", positionUrl);
    }
    return postContents;
  }

  /**
   * FaceBook投稿URL作成
   * ----------------------------------------------------------------------------
   */
  createFacebookLink() {
    var dlgInst = $("#obsDlg").getExtVal("instance");

    var facebookUrl = dlgInst.config.snsSettings.facebookUrl;
    var facebookQuery = dlgInst.config.snsSettings.facebookQuery;

    // 現在のURLを取得
    var facebookHref = location.href;

    // HTTP referrer対応の為、現在のURLを観測所緯度経度に書き換え
    if (dlgInst.latlng) {
      facebookHref = dlgInst.changeObsUrl(facebookHref, dlgInst.latlng);
    }

    //iframe用URLを作成
    facebookUrl =
      facebookUrl + "href=" + encodeURIComponent(facebookHref) + facebookQuery;
    return facebookUrl;
  }

  /**
   * URL緯度経度パラメータの変更
   *
   * 現在のURLクエリ内の緯度経度を観測所の緯度経度に変更する。
   * @param {String} url 現在のURLのクエリ部分
   * @param {Object} latlng 観測所の緯度経度{lat:○○.○○○, lng:○○○.○○○}
   * ----------------------------------------------------------------------------
   */
  changeObsUrl(url, latlng) {
    var obsUrl = url
      .replace(/(clat=)\d+(?:\.\d+)?/, "clat=" + latlng.lat)
      .replace(/(clon=)\d+(?:\.\d+)?/, "clon=" + latlng.lng);

    return obsUrl;
  }

  /**
   * 観測所ダイアログキャプチャ取得
   * ----------------------------------------------------------------------------
   */
  getDlgCaptionData() {
    var contentWid = Math.ceil($("#contentsArea").width());
    var contentHght = Math.ceil($("#contentsArea").height());

    var element = document.getElementById("contentsArea");
    if (element) {
      html2canvas(element, {
        proxy: true,
        useCORS: true,
        logging: true,
      })
        .then(function (canvas) {
          var base64 = canvas.toDataURL();
          $("#obsDlg").setExtVal("dlgCapData", base64);
          $("#obsDlg").setExtVal("dlgCapSize", {
            width: contentWid,
            height: contentHght,
          });
        })
        .catch(function (error) {
          console.log(error);
          $("#obsDlg").setExtVal("dlgCapData", "error");
          $("#obsDlg").setExtVal("dlgCapSize", "error");
        });
    } else {
      $("#obsDlg").setExtVal("dlgCapData", "error");
      $("#obsDlg").setExtVal("dlgCapSize", "error");
    }
  }

  /**
   * 観測値一覧表示
   */
  dispLevelList() {
    const dlgInst = $("#obsDlg").getExtVal("instance");

    //横断パラメータ作成
    let riverInfo = null;
    let levelParam = null;
    if (dlgInst.type == "swin") {
      riverInfo = dlgInst.createCrossPrm("levelList");

      // 天端高からの観測開始水位
      let startLevel = dlgInst.obs.startLevel - dlgInst.obs.fladLevel;
      startLevel = Math.round(startLevel * 100) / 100;
      // 天端高からの危険水位
      let warnLevel = null;
      // 危険水位判定
      if (dlgInst.obs.warnLevel != null && dlgInst.obs.warnLevel != "") {
        warnLevel = dlgInst.obs.warnLevel - dlgInst.obs.fladLevel;
        warnLevel = Math.round(warnLevel * 100) / 100;
      }
      // リストパラメータ作成
      levelParam = {
        level: {
          startLevel: startLevel,
          warnLevel: warnLevel,
          fladLevel: 0,
        },
        bank: dlgInst.obs.fladLevel,
        obsValue: riverInfo.stages,
      };
    } else if (dlgInst.type == "TM") {
      const tmObsData = $("#obsDlg").getExtVal("tmObsData");
      riverInfo = dlgInst.createTmCrossPrm(tmObsData, "levelList");

      //TODO: 書き換え
      /*
      const bankHeight = Math.min(
        tmObsData.obs.bankHeight1,
        tmObsData.obs.bankHeight2
      );
      */
      let bankHeight = null;
      if (tmObsData.obs.bankHeight1 <= tmObsData.obs.riverBottom || tmObsData.obs.bankHeight2 <= tmObsData.obs.riverBottom) {
        console.log("堤防高非常算出");
        bankHeight = Math.max(tmObsData.obs.bankHeight1, tmObsData.obs.bankHeight2)
      } else {
        bankHeight = Math.min(tmObsData.obs.bankHeight1, tmObsData.obs.bankHeight2);
      }
  

      // 基準水位の設定
      let startLevel = null; //避難判断水位
      let warnLevel = null; //氾濫危険水位
      const alititudeStandardValue = dlgInst.getAlititudeStandardValue(
        tmObsData.obs.alititudeStandardCode
      );
      if (tmObsData.stage.warnStage != null) {
        startLevel =
          tmObsData.stage.warnStage +
          tmObsData.obs.zeroHigh +
          alititudeStandardValue -
          bankHeight;
        startLevel = Math.round(startLevel * 100) / 100;
      }
      if (tmObsData.stage.dangerStage != null) {
        warnLevel =
          tmObsData.stage.dangerStage +
          tmObsData.obs.zeroHigh +
          alititudeStandardValue -
          bankHeight;
        warnLevel = Math.round(warnLevel * 100) / 100;
      }

      // リストパラメータ作成
      levelParam = {
        level: {
          startLevel: startLevel,
          warnLevel: warnLevel,
          fladLevel: 0,
        },
        bank: bankHeight,
        obsValue: riverInfo.stages,
      };
    }

    // 観測値一覧ダイアログの作成
    setTimeout(
      function (levelParam, dlgInst, riverInfo) {
        _levelListDlg.show(
          levelParam,
          dlgInst,
          riverInfo,
          dlgInst.levelListDlgRenew
        );
      },
      400,
      levelParam,
      dlgInst,
      riverInfo
    );
  }

  /**
   * 観測値一覧ダイアログの更新
   *
   */
  levelListDlgRenew() {
    $("#levelListDlgClose").off("click");
    $("#levelListDlgBk").css("display", "none");
    $("#crossContents").html("");

    const dlgInst = $("#obsDlg").getExtVal("instance");
    if (dlgInst.type == "swin") {
      dlgInst.getLevels(dlgInst.orgParam);
    } else {
      dlgInst.getTmObsData(dlgInst.obs);
    }
    setTimeout(
      function (inst) {
        dlgInst.dispLevelList();
      },
      400,
      dlgInst
    );
  }

  /**
   * テレメータ観測所A点B点描画
   */
  async drawTmCrossRange() {
    const tmObsData = $("#obsDlg").getExtVal("tmObsData");

    try {
      // 河川ラインデータ取得
      const url =
        this.config.tmRiverLineUrl + "/" + tmObsData.obs.riverCode + ".json";
      const riverWidth = tmObsData.obs.riverWidth; // 川幅
      if (!riverWidth) {
        //return null;
        throw "川幅が取得できません。";
      }
      const riverLines = []; // 河川ラインリスト
      let nearestRiverLine = null; // 最も近い河川ライン

      const latlngPoint = [this.latlng.lng, this.latlng.lat];
      const obsPoint = turf.point(latlngPoint); // 観測所位置

      let minDistance = null;

      await $.ajax({
        url: url,
        beforeSend: function (jqXHR, settings) {
          jqXHR.requestURL = settings.url;
        },
      })
        .then((gjson, textResponse, jqXHR) => {
          for (const idx in gjson.features) {
            if (gjson.features[idx].geometry.type === "LineString") {
              riverLines.push(
                turf.lineString(gjson.features[idx].geometry.coordinates)
              );
            } else if (
              gjson.features[idx].geometry.type === "MultiLineString"
            ) {
              const lines = gjson.features[idx].geometry.coordinates;
              for (var idx2 in lines) {
                riverLines.push(turf.lineString(lines[idx2]));
              }
            }
          }

          // 近隣点を求める
          for (const idx in riverLines) {
            const distance = turf.pointToLineDistance(obsPoint, riverLines[idx], {
              units: "kilometers",
            }); // 近隣点との距離

            //観測所までの距離が最短の近隣線
            if (distance < minDistance || minDistance == null) {
              minDistance = distance;
              nearestRiverLine = riverLines[idx]; // 近隣線
            }
          }

          const nearestPoint = turf.nearestPointOnLine(
            nearestRiverLine,
            obsPoint,
            {
              units: "kilometers",
            }
          ); // 近隣点
          const bearing = turf.bearing(obsPoint, nearestPoint); // 方位

          // 描画範囲の両端（A地点、B地点）を求める
          // ・川幅が300m以下の場合、河川両岸から300m延長した地点を描画範囲両端とする。
          // ・川幅が300mを超える場合、河川両岸から川幅分だけ延長した地点を描画範囲両端とする。
          const adjust = riverWidth <= 300 ? 300 : riverWidth;
          const pt1 = turf.destination(
            nearestPoint,
            (riverWidth + adjust) / 1000,
            bearing,
            {
              units: "kilometers",
            }
          );
          const pt2 = turf.destination(
            nearestPoint,
            -((riverWidth + adjust) / 1000),
            bearing,
            {
              units: "kilometers",
            }
          );

          // 岸分類
          const bankSct = tmObsData.obs.bankSct;
          if (bankSct != null
            && tmObsData.obs.bankHeight1 >= tmObsData.obs.riverBottom && tmObsData.obs.bankHeight2 >= tmObsData.obs.riverBottom
          ) {
            // 既存の横断線・A点B点アイコンの削除
            if (this.line != null) {
              this.line.remove();
            }
            if (this.markerA != null) {
              this.markerA.remove();
            }
            if (this.markerB != null) {
              this.markerB.remove();
            }

            if (isEmpty(this.spSurface)) {
              // A点B点の緯度経度取得
              const danmenPoint = this.getTmPointAB(bankSct, pt1, pt2);
              // 横断線の描画
              this.line = L.polyline([danmenPoint.a, danmenPoint.b], {
                color: this.config.crossSettings.onMapLine.color,
                weight: this.config.crossSettings.onMapLine.weight,
                opacity: this.config.crossSettings.onMapLine.opacity,
              }).addTo(this.map);
              // A点B点アイコンの設定
              this.markerA = L.marker(danmenPoint.a, {
                icon: this.aPointIcon,
              }).addTo(this.map);
              this.markerB = L.marker(danmenPoint.b, {
                icon: this.bPointIcon,
              }).addTo(this.map);
            }

            // A'点B'点の取得
            // ・河川ラインと観測所の交点から「川幅/2」の距離だけ観測所正方向及び反対方向に進んだ地点。
            const pt1D = turf.destination(
              nearestPoint,
              riverWidth / 2 / 1000,
              bearing,
              {
                units: "kilometers",
              }
            );
            const pt2D = turf.destination(
              nearestPoint,
              -(riverWidth / 2 / 1000),
              bearing,
              {
                units: "kilometers",
              }
            );
            const danmenPointD = this.getTmPointAB(bankSct, pt1D, pt2D);
            $("#obsDlg").setExtVal("danmenPointD", danmenPointD);

            // 横断図パラメータ作成
            const riverInfo = this.createTmCrossPrm(tmObsData, "obs");
            $("#obsDlg").setExtVal("tmRiverInfo", riverInfo);
          }else{
            //console.log("case 0");
          }
        })
        .catch((jqXHR, textStatus, errorThrown) => {
          this.line = null;
          this.markerA = null;
          this.markerB = null;
        });
    } finally {
      // 品管フラグ
      $("#qtImg").css("display", "none");

      // 表示切替（観測所写真ボタン）
      $("#obsPic").css("display", "none");

      // 表示切替（横断図ボタン）
      if ($("#obsDlg").getExtVal("danmenPointD") != null) {
        //特殊横断の場合は測量横断データがある場合のみ表示する
        if (
          !isEmpty($("#obsDlg").getExtVal("instance").spSurface) &&
          $("#obsDlg").getExtVal("tmRiverInfo").points.length <= 0
        ) {
          $("#crossBtn").css("display", "none");
          //console.log("case 1");
        } else {
          $("#crossBtn").css("display", "inline");
          //console.log("case 2");
        }
      } else {
        $("#crossBtn").css("display", "none");
        //console.log("case 3");
      }

      // ハイドロボタン
      $("#hydroBtn").css("display", "inline");

      // 表示切替（CCTVカメラ画像現状）
      //console.log("tmObsData.cctv.latestUrl:" + tmObsData.cctv.latestUrl);
      if (tmObsData.cctv.latestUrl != null) {
        $("#camNowBtn").css("display", "inline");
      } else {
        $("#camNowBtn").css("display", "none");
      }

      // 表示切替（CCTVカメラ画像平常）
      //console.log("tmObsData.cctv.usualUrl:" + tmObsData.cctv.usualUrl);
      if (tmObsData.cctv.usualUrl != null) {
        $("#camDefBtn").css("display", "inline");
      } else {
        $("#camDefBtn").css("display", "none");
      }

      // 観測値一覧
      $("#numListBtn").css("display", "inline");

      // ダイアログの作成
      this._dlgDraw();
    }
  }

  /**
   * テレメータの横断図A点B点設定処理
   *
   * @param {String} bankSct '左岸' or '右岸'
   * @param {object} pt1 緯度経度情報
   * @param {object} pt2 緯度経度情報
   */
  getTmPointAB(bankSct, pt1, pt2) {
    var danmenPoint = null;
    switch (bankSct) {
      case "左岸":
        danmenPoint = {
          a: [pt2.geometry.coordinates[1], pt2.geometry.coordinates[0]],
          b: [pt1.geometry.coordinates[1], pt1.geometry.coordinates[0]],
        };
        break;
      case "右岸":
        danmenPoint = {
          a: [pt1.geometry.coordinates[1], pt1.geometry.coordinates[0]],
          b: [pt2.geometry.coordinates[1], pt2.geometry.coordinates[0]],
        };
        break;
      default:
        danmenPoint = null;
        break;
    }

    return danmenPoint;
  }

  /**
   * ハイドログラフのパラメータ作成
   * (危機管理水位計)
   * @return {object} ハイドログラフのパラメータ
   */
  createHydroPrm() {
    const dlgInst = $("#obsDlg").getExtVal("instance");

    const hydroPrm = {
      // 天端高・堤防高の標高値(m)
      // y軸の0になる標高値
      bank: dlgInst.obs.fladLevel,
      zeroHigh: 0 - dlgInst.obs.fladLevel,
      // エラー時メッセージ
      error: {
        message: "グラフの作成に失敗しました。",
        color: "rgb(255,0,0)",
      },
      // 基準水位
      level: [
        {
          levelName: "obsLevel",
          label: "観測水位",
          value: "obsValue",
          color: "rgba(0,127,190)",
        },
        {
          levelName: "flad",
          label: "氾濫開始水位",
          value: 0,
          height: 1,
          color: "rgba(0,0,0,0.7)",
          background: "rgba(0,0,0,0.2)",
        },
      ],
      // 観測値
      obsValue: [],
    };

    //観測値の日付フォーマット変更と昇順・降順入れ替え
    const len = dlgInst.levelList.length;

    for (let ix = 0; ix < len; ix++) {
      const value = dlgInst.levelList[len - ix - 1];
      console.log("createHydroPrm: " + JSON.stringify(value));
      hydroPrm.obsValue.push({
        date: dlgInst.chartDateFormat(value.date),
        elevation: value.level,
      });
    }

    // 基準水位の判定
    if (dlgInst.obs.warnLevel != null) {
      // 基準値に危険水位がある場合
      hydroPrm.level.push(
        {
          levelName: "start",
          label: "観測開始水位",
          value: dlgInst.obs.startLevel - dlgInst.obs.fladLevel,
          height: dlgInst.obs.warnLevel - dlgInst.obs.startLevel,
          color: "rgba(255,170,0,0.7)",
          background: "rgba(255,170,0,0.2)",
        },
        {
          levelName: "warn",
          label: "危険水位",
          value: dlgInst.obs.warnLevel - dlgInst.obs.fladLevel,
          height: dlgInst.obs.fladLevel - dlgInst.obs.warnLevel,
          color: "rgba(247,0,205,0.7)",
          background: "rgba(247,0,205,0.2)",
        }
      );
    } else {
      hydroPrm.level.push({
        levelName: "start",
        label: "観測開始水位",
        value: dlgInst.obs.startLevel - dlgInst.obs.fladLevel,
        height: dlgInst.obs.fladLevel - dlgInst.obs.startLevel,
        color: "rgba(255,170,0,0.7)",
        background: "rgba(255,170,0,0.2)",
      });
    }

    return hydroPrm;
  }

  /**
   * ハイドログラフのパラメータ作成
   * (テレメータ)
   * @param {Object} obsData 観測所詳細情報
   * @return {Object} ハイドログラフのパラメータ
   */
  createTmHydroPrm(obsData) {
    const dlgInst = $("#obsDlg").getExtVal("instance");
    let bankHeight = null;
    if (obsData.obs.bankHeight1 <= obsData.obs.riverBottom || obsData.obs.bankHeight2 <= obsData.obs.riverBottom) {
      bankHeight = Math.max(obsData.obs.bankHeight1, obsData.obs.bankHeight2);
    }
    else {
      bankHeight = Math.min(obsData.obs.bankHeight1, obsData.obs.bankHeight2);
    }
    const hydroPrm = {
      // 天端高・堤防高の標高値(0)
      // y軸の0になる標高値
      //bank: Math.min(obsData.obs.bankHeight1, obsData.obs.bankHeight2),
      bank: bankHeight,
      // 河床
      zeroHigh: obsData.obs.riverBottom,
      // エラーメッセージ
      error: {
        message: "グラフの作成に失敗しました。",
        color: "rgb(255,0,0)",
      },
      // 基準水位
      level: [
        {
          levelName: "obsLevel",
          label: "観測水位",
          value: "obsValue",
          color: "rgba(0,127,190)",
        },
        {
          levelName: "flad",
          label: "堤防天端",
          value: 0,
          height: 1,
          color: "rgba(0,0,0,0.7)",
          background: "rgba(0,0,0,0.2)",
        },
      ],
      // 観測値
      obsValue: [],
    };

    $.each(obsData.stage.stages, function (i, v) {
      let elevation = null;
      let elevationWord = "通常";
      if (v.value != null) {
        elevation = v.value + obsData.obs.zeroHigh;
      } else {
        elevationWord = dlgInst._getStageFlagWord(v.stageFlag);
        console.log("createTmHydroPrm: elevationWord:" + elevationWord);
      }
      hydroPrm.obsValue.push({
        date: v.date,
        elevation: elevation,
        elevationWord: elevationWord
      });
    });

    // 基準水位の判定
    /*
    const bankHeight = Math.min(obsData.obs.bankHeight1, obsData.obs.bankHeight2);
    */
    const levelval = {
      start: bankHeight,
      warn: bankHeight,
      flad: bankHeight,
    };

    // 標高基準面補正値を取得する
    const alititudeStandardValue = dlgInst.getAlititudeStandardValue(
      obsData.obs.alititudeStandardCode
    );
    if (obsData.stage.warnStage != null) {
      levelval.start =
        obsData.stage.warnStage +
        obsData.obs.zeroHigh
        + alititudeStandardValue;
    }
    if (obsData.stage.dangerStage != null) {
      levelval.warn =
        obsData.stage.dangerStage +
        obsData.obs.zeroHigh +
        alititudeStandardValue;
    }
    // 20200615 超過色変更対応
    /*
    if (obsData.stage.warnStage != null) {
      hydroPrm.level.push({
        levelName: "start",
        label: "避難判断水位",
        value: levelval.start - levelval.flad,
        height: levelval.warn - levelval.start,
        color: "rgba(255,170,0,0.7)",
        background: "rgba(255,170,0,0.2)"
      });
    }
    if (obsData.stage.dangerStage != null) {
      hydroPrm.level.push({
        levelName: "warn",
        label: "氾濫危険水位",
        value: levelval.warn - levelval.flad,
        height: levelval.flad - levelval.warn,
        color: "rgba(247,0,205,0.7)",
        background: "rgba(247,0,205,0.2)"
      });
    }
    */
    if (obsData.stage.warnStage != null) {
      hydroPrm.level.push({
        levelName: "start",
        label: dlgInst.config.graphSettings.start.label,
        value: levelval.start - levelval.flad,
        height: levelval.warn - levelval.start,
        color: dlgInst.config.graphSettings.start.color,
        background: dlgInst.config.graphSettings.start.background,
      });
    }
    if (obsData.stage.dangerStage != null) {
      hydroPrm.level.push({
        levelName: "warn",
        label: dlgInst.config.graphSettings.warn.label,
        value: levelval.warn - levelval.flad,
        height: levelval.flad - levelval.warn,
        color: dlgInst.config.graphSettings.warn.color,
        background: dlgInst.config.graphSettings.warn.background,
      });
    }

    return hydroPrm;
  }

  /**
   * 横断図パラメータ作成 (危機管理水位計)
   * @param {String} dlgType "obs"(観測所) or "obsZoom"(観測所拡大) or "levelList"(観測値一覧)
   * @return {Object} 横断図のパラメータ
   */
  createCrossPrm(dlgType) {
    const dlgInst = $("#obsDlg").getExtVal("instance");

    // 描画対象divIdの設定
    let divId = null;
    if (dlgType === "obs") {
      divId = "#cross";
    } else if (dlgType === "obsZoom") {
      divId = "#crossZoomContent";
    } else if (dlgType === "levelList") {
      divId = "#crossContents";
    }

    // 観測水位データのと昇順・降順入れ替え
    const vals = [];
    if (dlgInst.levelList == null) return;
    const len = dlgInst.levelList.length;
    for (let ix = 0; ix < len; ix++) {
      const value = dlgInst.levelList[len - ix - 1];
      //console.log("createCrossPrm", dlgInst.levelList);
      vals.push({
        date: dlgInst.chartDateFormat(value.date),
        elevation: value.level,
        qt: value.qt,
      });
    }

    // 基準水位の設定
    let baseLevels = null;
    if (dlgInst.obs.warnLevel != null) {
      baseLevels = {
        // 観測開始水位
        stageLvl1: {
          label: "観測開始",
          value: dlgInst.obs.startLevel,
        },
        // 危険水位
        stageLvl2: {
          label: "危険水位",
          value: dlgInst.obs.warnLevel,
        },
        // 氾濫開始水位
        stageLvl3: {
          label: "氾濫開始",
          value: dlgInst.obs.fladLevel,
        },
      };
    } else {
      baseLevels = {
        // 観測開始水位
        stageLvl1: {
          label: "観測開始",
          value: dlgInst.obs.startLevel,
        },
        // 氾濫開始水位
        stageLvl3: {
          label: "氾濫開始",
          value: dlgInst.obs.fladLevel,
        },
      };
    }

    //横断パラメータ作成
    var riverInfo = {
      // 川幅
      rvrwid: dlgInst.getRiverWidth(dlgInst.obs),
      // 河床標高
      // 英語化のみ使用
      // nullの場合は河川内標高最低値を使用
      rvrbed: null,

      // A’地点緯度経度
      leftBankInfo: {
        lat: dlgInst.obs.leftBank.coordinate.lat,
        lon: dlgInst.obs.leftBank.coordinate.lon,
        elevation: dlgInst.obs.leftBank.height,
      },

      // B’地点緯度経度
      rightBankInfo: {
        lat: dlgInst.obs.rightBank.coordinate.lat,
        lon: dlgInst.obs.rightBank.coordinate.lon,
        elevation: dlgInst.obs.rightBank.height,
      },

      // 堤防高
      // 最高描画ライン決定に使用
      upgate: dlgInst.obs.fladLevel,
      // 観測開始水位扱い
      // 英語化はnull許容 / nullの場合は全て描画
      // 最低描画ライン決定・河川描画領域内の地形描画判断に使用
      strtStage: dlgInst.obs.startLevel,

      // 断面零点高
      // null許容
      zeroHeight: null,

      // 横断データ(TODO:仮処置)
      points: dlgInst.surface,
      // 左限界点
      surfaceLeftLim: dlgInst.surfaceLeftLim,
      // 右限界点
      surfaceRghtLim: dlgInst.surfaceRghtLim,

      // 基準水位
      levelValue: baseLevels,

      // 過去150回分の観測水位
      stages: vals,

      // 各水位超過時塗り色
      stgFillColor: {
        normal: dlgInst.config.crossSettings.stgFillColor.normal,
        stageLvl1: dlgInst.config.crossSettings.stgFillColor.stageLvl1,
        stageLvl2: dlgInst.config.crossSettings.stgFillColor.stageLvl2,
        stageLvl3: dlgInst.config.crossSettings.stgFillColor.stageLvl3,
      },

      // 堤外地塗り色
      lndFillColor: dlgInst.config.crossSettings.lndFillColor,

      strokeStyle: {
        // 堤外地線色
        strokeColor: dlgInst.config.crossSettings.strokeStyle.strokeColor,
        // 堤外地線太さ
        strokeWidth: dlgInst.config.crossSettings.strokeStyle.strokeWidth,
      },

      // システムID
      // 英語化→ph2
      // 危機管理→swin
      sys: "swin",

      // 川幅描画%決定に使用
      rvrwidCfg1: dlgInst.config.crossSettings.rvrwidCfg1,
      rvrwidCfg2: dlgInst.config.crossSettings.rvrwidCfg2,

      // 描画最高位置(%)
      highCfg1: dlgInst.config.crossSettings.highCfg1,
      // 描画最低位置(%)
      lowCnf1: dlgInst.config.crossSettings.lowCnf1,

      // 標高タイル使用時表示文言
      // 改行位置は / で指定
      // 横半角30文字を超えないこと
      tileAnnotation: dlgInst.config.crossSettings.tileAnnotation,

      // 描画対象divId
      divId: divId,

      // 河川領域描画幅の決定に使用
      riverDrawWidth: dlgInst.getRiverWidth(dlgInst.obs),

      legendButtonLabel: {
        on: "凡例を表示する",
        off: "凡例を非表示にする",
      },

      obsLabel: "堤防天端高から",
    };

    return riverInfo;
  }

  /**
   * 横断図パラメータ作成 (テレメータ)
   * @param {Object} obsData 観測所情報
   * @param {String} dlgType "obs"(観測所) or "obsZoom"(観測所拡大) or "levelList"(観測値一覧)
   * @return {Object} テレメータ横断図のパラメータ
   */
  createTmCrossPrm(obsData, dlgType) {
    const dlgInst = $("#obsDlg").getExtVal("instance");

    // 描画対象divIdの設定
    let divId = null;
    if (dlgType === "obs") {
      divId = "#cross";
    } else if (dlgType === "obsZoom") {
      divId = "#crossZoomContent";
    } else if (dlgType === "levelList") {
      divId = "#crossContents";
    }

    // 標高基準面補正値を取得する
    const alititudeStandardValue = dlgInst.getAlititudeStandardValue(
      obsData.obs.alititudeStandardCode
    );

    // 観測水位の設定
    const vals = $.map(obsData.stage.stages, function (v, k) {
      let elevation;
      let elevationWord = "通常";

      if (v.stageFlag == null || v.stageFlag > 70 || v.value == null) {
        // 水位内容コードが null or 70超、または 水位が null の場合は無効値とする
        elevation = null;
        elevationWord = dlgInst._getStageFlagWord(v.stageFlag);
      } else {
        // 観測水位を標高値（水位＋零点高）に変換する
        elevation = v.value + obsData.obs.zeroHigh;
      }
      return {
        date: v.date,
        elevation: elevation,
        elevationWord: elevationWord
      };
    });

    // 河川基準面を取得する
    const rvrBaseHghtValue = this.getAlititudeStandardValue(
      obsData.cross.rvrBaseHght
    );

    const points = $.map(obsData.cross.points, function (v, k) {
      return {
        drawLength: v.drawLength,
        // 縦データを標高値（縦データ＋断面零天高+補正値）に変換する
        drawHeight: v.drawHeight + obsData.cross.zeroHigh + rvrBaseHghtValue,
      };
    });

    let stageLvl1 = null;
    let stageLvl2 = null;
    //const stageLvl3 = Math.min(obsData.obs.bankHeight1, obsData.obs.bankHeight2);
    let stageLvl3 = null;
    if (obsData.obs.bankHeight1 <= obsData.obs.riverBottom || obsData.obs.bankHeight2 <= obsData.obs.riverBottom) {
      console.log("堤防高非常算出");
      stageLvl3 = Math.max(obsData.obs.bankHeight1, obsData.obs.bankHeight2)
    } else {
      stageLvl3 = Math.min(obsData.obs.bankHeight1, obsData.obs.bankHeight2);
    }

    // 基準水位を標高値（水位＋零点高＋標高基準面補正値）に変換する
    if (obsData.stage.warnStage != null) {
      stageLvl1 =
        obsData.stage.warnStage +
        obsData.obs.zeroHigh+
        alititudeStandardValue;
    }
    if (obsData.stage.dangerStage != null) {
      stageLvl2 =
        obsData.stage.dangerStage +
        obsData.obs.zeroHigh +
        alititudeStandardValue;
    }

    // A'地点・B'地点情報の取得
    const danmenPointD = $("#obsDlg").getExtVal("danmenPointD");

    let riverInfo = null;
    if (danmenPointD != null) {
      riverInfo = {
        // システムID
        // 英語化→ph2
        // 危機管理→swin
        sys: "ph2",

        // 川幅
        rvrwid: obsData.obs.riverWidth,

        // A’地点情報
        leftBankInfo: {
          lat: danmenPointD.a[0],
          lon: danmenPointD.a[1],
          elevation: obsData.obs.bankHeight1,
        },
        // B’地点緯度経度
        rightBankInfo: {
          lat: danmenPointD.b[0],
          lon: danmenPointD.b[1],
          elevation: obsData.obs.bankHeight2,
        },

        // 堤防高
        // 最高描画ライン決定に使用
        upgate: stageLvl3,

        // 観測開始水位扱い
        // 英語化はnull許容 / nullの場合は全て描画
        // 最低描画ライン決定・河川描画領域内の地形描画判断に使用
        strtStage: stageLvl1,

        // 河床標高
        // 英語化のみ使用
        // nullの場合は河川内標高最低値を使用
        rvrbed: obsData.obs.riverBottom,

        // 断面零点高
        // 英語化のみ使用
        zeroHeight: obsData.cross.zeroHigh,

        // 横断データ
        points: points,

        // 基準水位（標高値を指定）
        levelValue: {
          // 避難判断水位
          stageLvl1: {
            label: "避難判断水位",
            value: stageLvl1,
          },
          // 氾濫危険水位
          stageLvl2: {
            label: "氾濫危険水位",
            value: stageLvl2,
          },
          // 堤防高
          stageLvl3: {
            label: "堤防天端",
            value: stageLvl3,
          },
        },
        legendButtonLabel: {
          on: "凡例を表示する", // Water level explanation
          off: "凡例を非表示にする", // Hide explanation
        },
        obsLabel: "堤防天端から", // 堤防天端から

        // 過去100回分の観測水位
        stages: vals,

        // 標高取得地点数
        intervalValue: dlgInst.config.tmCrossSettings.intervalValue,

        // 川幅描画%決定に使用
        rvrwidCfg1: dlgInst.config.tmCrossSettings.rvrwidCfg1,
        rvrwidCfg2: dlgInst.config.tmCrossSettings.rvrwidCfg2,

        // 描画最高位置(%)
        highCfg1: dlgInst.config.tmCrossSettings.highCfg1,
        // 描画最低位置(%)
        lowCnf1: dlgInst.config.tmCrossSettings.lowCnf1,

        // 各水位超過時塗り色
        stgFillColor: {
          normal: dlgInst.config.tmCrossSettings.stgFillColor.normal,
          stageLvl1: dlgInst.config.tmCrossSettings.stgFillColor.stageLvl1,
          stageLvl2: dlgInst.config.tmCrossSettings.stgFillColor.stageLvl2,
          stageLvl3: dlgInst.config.tmCrossSettings.stgFillColor.stageLvl3,
        },

        // 標高タイル使用時に表示する文言
        // 改行は / を入れた位置で行う
        // 英語化の場合は英語で記入(改行ルールは同じ)
        // 横半角20文字くらいだと見栄えがいいです
        tileAnnotation: dlgInst.config.tmCrossSettings.tileAnnotation,

        // 堤外地塗り色
        lndFillColor: dlgInst.config.tmCrossSettings.lndFillColor,

        strokeStyle: {
          // 堤外地線色
          strokeColor: dlgInst.config.tmCrossSettings.strokeStyle.strokeColor,
          // 堤外地線太さ
          strokeWidth: dlgInst.config.tmCrossSettings.strokeStyle.strokeWidth,
        },

        // 描画対象divId
        divId: divId,

        // データが全てnullの場合のメッセージ
        allStageNullMes: dlgInst.config.tmCrossSettings.allStageNullMes,
      };
    } else {
      riverInfo = {
        // 過去100回分の観測水位
        stages: vals,
      };
    }
    //console.log("riverInfo:\n" + JSON.stringify(riverInfo));
    return riverInfo;
  }

  /**
   * 補正コードから補正値を返す
   *
   *
   * http://172.16.100.164:30002/issues/656
   *
   * @param {number} code
   */
  getAlititudeStandardValue(code) {
    /*
    if (code == null) {
      return 0;
    }

    const list = {
      0: 0, // TP(東京湾中等潮位)
      1: -0.8402, // YP(利根川)
      2: 1.1344, // AP(荒川)
      3: -1.3, // OP(淀川)
      4: -0.8333, // AP(吉野川)
      5: -0.6573, // OP(木曽川)
      6: -0.8745, // KP(北上川)
      7: -0.0873, // SP(成瀬川)
      8: 0.113, // TPW(渡川)
      9: 84.371, // BSL(琵琶湖)
    };

    return list[code];
    */
    return 0;
  }

  /**
   * テレメータCCTVURLの設定作成
   * @param {Object} data 観測所情報
   * @return {Object} テレメータCCTVのキャッシュURLを追加した観測所情報
   */
  /*
  createTmCctvUrl(data) {
    if (data.cctv.usualUrl != null) {
      // 熊本地震河川カメラの場合URLを整形
      let usualUrlStr = this.editUrl(data.cctv.usualUrl);
      if (usualUrlStr != null) {
        // 整形された熊本地震河川カメラのURLを設定
        data.cctv.usualUrl = usualUrlStr;
      }
      // 通常の河川カメラのURLを設定
      const usualName = data.cctv.usualUrl.split("/").pop().split("?")[0];
      data.cctv.normalUsualUrl = this.config.tmCctvUrl.usual
        .replace("%0%", data.cctv.cctvCd)
        .replace("%1%", usualName);
    }
    if (data.cctv.latestUrl != null) {
      // 熊本地震河川カメラの場合URLを整形
      let latestUrlStr = this.editUrl(data.cctv.latestUrl);
      if (latestUrlStr != null) {
        // 整形された熊本地震河川カメラのURLを設定
        data.cctv.latestUrl = latestUrlStr;
      }
      // 通常の河川カメラの設定
      const latestName = data.cctv.latestUrl.split("/").pop().split("?")[0];
      data.cctv.normalLatestUrl = this.config.tmCctvUrl.latest
        .replace("%0%", data.cctv.cctvCd)
        .replace("%1%", latestName);
    }
    return data;
  }
  */
  createTmCctvUrl(data, obsInfo){
    //let result = await dlgInst.getKwbObsData(dlgInst.config, data.obs.obsFcd);
    //if (result.obsInfo) {
    if (obsInfo) {
      if (obsInfo.normProvUrl != null) {
        // 熊本地震河川カメラの場合URLを整形
        let usualUrlStr = this.editUrl(obsInfo.normProvUrl);
        if (usualUrlStr != null) {
          // 整形された熊本地震河川カメラのURLを設定
          data.cctv.usualUrl = usualUrlStr;
        } else {
          data.cctv.usualUrl = obsInfo.normProvUrl;
        }
        // 通常の河川カメラのURLを設定
        data.cctv.normalUsualUrl = data.cctv.usualUrl;
      }
      if (obsInfo.currProvUrl != null) {
        // 熊本地震河川カメラの場合URLを整形
        const latestUrlStr = this.editUrl(obsInfo.currProvUrl);
        if (latestUrlStr != null) {
          // 整形された熊本地震河川カメラのURLを設定
          data.cctv.latestUrl = latestUrlStr;
        } else {
          data.cctv.latestUrl = obsInfo.currProvUrl;
        }
        // 通常の河川カメラの設定
        data.cctv.normalLatestUrl = data.cctv.latestUrl;
      }

      //観測所のかなをここで渡す
      //console.log("obsKana: " + obsInfo.obsKana);
      data.obs.obsKana = obsInfo.obsKana == null ? "": obsInfo.obsKana;
    }
    return data;
  }

  /**
   * 次期川防TM観測所詳細情報ファイル取得
   * @param {string} obsFcd 観測所番号
   */
  async getKwbObsData(config, obsFcd){
    const url = config.tmObsUrl + "/" + obsFcd + ".json?";
    let result = null;
    await $.ajax({
      url: url,
      type: "GET",
			cache: false
    }).done((data, st, jq) => {
      result = data;
    }).fail((jq, st, e) => {
      console.log(st,e);
    });
    return result;
  }

  /**
   * 熊本自身河川カメラ用メソッド
   * 正規表現で「<HENKANB(.*)M(.*)>」を持つURLを熊本地震河川カメラとみなし、
   * 該当文字列をyyyymmddhhmmssの形に置換する。
   * 熊本地震河川カメラのURL以外はnull値を返す。
   *
   * @param {String} url
   */
  editUrl(url) {
    if (!url) {
      return null;
    }
    const re = new RegExp("<HENKANB(.*)M(.*)>");
    const myArray = re.exec(url);
    if (!myArray || myArray.length < 3) {
      // 熊本地震河川カメラではないのでnullを返す。
      return null;
    }

    // 遡り時間(分)
    const b = myArray[1];
    // 撮影間隔(秒)
    const m = myArray[2];

    // 現在時刻
    const dt = new Date();
    // 現在時刻から遡り時間(分)を差し引く
    dt.setTime(dt.getTime() - b * 60 * 1000);
    const minnute = dt.getMinutes();
    const second = dt.getSeconds();

    // 撮影間隔に現在時間を合わせる。
    // 計算式「現在時間 - ((minnute % m ) * 60 * 1000 + (second * 1000))」
    const num = minnute % m;
    const diffTime = num * 60 * 1000 + second * 1000;

    dt.setTime(dt.getTime() - diffTime);

    // 計算後の時間をYYYMMDDhhmmssに変換する。
    const year = dt.getFullYear();
    const month = this.leftpad(dt.getMonth() + 1, 2, "0");
    const day = this.leftpad(dt.getDate(), 2, "0");
    const hour = this.leftpad(dt.getHours(), 2, "0");
    minnute = this.leftpad(dt.getMinutes(), 2, "0");

    const replace =
      year +
      month +
      "/" +
      day +
      "/" +
      year +
      month +
      day +
      hour +
      minnute +
      "00";
    const newUrl = url.replace(myArray[0], replace);

    return newUrl;
  }

  /**
   * 時間を整形する （熊本地震河川カメラ用）
   * @param {*} str
   * @param {*} len
   * @param {*} ch
   */
  leftpad(str, len, ch) {
    str = String(str);
    const i = -1;
    if (!ch && ch !== 0) ch = " ";
    len = len - str.length;
    while (++i < len) {
      str = ch + str;
    }
    return str;
  }
}
