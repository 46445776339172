/**
 * 住所表示パネルクラス
 * ------------------------------------------------------------------------------
 * @LIS 20180411 新規作成
 * ==============================================================================
 */
export default class{

  /**
   * コンストラクタ
   * ---------------------------------------------------------------------------
   */
  constructor() {
    this.addrStr = "";
    $("<div></div>",{
      id: "addrPanel",
      "class": "address-panel"
    }).appendTo("body");
  }

  /**
   * アドレス設定
   * @param {string} addr 住所
   * ---------------------------------------------------------------------------
   */
  set address(addr) {
    this.addrStr = addr;
    $("#addrPanel").html(this.addrStr);

    if (this.addrStr == "") {
      $("#addrPanel").hide();
    } else {
      $("#addrPanel").show();
    }
  }

  /**
   * アドレス取得
   * @return {string} 住所
   * ---------------------------------------------------------------------------
   */
  get address() {
    return this.addrStr;
  }
}