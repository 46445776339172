/**
 * 水位計画像表示ダイアログクラス
 * ------------------------------------------------------------------------------
 */
export default class {
  /**
   * コンストラクタ
   * ----------------------------------------------------------------------------
   */
  constructor() {
    var bkElm = $("<div id=\"obsPicDlgBk\"></div>").appendTo("body");
    bkElm.setExtVal("instance", this);
    bkElm.load("./obspicdlg.html", function(response, status, xhr) {
      var inst = $("#obsPicDlgBk").getExtVal("instance");
      $("#obsPicDlgCloseBtn").on("click", inst.hide);
    });
  }

  /**
   * 画面表示処理
   * @param {String} imgUrl 画像URL
   * ----------------------------------------------------------------------------
   */
  show(imgUrl) {
    $("#obsPicImg").attr("src", imgUrl);
    $("#obsPicDlgBk").css("display", "block");

    setTimeout(() => {
      var sw = $(window).width();
      var sh = $(window).height();
      var dw = $("#obsPicDlg").width();
      var dh = $("#obsPicDlg").height();
      var x = sw / 2 - (dw / 2);
      var y = sh / 2 - (dh / 2);
      $("#obsPicDlg").css("top", y + "px");
      $("#obsPicDlg").css("left", x + "px");
    }, 500);
  }

  /**
   * 画面非表示処理
   * @param {Object} evnt イベントオブジェクト 
   * ----------------------------------------------------------------------------
   */
  hide(evnt) {
    $("#obsPicDlgBk").css("display", "none");
  }

  /**
   * 画面再描画処理
   * ----------------------------------------------------------------------------
   */
  redraw() {
    if ($("#obsPicDlgBk").css("display") == "block") {
      var sw = $(window).width();
      var sh = $(window).height();
      var dw = $("#obsPicDlg").width();
      var dh = $("#obsPicDlg").height();
      var x = sw / 2 - (dw / 2);
      var y = sh / 2 - (dh / 2);
      $("#obsPicDlg").css("top", y + "px");
      $("#obsPicDlg").css("left", x + "px");
    }
  }
}