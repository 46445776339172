export default class {

  constructor() {
    this._visible = false;
    this._elm = $("<img src=\"./img/legendbox.png\">").appendTo("body");
    this._elm.css("position", "absolute");
    this._elm.css("left", "4px");
    this._elm.css("bottom", "4px");
    this._elm.css("width", "276px");
    this._elm.css("height", "260px");
    this._elm.css("z-index", "3000");
    this._elm.css("display", "none");
  }

  show() {
    this._elm.css("display", "block");
  }

  hide() {
    this._elm.css("display", "none");
  }
}