import SideMenuDlg from "./sidemenudlg.js";
import PageLog from "./pagelog";

var _sideMenuDlg = null;

export default class {
  constructor(config, rewriteUrl, params) {
    this.obsLayerGroup = null;
    this.config = config;

    //背景作成
    var bk = $("<div id=\"sideMenuBk\"></div>", {
      css: {
        "z-index": 2000
      }
    }).appendTo("body");
    bk.setExtVal("instance", this);

    bk.load("./sidemenu.html", function (response, status, xhr) {
      var inst = $("#sideMenuBk").getExtVal("instance");

      // 観測値リストオブジェクトの生成
      _sideMenuDlg = new SideMenuDlg(inst.config);
      // informationUrlの判定
      var infoUrl = inst.config.sidemenuSettings.informationUrl;
      if (infoUrl != null && infoUrl != "") {
        //ボタンの有効無効としてHTMLがロードできるかどうかを確認する。
        //htmlをロードする
        $.ajax({
          url: infoUrl
        }).then((htmlStr, textResponse, jqXHR) => {
          $("#manual").css("display", "block");
          $("#attention").css("display", "block");
          _sideMenuDlg.attentionHtml = htmlStr;
        }).catch((e) => {
          $("#manual").css("display", "block");
          $("#attentionBtn").css("display", "none");
        });
      } else {
        $("#manual").css("display", "block");
        $("#attentionBtn").css("display", "none");
      }

      // 設定内容と本クラスのインスタンスをサイドメニューのエレメントに登録
      $("#sideMenu").setExtVal("instance", inst);
      $("#sideMenu").setExtVal("config", inst.config);

      $("#obsCheckBox").attr("checked", true);
      $("#cctvCheckBox").attr("checked", true);
      $("#riverCheckBox").attr("checked", true);
      $("#tmObsCheckBox").attr("checked", true);
      $("#tmCctvCheckBox").attr("checked", true);
      $("#sideMenuClose").on("click", function (evnt) {
        var zoom = inst.mapWin.getZoom();
        rewriteUrl(zoom);
        $("#sideMenuBk").css("display", "none");
      });
      // マニュアルボタン
      $("#manualBtn").on("click", {
        inst: inst
      }, inst.clickManual);
      // 利用注意ボタン
      $("#attentionBtn").on("click", {
        inst: inst
      }, inst.clickAttention);
      // 地図変更ボタン
      $("#mapSelectBtn").on("click", {
        inst: inst
      }, inst.clickChangeMap);

      // リンクボタンの作成
      inst.dispLinkBtn(inst.config);

      //タイル違い
      //if (params.t != null) {
      //  this.mapWin.changeTile(+params.t);
      //}

      if (params.dobs != null) {
        if (params.dobs == 1) {
          $("#obsCheckBox").attr("checked", true);
        } else {
          $("#obsCheckBox").attr("checked", false);
        }
      }
      if (params.dtv != null) {
        if (params.dtv == 1) {
          $("#cctvCheckBox").attr("checked", true);
        } else {
          $("#cctvCheckBox").attr("checked", false);
        }
      }
      if (params.drvr != null) {
        if (params.drvr == 1) {
          $("#riverCheckBox").attr("checked", true);
        } else {
          $("#riverCheckBox").attr("checked", false);
        }
      }
      if (params.dtmobs != null) {
        if (params.dtmobs == 1) {
          $("#tmObsCheckBox").attr("checked", true);
        } else {
          $("#tmObsCheckBox").attr("checked", false);
        }
      }
      if (params.dtmtv != null) {
        if (params.dtmtv == 1) {
          $("#tmCctvCheckBox").attr("checked", true);
        } else {
          $("#tmCctvCheckBox").attr("checked", false);
        }
      }

    });
  }

  /**
   * サイドメニュー処理
   * @param {object} obsLayerGroup 危機管理型水位計レイヤグループ
   * @param {object} cctvLayerGroup 危機管理型CCTVレイヤグループ
   * @param {object} riverLayerGroup 河川ラインレイヤグループ
   * @param {object} overTmObsLayerGroup 超過テレメータ水位観測所レイヤグループ
   * @param {object} tmObsLayerGroup テレメータ水位観測所レイヤグループ
   * @param {object} tmCctvLayerGroup テレメータCCTV観測所レイヤグループ
   * @param {object} mapWin mapwinオブジェクト
   * @param {number} cnstIndex 基準インデックス
   */
  show(obsLayerGroup, cctvLayerGroup, riverLayerGroup, overTmObsLayerGroup, tmObsLayerGroup, mapWin, cnstIndex, cctvCallback) {
    this.mapWin = mapWin;

    //　チェックボック状態の変更
    if (this.mapWin.dispOBS == 1) {
      $("#obsCheckBox").attr("checked", true);
    } else {
      $("#obsCheckBox").attr("checked", false);
    }

    if (this.mapWin.dispCCTV == 1) {
      $("#cctvCheckBox").attr("checked", true);
    } else {
      $("#cctvCheckBox").attr("checked", false);
    }

    if (this.mapWin.dispRVR == 1) {
      $("#riverCheckBox").attr("checked", true);
    } else {
      $("#riverCheckBox").attr("checked", false);
    }

    if (this.mapWin.dispTMOBS == 1) {
      $("#tmObsCheckBox").attr("checked", true);
    } else {
      $("#tmObsCheckBox").attr("checked", false);
    }

    if (this.mapWin.dispTMCCTV == 1) {
      $("#tmCctvCheckBox").attr("checked", true);
    } else {
      $("#tmCctvCheckBox").attr("checked", false);
    }

    $("#sideMenuBk").css("display", "block");
    $("#sideMenu").setExtVal("mapWin", this.mapWin);

    // 地図の種類
    var count = 0;

    // 地図切替のセレクトボックス作成
    // タイル番号を設定
    if ($("option")[0] == undefined) {
      this.config.mapTileUrls.forEach(tile => {
        var selectItem = $("<option value=\"" + count + "\">" + tile.name + "</option>");
        $(selectItem).appendTo("select#mapSelect");
        if (count == mapWin.tileIndex) {
          $(selectItem).attr("selected", "true");
        }
        count++;
      });
    }

    // 水位計の表示判定
    $("#obsCheckBox").on("click", function (evnt) {
      if ($("#obsCheckBox").prop("checked")) {
        obsLayerGroup.setZIndex(cnstIndex + 70);
        mapWin.map.addLayer(obsLayerGroup);
        mapWin.dispOBS = 1;
      } else {
        mapWin.map.removeLayer(obsLayerGroup);
        mapWin.dispOBS = 0;
      }
    });

    // 河川カメラの表示判定
    $("#cctvCheckBox").on("click", function (evnt) {
      if ($("#cctvCheckBox").prop("checked")) {
        mapWin.dispCCTV = 1;
      } else {
        mapWin.dispCCTV = 0;
      }
      cctvCallback();
    });

    // 河川ラインの表示判定
    $("#riverCheckBox").on("click", function (evnt) {
      if ($("#riverCheckBox").prop("checked")) {
        riverLayerGroup.setZIndex(cnstIndex + 30);
        mapWin.map.addLayer(riverLayerGroup);
        mapWin.showRiverTile();
        mapWin.dispRVR = 1;
      } else {
        mapWin.map.removeLayer(riverLayerGroup);
        mapWin.hideRiverTile();
        mapWin.dispRVR = 0;
      }
    });

    // テレメータ水位観測所の表示判定
    $("#tmObsCheckBox").on("click", function (evnt) {
      if ($("#tmObsCheckBox").prop("checked")) {
        overTmObsLayerGroup.setZIndex(cnstIndex + 80);
        mapWin.map.addLayer(overTmObsLayerGroup);
        tmObsLayerGroup.setZIndex(cnstIndex + 60);
        mapWin.map.addLayer(tmObsLayerGroup);

        mapWin.dispTMOBS = 1;
      } else {
        mapWin.map.removeLayer(overTmObsLayerGroup);
        mapWin.map.removeLayer(tmObsLayerGroup);
        mapWin.dispTMOBS = 0;
      }
    });

    // テレメータCCTVの表示判定
    $("#tmCctvCheckBox").on("click", function (evnt) {
      if ($("#tmCctvCheckBox").prop("checked")) {
        mapWin.dispTMCCTV = 1;
      } else {
        mapWin.dispTMCCTV = 0;
      }
      cctvCallback();
    });
  }

  /**
   * 再描画処理
   * @param {Object} event 
   */
  redraw(event) {
    if ($("#sideMenuDlgBk").css("display") == "block") {
      setTimeout(function (inst) {
        inst.show();
      }, 200, _sideMenuDlg);
    }
  }

  /**
   * マニュアル表示
   * @param {String} manualUrl 
   */
  clickManual(event) {
    _sideMenuDlg.show("manual");
  }

  /**
   * 利用注意表示
   * @param {String} event
   */
  clickAttention(event) {
    _sideMenuDlg.show("attention");
    PageLog.log(event.data.inst.config.pageLogUrl.useNotesUrl);
  }

  /**
   * 地図切替
   */
  clickChangeMap(event) {
    var mapSelectVal = $("#mapSelect").val();
    var mapWin = $("#sideMenu").getExtVal("mapWin");
    mapWin.changeTile(mapSelectVal);
    PageLog.log(event.data.inst.config.pageLogUrl.mapChngUrl, mapSelectVal);
  }

  // リンクボタンの作成
  dispLinkBtn(config) {
    var count = 1;
    config.sidemenuSettings.links.forEach(link => {
      var btnContent = $("<div class=\"btnContent\" id=\"link_" + count + "\"></div>");
      $(btnContent).appendTo("#linkSideMenu");
      var btnLink = $("<a href=\"" + link.url + "\" target=\"_blank\"></a>");
      $(btnLink).appendTo("#link_" + count);
      var btnTitle = $("<span class=\"sideMenuBtnTitle\">" + link.title + "</span>");
      $(btnTitle).appendTo("#link_" + count);
      var btnImg = $("<img class=\"sideMenuBtnMark\" src=\"img/fold.png\" />");
      $(btnImg).appendTo("#link_" + count);

      count++;
    });
  }
}