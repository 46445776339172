

export default class {
  static log(url, code) {
    //console.log("pagelog:", url, code);
    var uri = url;
    if (code != null) {
      uri = uri + "?code=" + code;
    }
    $.ajax({
      type: "GET",
      url: uri,
      data: null,
      dataType: "json",
      cache: true
    })
      .done(function (response) {
      })
      .fail(function (err) {
      })
      .always(function (p) {
      });
  }
}