import { DateFormatUtil } from "./util.js";
import PageLog from "./pagelog";

var _timerId = 0;

let __ListDlgCallbackFunc = null;
/**
 * 観測所リストダイアログ部品
 * ------------------------------------------------------------------------------
 * @LIS 20180420 新規作成
 * ==============================================================================
 */
export default class {
  /**
   * コンストラクタ
   * ----------------------------------------------------------------------------
   */
  constructor(config) {
    this.areaList = null;
    this.riverList = null;
    this.tabOrder = 0;
    this.requester = null;
    this.config = config;
    this.toolTip = $("#toolTip");

    $('<div id="listDlgBk"></div>', {
      css: {
        "z-index": 2000,
      },
    })
      .appendTo("body")
      .load("./listdlg.html");
  }

  /**
   * 画面表示処理
   * @param {Object} prm パラメータオブジェクト
   * ----------------------------------------------------------------------------
   */
  show(prm) {
    if (this.areaList != prm.areas || this.riverList != prm.rivers) {
      this.areaList = prm.areas;
      this.riverList = prm.rivers;
      this.tabOrder = 0;
      this.requester = prm.requester;
      __ListDlgCallbackFunc = prm.selectObs;
      this.clearRsysList();
      this.clearRankingList();
      this.createRsysList();
      this.createRankingList();
    }
    $("#listDlgBk").css("display", "block");
    $("#listDlg").setExtVal("instance", this);

    //幅中心に持っていく
    var scrWidth = $(window).width();
    var dlgWidth = $("#listDlg").width();
    var xpos = (scrWidth - dlgWidth) / 2;
    $("#listDlg").css("left", xpos + "px");

    //高さ中心に持っていく
    var scrHeight = $(window).height();
    var dlgHeight = $("#listDlg").height();
    var ypos = (scrHeight - dlgHeight) / 2;
    $("#listDlg").css("top", ypos + "px");

    //表示
    if (this.tabOrder == 0) {
      this.openRsysList();
    } else {
      this.openRankingList();
    }

    //更新ボタンの処理を割り当て
    $("#listDlgRenew").off("click");
    $("#listDlgRenew").on("click", this.clickRenew);

    //closeボタンに消す処理を割り当て
    $("#listDlgClose").off("click");
    $("#listDlgClose").on("click", function (evnt) {
      $("#listDlgBk").css("display", "none");
    });

    //タブクリックイベント割り当て
    $("#listDlgRsysTag").off("click");
    $("#listDlgRsysTag").on("click", this.openRsysList);
    $("#listDlgRankTag").off("click");
    $("#listDlgRankTag").on("click", this.openRankingList);
  }

  /**
   * ダイアログ閉じる
   */
  close() {
    $("#listDlgBk").css("display", "none");
  }

  /**
   * 水系リスト表示(タブ押下時)
   * ----------------------------------------------------------------------------
   */
  openRsysList() {
    var dlgInst = $("#listDlg").getExtVal("instance");
    dlgInst.tabOrder = 0;
    if ($("#listDlgRsysTag").hasClass("dact")) {
      $("#listDlgRsysTag").removeClass("dact");
      $("#listDlgRsysTag").addClass("act");
      $("#listDlgRankTag").removeClass("act");
      $("#listDlgRankTag").addClass("dact");
      $("#rsysList").css("display", "block");
      $("#rankingList").css("display", "none");
    }

    PageLog.log(dlgInst.config.pageLogUrl.listUrl);
  }

  /**
   * ランキングリスト表示(タブ押下時)
   * ----------------------------------------------------------------------------
   */
  openRankingList() {
    var dlgInst = $("#listDlg").getExtVal("instance");
    dlgInst.tabOrder = 1;
    if ($("#listDlgRankTag").hasClass("dact")) {
      $("#listDlgRankTag").removeClass("dact");
      $("#listDlgRankTag").addClass("act");
      $("#listDlgRsysTag").removeClass("act");
      $("#listDlgRsysTag").addClass("dact");
      $("#rsysList").css("display", "none");
      $("#rankingList").css("display", "block");
    }

    PageLog.log(dlgInst.config.pageLogUrl.rnkUrl);
  }

  /**
   * 水系リストクリア
   * ----------------------------------------------------------------------------
   */
  clearRsysList() {
    $("#rsysList").html("");
  }

  /**
   * ランキングリストクリア
   * ----------------------------------------------------------------------------
   */
  clearRankingList() {
    $("#rankingList").html("");
  }

  /**
   * 水系リスト作成処理
   * ----------------------------------------------------------------------------
   */
  createRsysList() {
    var rsyss = [];
    this.riverList.rivers.forEach((river) => {
      if (rsyss[river.rsys.code] == null) {
        rsyss[river.rsys.code] = {
          name: river.rsys.name,
          list: [],
        };
      }
      rsyss[river.rsys.code].list.push(river);
    });

    //水系の項目を作成
    for (var rsyscd in rsyss) {
      var line = $('<div class="item"></div>');
      var img = $('<img src="img/fold.png" />').appendTo(line);
      line.append('<div class="name">' + rsyss[rsyscd].name + "水系</div>");
      line.appendTo($("#rsysList"));

      //水系に連なる河川のリストを作成
      var rvrList = $('<div class="rvrList" style="display:none;"></div>');

      var rivers = rsyss[rsyscd].list;
      //河川の項目を作成
      for (var rvrid in rivers) {
        var rvrItem = $('<div class="rvrItem"></div>');
        var rvrImg = $('<img src="img/fold.png" />').appendTo(rvrItem);
        $('<div class="name">' + rivers[rvrid].name + "</div>").appendTo(
          rvrItem
        );
        var obsList = $(
          '<div class="obsList" id="obsList_' + rivers[rvrid].code + '"></div>'
        );
        rvrItem.on(
          "click",
          {
            code: rivers[rvrid].code,
            imgElm: rvrImg,
            req: this.requester,
            openListFunc: this.openObsList,
          },
          this.clickRiver
        );
        rvrList.append(rvrItem);
        rvrList.append(obsList);
      }
      rvrList.appendTo($("#rsysList"));

      line.on(
        "click",
        {
          rsyscd: rsyscd,
          imgElm: img,
          rvrList: rvrList,
        },
        function (event) {
          if (event.data.imgElm.attr("src") == "img/fold.png") {
            event.data.imgElm.attr("src", "img/unfold.png");
            event.data.rvrList.css("display", "block");
          } else {
            event.data.imgElm.attr("src", "img/fold.png");
            event.data.rvrList.css("display", "none");
          }
        }
      );
    }
  }

  /**
   * 河川項目クリック処理
   * @param {Object} event イベントオブジェクト
   * ----------------------------------------------------------------------------
   */
  clickRiver(event) {
    var listElm = $("#obsList_" + event.data.code);
    if (event.data.imgElm.attr("src") == "img/fold.png") {
      event.data.imgElm.attr("src", "img/unfold.png");
      //リストが空っぽの時は生成する。
      if (listElm.html().length == 0) {
        var sbmtr = event.data.req.create();
        sbmtr.register(
          "getRiverObs",
          {
            river: event.data.code,
          },
          function (result) {
            result.obss.forEach((obs) => {
              var obsItem = $('<div class="obsItem"></div>');
              var obsVals = $('<div class="obsVal"></div>');
              obsItem.setExtVal("obsData", obs);

              // 観測所名の表示
              var kana = obs.kana
              if ( obs.kana === null){
                kana = ""
              }
              $('<ruby><div class="name">' + obs.name + 
              '</div><rp>(</rp><rt class="kana">' + kana + 
              "</rt><rp>)</rp></ruby>").appendTo(obsItem);

              // 観測所日時の表示
              var timeData = $('<div class="timeData"></div>');
              $(timeData).appendTo(obsVals);
              var tmpDate = new Date(obs.obsTime);
              if (isNaN(tmpDate)) {
                tmpDate = new Date(
                  obs.obsTime.replace(/-/g, "/").replace(/T/g, " ")
                );
              }
              var obsTime = DateFormatUtil.format(tmpDate, "YYYY/MM/DD hh:mm");
              $('<div class="tm">' + obsTime + "</div>").appendTo(timeData);
              // 品管フラグの表示領域の設定
              var qtDiv = $('<div class="qtDiv"></div>');
              $(qtDiv).appendTo(timeData);
              // 品管フラグの表示判定
              var qtElm = null;
              if (obs.qt != null && obs.qt != 0) {
                var dlgInst = $("#listDlg").getExtVal("instance");
                qtElm = $('<img class="qt" src="img/qt.png" />').appendTo(
                  qtDiv
                );
              }

              // 観測水位の欠測判定
              if (obs.level != null) {
                var level = (obs.level - obs.fladLevel) * 100;
                level = Math.round(level) / 100;
                level = level.toFixed(2);

                $('<div class="cap">堤防までの高さ</div>').appendTo(obsVals);
                $('<div class="val">' + level + "m</div>").appendTo(obsVals);

                obsItem.append(obsVals);
                obsItem.appendTo(listElm);
                if (obs.level >= obs.fladLevel) {
                  obsItem.addClass("flad");
                  obsItem.removeClass("warn");
                  obsItem.removeClass("start");
                  obsItem.removeClass("normal");
                  obsItem.removeClass("missing");
                } else if (
                  obs.warnLevel != null &&
                  obs.level >= obs.warnLevel
                ) {
                  obsItem.removeClass("flad");
                  obsItem.addClass("warn");
                  obsItem.removeClass("start");
                  obsItem.removeClass("normal");
                  obsItem.removeClass("missing");
                } else if (obs.level >= obs.startLevel) {
                  obsItem.removeClass("flad");
                  obsItem.removeClass("warn");
                  obsItem.addClass("start");
                  obsItem.removeClass("normal");
                  obsItem.removeClass("missing");
                } else {
                  obsItem.removeClass("flad");
                  obsItem.removeClass("warn");
                  obsItem.removeClass("start");
                  obsItem.addClass("normal");
                  obsItem.removeClass("missing");
                }
              } else {
                $('<div class="cap"></div>').appendTo(obsVals);
                $('<div class="val">欠測</div>').appendTo(obsVals);
                obsItem.append(obsVals);
                obsItem.appendTo(listElm);

                obsItem.removeClass("flad");
                obsItem.removeClass("warn");
                obsItem.removeClass("start");
                obsItem.removeClass("normal");
                obsItem.addClass("missing");
              }

              obsItem.on(
                "click",
                {
                  obs: obs,
                },
                function (event) {
                  __ListDlgCallbackFunc(event.data.obs);
                }
              );
              if (qtElm != null) {
                qtElm.on(
                  "click",
                  {
                    dlg: dlgInst,
                  },
                  function (event) {
                    var ww = window.innerWidth;
                    var wh = window.innerHeight;
                    var x = event.pageX;
                    var y = event.pageY;
                    var dw = event.data.dlg.toolTip.outerWidth();
                    var dh = event.data.dlg.toolTip.outerHeight();

                    if (ww < x + dw) {
                      x = x - (x + dw - ww);
                    }
                    if (wh < y + dh) {
                      y = y - (y + dh - wh);
                    }

                    event.data.dlg.toolTip.css("display", "block");
                    event.data.dlg.toolTip.css("left", x + "px");
                    event.data.dlg.toolTip.css("top", y + "px");

                    if (_timerId != 0) {
                      clearTimeout(_timerId);
                    }
                    _timerId = setTimeout(
                      (toolTip) => {
                        toolTip.css("display", "none");
                        console.log("timer close");
                        _timerId = 0;
                      },
                      3000,
                      event.data.dlg.toolTip
                    );
                    event.stopPropagation();
                  }
                );
              }
            });
          }
        );
        sbmtr.submit().then(() => {
          listElm.css("display", "block");
        });
      } else {
        listElm.css("display", "block");
      }
    } else {
      event.data.imgElm.attr("src", "img/fold.png");
      listElm.css("display", "none");
    }
  }

  /**
   * ランキングリスト作成処理
   * ----------------------------------------------------------------------------
   */
  createRankingList() {
    //ランキングリストのエレメントを取得
    var listElm = $("#rankingList");
    //ランキング別の観測所を取得する。
    var sbmtr = this.requester.create();
    sbmtr.register("getRanking", this.areaList, function (result) {
      //取得したリストからHTMLのタグを生成して追加していく
      result.obss.forEach((obs) => {
        var obsItem = $('<div class="obsItem"></div>');
        var obsVals = $('<div class="obsVal"></div>');
        obsItem.setExtVal("obsData", obs);

        var level = (obs.level - obs.fladLevel) * 100;
        level = Math.round(level) / 100;
        level = level.toFixed(2);
        var tmpDate = new Date(obs.obsTime);
        if (isNaN(tmpDate)) {
          tmpDate = new Date(obs.obsTime.replace(/-/g, "/").replace(/T/g, " "));
        }
        var obsTime = DateFormatUtil.format(tmpDate, "YYYY/MM/DD hh:mm");

        var kana = obs.kana
        if ( obs.kana === null){
          kana = ""
        }
        $('<ruby><div class="name">' + obs.name + 
        '</div><rp>(</rp><rt class="kana">' + kana + 
        "</rt><rp>)</rp></ruby>").appendTo(obsItem);

        var timeData = $('<div class="timeData"></div>');
        $(timeData).appendTo(obsVals);
        $('<div class="tm">' + obsTime + "</div>").appendTo(timeData);
        var qtDiv = $('<div class="qtDiv"></div>');
        $(qtDiv).appendTo(timeData);
        // 品管フラグの表示判定
        var qtElm = null;
        if (obs.qt != null && obs.qt != 0) {
          var dlgInst = $("#listDlg").getExtVal("instance");
          qtElm = $('<img class="qt" src="img/qt.png" />').appendTo(qtDiv);
        }
        $('<div class="cap">堤防までの高さ</div>').appendTo(obsVals);
        $('<div class="val">' + level + "m</div>").appendTo(obsVals);

        obsItem.append(obsVals);
        obsItem.appendTo(listElm);
        if (obs.level >= obs.fladLevel) {
          obsItem.addClass("flad");
          obsItem.removeClass("warn");
          obsItem.removeClass("start");
          obsItem.removeClass("normal");
        } else if (obs.warnLevel != null && obs.level >= obs.warnLevel) {
          obsItem.removeClass("flad");
          obsItem.addClass("warn");
          obsItem.removeClass("start");
          obsItem.removeClass("normal");
        } else if (obs.level >= obs.startLevel) {
          obsItem.removeClass("flad");
          obsItem.removeClass("warn");
          obsItem.addClass("start");
          obsItem.removeClass("normal");
        } else {
          obsItem.removeClass("flad");
          obsItem.removeClass("warn");
          obsItem.removeClass("start");
          obsItem.addClass("normal");
        }

        obsItem.on(
          "click",
          {
            obs: obs,
          },
          function (event) {
            __ListDlgCallbackFunc(event.data.obs);
          }
        );
        if (qtElm != null) {
          qtElm.off();
          qtElm.on(
            "click",
            {
              dlg: dlgInst,
            },
            function (event) {
              var ww = window.innerWidth;
              var wh = window.innerHeight;
              var x = event.pageX;
              var y = event.pageY;
              var dw = event.data.dlg.toolTip.outerWidth();
              var dh = event.data.dlg.toolTip.outerHeight();

              if (ww < x + dw) {
                x = x - (x + dw - ww);
              }
              if (wh < y + dh) {
                y = y - (y + dh - wh);
              }

              event.data.dlg.toolTip.css("display", "block");
              event.data.dlg.toolTip.css("left", x + "px");
              event.data.dlg.toolTip.css("top", y + "px");

              if (_timerId != 0) {
                clearTimeout(_timerId);
              }
              _timerId = setTimeout(
                (toolTip) => {
                  toolTip.css("display", "none");
                  console.log("timer close");
                  _timerId = 0;
                },
                3000,
                event.data.dlg.toolTip
              );
              event.stopPropagation();
            }
          );
        }
      });
    });
    //リクエスト送信
    sbmtr.submit();
  }
  clickQtToolTip(event) {
    clearTimeout(_timerId);
    event.data.dlg.toolTip.css("display", "none");
  }

  /**
   * 更新ボタンの処理
   * @param {Object} event
   */
  clickRenew(event) {
    var dlgInst = $("#listDlg").getExtVal("instance");
    dlgInst.clearRsysList();
    dlgInst.clearRankingList();
    dlgInst.createRsysList();
    dlgInst.createRankingList();

    //ページログ処理
    if (dlgInst.tabOrder == 0) {
      PageLog.log(dlgInst.config.pageLogUrl.listUrl);
    } else {
      PageLog.log(dlgInst.config.pageLogUrl.rnkUrl);
    }
  }

  /**
   * 再描画処理
   * @param {Object} event
   */
  redraw(event) {
    var dlgInst = $("#listDlg").getExtVal("instance");

    //幅中心に持っていく
    var scrWidth = $(window).width();
    var dlgWidth = $("#listDlg").width();
    var xpos = (scrWidth - dlgWidth) / 2;
    $("#listDlg").css("left", xpos + "px");

    //高さ中心に持っていく
    var scrHeight = $(window).height();
    var dlgHeight = $("#listDlg").height();
    var ypos = (scrHeight - dlgHeight) / 2;
    $("#listDlg").css("top", ypos + "px");

    //表示
    if (dlgInst.tabOrder == 0) {
      dlgInst.openRsysList();
    } else {
      dlgInst.openRankingList();
    }
  }

  /**
   * ダイアログが開いているかどうか確認する
   * @return {boolean} true(開いている)、false(閉じている)
   * ----------------------------------------------------------------------------
   */
  isOpen() {
    if ($("#listDlgBk").css("display") == "block") {
      return true;
    }
    return false;
  }
}
