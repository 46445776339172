/**
 * URLパラメータ抽出ユーティリティ
 * ------------------------------------------------------------------------------
 * @LIS 20180406 新規作成
 * ==============================================================================
 */
export default class {
  /**
   * パラメータ抽出処理
   * @param {string} myUrl 現在のURL
   * @return {Object} パラメータオブジェクト 
   */
  static parse(myUrl) {

    //"?"で分解
    var urls = myUrl.split("?");
    //?の後ろだけ使用
    var paramsStr = urls[1];
    if (paramsStr == null){
      return {};
    }
    //"&"で分解
    var params = paramsStr.split("&");

    //返却用のオブジェクト生成
    var result = {};
    params.forEach(str => {
      //"="で分解して、左がKEY、右がValueで返却オブジェクトに登録
      var elm = str.split("=");
      result[elm[0]] = elm[1];
    });

    return result;
  }
}