/**
 * Twitter通信エラーダイアログ部品
 * ------------------------------------------------------------------------------
 * @LIS 2019 新規作成
 * ==============================================================================
 */
export default class {
  constructor() {
    // Twitter通信エラーダイアログの作成
    this.twitterErrDlgBk = $("<div id=\"twitterErrDlgBk\"></div>").appendTo("body");
    this.twitterErrDlg = $("<div id=\"twitterErrDlg\"></div>").appendTo(this.twitterErrDlgBk);
    this.twitterErrHead = $("<div id=\"twitterErrHead\"></div>").appendTo(this.twitterErrDlg);
    this.twitterErrClose = $("<img src=\"img/close.png\">").appendTo(this.twitterErrHead);
    this.twitterErrMeg = $("<div id=\"twitterErrMsg\"></div>").appendTo(this.twitterErrDlg);
    
    // クローズボタンの実装
    this.twitterErrClose.on("click", {win: this.twitterErrDlgBk}, this.close);
  }
  /**
   * Twitter通信エラーダイアログの表示
   * ------------------------------------------------------------------------------
   * @param {String} msg 表示するメッセージ 
   */
  show(msg) {
    $("#twitterErrDlgBk").css("display", "flex");
    $("#twitterErrMsg").text(msg);
  }

  /**
   * Twitter通信エラーダイアログの非表示
   * ------------------------------------------------------------------------------
   */
  close() {
    $("#twitterErrDlgBk").css("display", "none");
  }
}