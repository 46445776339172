/**
 * 観測値データリストダイアログ部品
 * ------------------------------------------------------------------------------
 * @LIS 20180521 新規作成
 * ==============================================================================
 */
var _timerId = 0;

export default class {
  /**
   * コンストラクタ
   */
  constructor() {
    this.levelParam = null;
    this.dlgInst = null;
    this.riverInfo = null;
    this.points = null;
    this.tabOrder = 0;
    this.toolTip = $("#toolTip");

    $('<div id="levelListDlgBk"></div>', {
      css: {
        "z-index": 3000,
      },
    })
      .appendTo("body")
      .load("./levellistdlg.html");
  }

  /**
   * 観測値一覧ダイアログ表示処理
   * @param {object} prm 観測値一覧パラメータオブジェクト
   * @param {object} dlgInst obsDlgインスタンス
   * @param {object} riverInfo 横断図描画データ
   * @param {method} renewFunk 観測所ダイアログ更新メソッド
   */
  show(prm, dlgInst, riverInfo, renewFunk) {
    $("#levelListDlgBk").css("display", "block");
    if (this.levelParam != prm || prm == null) {
      this.levelParam = prm;
      this.dlgInst = dlgInst;
      this.riverInfo = riverInfo;
      this.createObsTitle();
      this.clearLevelList();
      this.createSectionView(riverInfo);
      this.createObsValueList();
    }

    //幅中心に持っていく
    var scrWidth = $(window).width();
    var dlgWidth = $("#levelListDlg").width();
    var xpos = (scrWidth - dlgWidth) / 2;
    $("#levelListDlg").css("left", xpos + "px");

    //高さ中心に持っていく
    var scrHeight = $(window).height();
    var dlgHeight = $("#levelListDlg").height();
    var ypos = (scrHeight - dlgHeight) / 2;
    $("#levelListDlg").css("top", ypos + "px");

    //closeボタンに消す処理を割り当て
    $("#levelListDlgClose").off("click");
    $("#levelListDlgClose").on(
      "click",
      {
        dlg: this.dlgInst,
      },
      function (evt) {
        $("#levelListDlgBk").css("display", "none");
        evt.data.dlg.tabOrder = 1;
        evt.data.dlg.dispContent();
        $("#crossContents").html("");
      }
    );
    //更新ボタン
    $("#levelListDlgRenew").off("click");
    $("#levelListDlgRenew").on(
      "click",
      {
        dlg: this.dlgInst,
      },
      function (evt) {
        renewFunk();
      }
    );
  }

  /**
   * リストの削除
   */
  clearLevelList() {
    $("#levelList").html("");
  }

  /**
   * 観測所名の作成
   */
  createObsTitle() {
    if (this.dlgInst.obs.name) {
      $("div#obsKanaTitle").html(this.dlgInst.obs.kana);
      $("div#obsNameTitle").html(this.dlgInst.obs.name);
    } else if (this.dlgInst.obs.obsName) {
      $("div#obsKanaTitle").html(this.dlgInst.obs.obsKana);
      $("div#obsNameTitle").html(this.dlgInst.obs.obsName);
    } else {
      $("div#obsKanaTitle").html("");
      $("div#obsNameTitle").html("");
    }
  }

  /**
   * 横断図の表示
   * @param {Object} riverInfo
   */
  createSectionView(riverInfo) {
    if (riverInfo != null) {
      riverInfo.divWidth = $("#crossContents").width();
      riverInfo.divHeight = $("#crossContents").height();
    }

    if (
      this.dlgInst.obs.dispSurfFlag == 1 ||
      $("#obsDlg").getExtVal("danmenPointD") != null
    ) {
      $("div.cross-frame").css("display", "block");

      if (!isEmpty(this.dlgInst.spSurface) && riverInfo.points.length <= 0) {
        $("div.cross-frame").css("display", "none");
        return;
      }

      if (isEmpty(this.dlgInst.spSurface)) {
        $("div.cross-frame").css("overflow-x", "scroll");
        var points = getEndPoint(riverInfo);
        // 横断図描画
        createCossSectionData(points, riverInfo);
      } else {
        // 特殊横断の場合
        $("div.cross-frame").css("overflow-x", "hidden");
        riverInfo.spSurface = this.dlgInst.spSurface;
        var points = getSpEndPoint(riverInfo);
        createSpCossSectionData(points, riverInfo);
      }
    } else {
      $("div.cross-frame").css("display", "none");
    }
  }

  /**
   * 観測値リストの作成
   */
  createObsValueList() {
    /** 表示日付 */
    var dispDay = null;
    // 基準水位
    var levels = this.levelParam.level;
    // 堤防高
    var bank = this.levelParam.bank;

    var len = this.levelParam.obsValue.length;
    for (var ix = len - 1; ix >= 0; ix--) {
      var value = this.levelParam.obsValue[ix];

      var levelItem = $(
        '<div class="levelItem" id="levelItem_' + ix + '"></div>'
      );
      $(levelItem).appendTo("div#levelList");
      // 横断図表示がある場合にクリック処理を実装
      if ($("div.cross-frame").css("display") != "none") {
        $(levelItem).on(
          "click",
          {
            count: ix,
          },
          this.clickLevel
        );
      }

      // 観測年月日の表示
      var fullDate = value.date;
      var fullDateParts = fullDate.split(" ");
      //console.log("DEBUG:", fullDateParts[0]);
      var tmpDate = new Date(fullDateParts[0]);
      if (isNaN(tmpDate)) {
        tmpDate = new Date(
          fullDateParts[0].replace(/-/g, "/").replace(/T/g, " ")
        );
      }
      if (ix == len - 1 || dispDay > tmpDate) {
        dispDay = tmpDate;
        var date = fullDateParts[0];
        $('<div class="date">' + date + "</div>").appendTo(levelItem);
      } else {
        $('<div class="date"></div>').appendTo(levelItem);
      }

      var time = fullDateParts[1];
      $('<div class="time">' + time + "</div>").appendTo(levelItem);
      // 観測値の表示形式を変更 (標高値 → 堤防までの高さ)
      var level = null;
      if (value.elevation != null) {
        level = (value.elevation - bank) * 100;
        level = Math.round(level) / 100;
        level = level.toFixed(2);

        $('<div class="cap">堤防までの高さ</div>').appendTo(levelItem);
        $('<div class="val">' + level + "m</div>").appendTo(levelItem);

        // 水位判定
        if (level >= 0) {
          // 氾濫開始水位以上
          $("div#levelItem_" + ix).addClass("levelItem flad");
        } else if (level >= levels.warnLevel) {
          // 危険水位以上
          $("div#levelItem_" + ix).addClass("levelItem warn");
        } else if (level >= levels.startLevel) {
          // 観測開始水位以上
          $("div#levelItem_" + ix).addClass("levelItem start");
        } else {
          // 観測開始水位未満
          $("div#levelItem_" + ix).addClass("levelItem normal");
        }
      } else {
        //console.log("levellistdlg-value:" + JSON.stringify(value));
        $('<div class="cap"></div>').appendTo(levelItem);
        if (value.elevationWord)
          $(`<div class="val">${value.elevationWord}</div>`).appendTo(levelItem);
        else
          $('<div class="val">欠測</div>').appendTo(levelItem);
        $("div#levelItem_" + ix).addClass("levelItem missing");
      }

      // 品管フラグの表示
      var qtDiv = $('<div class="qtDiv"></div>');
      $(qtDiv).appendTo(levelItem);
      if (this.dlgInst.type === "swin") {
        if (value.qt != null && value.qt != 0) {
          $('<img class="qt" src="img/qt.png" />')
            .appendTo(qtDiv)
            .on(
              "click",
              {
                dlg: this,
              },
              function (event) {
                var ww = window.innerWidth;
                var wh = window.innerHeight;
                var x = event.pageX;
                var y = event.pageY;
                var dw = event.data.dlg.toolTip.outerWidth();
                var dh = event.data.dlg.toolTip.outerHeight();

                if (ww < x + dw) {
                  x = x - (x + dw - ww);
                }
                if (wh < y + dh) {
                  y = y - (y + dh - wh);
                }

                event.data.dlg.toolTip.css("display", "block");
                event.data.dlg.toolTip.css("left", x + "px");
                event.data.dlg.toolTip.css("top", y + "px");

                if (_timerId != 0) {
                  clearTimeout(_timerId);
                }
                _timerId = setTimeout(
                  (toolTip) => {
                    toolTip.css("display", "none");
                    console.log("timer close");
                    _timerId = 0;
                  },
                  3000,
                  event.data.dlg.toolTip
                );
              }
            );
        }
      }
    }
  }
  clickQtToolTip(event) {
    clearTimeout(_timerId);
    event.data.dlg.toolTip.css("display", "none");
  }

  /**
   * 観測値クリック時の処理
   * @param {Object} event イベント情報
   */
  clickLevel(event) {
    //DEL 特殊横断
    //fillOldRiver(event.data.count, "#crossContents");
    //ADD 特殊横断 >>
    var dlgInst = $("#obsDlg").getExtVal("instance");
    if (isEmpty(dlgInst.spSurface)) {
      fillOldRiver(event.data.count, "#crossContents");
    } else {
      fillOldRiverSp(event.data.count, "#crossContents");
    }
    //ADD 特殊横断 <<
  }

  /**
   * 観測値一覧ダイアログ更新
   * @param {Object} event
   */
  clickRenew(event) {
    $("#levelListDlgClose").on(
      "click",
      {
        dlg: this.dlgInst,
      },
      function (evt) {
        $("#levelListDlgBk").css("display", "none");
        evt.data.dlg.tabOrder = 1;
        evt.data.dlg.dispContent();
        $("#crossContents").html("");
      }
    );
  }
}
