import PositionFormatter from "./positionformatter.js";

// 位置情報ファイル取得用オブジェクト
const positionFormatter = new PositionFormatter();
// getConfig(ConfigApi)で取得する設定情報
var _config = null;


/**
 * Class FileResponseFormatter
 * File取得API用のレスポンス整形クラス
 * ------------------------------------------------------------------------------
 * @LIS 20200218 新規作成
 * ==============================================================================
 */
export class FileResponseFormatter {
/**
	 * コンストラクタ
	 * @constructor
	 * @return {Requester} Requesterクラスのインスタンス
	 * ----------------------------------------------------------------------------
	 */
	constructor() {
		this.formatterMap = new Map();

		const getConfigFormatter = new GetConfigFormatter();
		const getOptionalInfoFormatter = new GetOptionalInfoFormatter();
		const getPositionFormatter = new GetPositionFormatter();
		const getAreasInfoFormatter = new GetAreasInfoFormatter();
		const getRiversFormatter = new GetRiversFormatter();
		const getAreasLevelsFormatter = new GetAreasLevelsFormatter();
		const getLevelsFormatter = new GetLevelsFormatter();
		const getRankingFormatter = new GetRankingFormatter();
		const getRiverObsFormatter = new GetRiverObsFormatter();

		this.formatterMap.set(getConfigFormatter.apiId, getConfigFormatter);
		this.formatterMap.set(getOptionalInfoFormatter.apiId, getOptionalInfoFormatter);
		this.formatterMap.set(getPositionFormatter.apiId, getPositionFormatter);
		this.formatterMap.set(getAreasInfoFormatter.apiId, getAreasInfoFormatter);
		this.formatterMap.set(getRiversFormatter.apiId, getRiversFormatter);
		this.formatterMap.set(getAreasLevelsFormatter.apiId, getAreasLevelsFormatter);
		this.formatterMap.set(getLevelsFormatter.apiId, getLevelsFormatter);
		this.formatterMap.set(getRankingFormatter.apiId, getRankingFormatter);
		this.formatterMap.set(getRiverObsFormatter.apiId, getRiverObsFormatter);
	}

	format(req, resultJsons) {
		if (this.formatterMap.has(req.apiId)) {
			return this.formatterMap.get(req.apiId).format(req, resultJsons);
		}
		return;
	}

	/**
	 * コンフィグ設定引き渡し
	 * param {Object} config
	 * ----------------------------------------------------------------------------
	 */
	setConfig(config) {
		_config = config;
	}
}

export class IFormatter {
	constructor(_apiId) {
		this._apiId = _apiId;
	}

	get apiId () {
		return this._apiId;
	}
}

/**
 * Class GetConfigFormatter
 * 画面設定情報ファイル取得用のレスポンス整形クラス
 * ------------------------------------------------------------------------------
 * @LIS 20200225 新規作成
 * ==============================================================================
 */
export class GetConfigFormatter extends IFormatter {
	/**
	 * コンストラクタ
	 * @constructor
	 * @return {GetConfigFormatter} GetConfigFormatterクラスのインスタンス
	 * ----------------------------------------------------------------------------
	 */
	constructor() {
		super("getConfig");
	}

	format(req, resultJsons) {

		// API結果データとして整形する
		let dummyData = {
			apiId: req.apiId,
			params: req.params,
			tag: req.tag,
			macro: req.macro,
			result: resultJsons[0]
		};

		return dummyData;
	}
}

/**
 * Class GetOptionalInfoFormatter
 * 任意情報ファイル取得用のレスポンス整形クラス
 * ------------------------------------------------------------------------------
 * @LIS 20200225 新規作成
 * ==============================================================================
 */
export class GetOptionalInfoFormatter extends IFormatter {
	/**
	 * コンストラクタ
	 * @constructor
	 * @return {GetOptionalInfoFormatter} GetOptionalInfoFormatterクラスのインスタンス
	 * ----------------------------------------------------------------------------
	 */
	constructor() {
		super("getOptionalInfo");
	}

	format(req, resultJsons) {

		// API結果データとして整形する
		let dummyData = {
			apiId: req.apiId,
			params: req.params,
			tag: req.tag,
			macro: req.macro,
			result: resultJsons[0]
		};

		return dummyData;
	}
}

/**
 * Class GetPositionFormatter
 * 位置情報ファイル取得用のレスポンス整形クラス
 * ------------------------------------------------------------------------------
 * @LIS 20200306 新規作成
 * ==============================================================================
 */
export class GetPositionFormatter extends IFormatter {
	/**
	 * コンストラクタ
	 * @constructor
	 * @return {GetPositionFormatter} GetPositionFormatterクラスのインスタンス
	 * ----------------------------------------------------------------------------
	 */
	constructor() {
		super("getPosition");
	}

	async format(req, resultJsons) {

		// ポリゴン情報ファイルURL作成
		let url = "";
		if (req.params.level === "town") {
			url = _config.topUrl + _config.getPositionUrl.twnPolUrl;
		} else {
			url = _config.topUrl + _config.getPositionUrl.prefPolUrl;
		}

		// API結果データとして整形する
		const results = await positionFormatter.format(req, resultJsons, url);

		// API結果データとして整形する
		let dummyData = {
			apiId: req.apiId,
			params: req.params,
			tag: req.tag,
			macro: req.macro,
			result: results
		};

		return dummyData;
	}
}

/**
 * Class GetAreasInfoFormatter
 * 地域観測所情報ファイル取得用のレスポンス整形クラス
 * ------------------------------------------------------------------------------
 * @LIS 20200302 新規作成
 * ==============================================================================
 */
export class GetAreasInfoFormatter extends IFormatter {
	/**
	 * コンストラクタ
	 * @constructor
	 * @return {GetAreasInfoFormatter} GetAreasInfoFormatterクラスのインスタンス
	 * ----------------------------------------------------------------------------
	 */
	constructor() {
		super("getAreasInfo");
	}

	format(req, resultJsons) {

		// API結果データとして整形する
		let results = {};

		if (req.params.prefs) {
			results["prefs"] = resultJsons;
		} else {
			results["towns"] = resultJsons;
		}

		let dummyData = {
			apiId: req.apiId,
			params: req.params,
			tag: req.tag,
			macro: req.macro,
			result: results
		};

		return dummyData;
	}
}

/**
 * Class GetRiversFormatter
 * 地域関連河川一覧ファイル取得用のレスポンス整形クラス
 * ------------------------------------------------------------------------------
 * @LIS 20200305 新規作成
 * ==============================================================================
 */
export class GetRiversFormatter extends IFormatter {
	/**
	 * コンストラクタ
	 * @constructor
	 * @return {GetRiversFormatter} GetRiversFormatterクラスのインスタンス
	 * ----------------------------------------------------------------------------
	 */
	constructor() {
		super("getRivers");
	}

	format(req, resultJsons) {

		// API結果データとして整形する
		let results = {};
		let rivers = new Array();

		for (const result of resultJsons) {
			for (const river of result.rivers) {
				// 重複を除去して一つの配列に統合する
				if (!rivers.some(el => el.code === river.code)) {
					rivers.push(river);
				}
			}
		}

		results["rivers"] = rivers;

		let dummyData = {
			apiId: req.apiId,
			params: req.params,
			tag: req.tag,
			macro: req.macro,
			result: results
		};

		return dummyData;
	}
}

/**
 * Class GetAreasLevelsFormatter
 * 市町村別観測値一覧ファイル取得用のレスポンス整形クラス
 * ------------------------------------------------------------------------------
 * @LIS 20200319 新規作成
 * ==============================================================================
 */
export class GetAreasLevelsFormatter extends IFormatter {
	/**
	 * コンストラクタ
	 * @constructor
	 * @return {GetAreasLevelsFormatter} GetAreasLevelsFormatterクラスのインスタンス
	 * ----------------------------------------------------------------------------
	 */
	constructor() {
		super("getAreasLevels");
	}

	format(req, resultJsons) {

		// API結果データとして整形する
		let results = {};
		let levels = new Array();

		for (const result of resultJsons) {
			for (const level of result.levels) {
				// 一つの配列に統合する
				levels.push(level);
			}
		}

		results["levels"] = levels;

		let dummyData = {
			apiId: req.apiId,
			params: req.params,
			tag: req.tag,
			macro: req.macro,
			result: results
		};

		return dummyData;
	}
}

/**
 * Class GetLevelsFormatter
 * 指定観測所観測値一覧ファイル取得用のレスポンス整形クラス
 * ------------------------------------------------------------------------------
 * @LIS 20200324 新規作成
 * ==============================================================================
 */
export class GetLevelsFormatter extends IFormatter {
	/**
	 * コンストラクタ
	 * @constructor
	 * @return {GetLevelsFormatter} GetLevelsFormatterクラスのインスタンス
	 * ----------------------------------------------------------------------------
	 */
	constructor() {
		super("getLevels");
	}

	format(req, resultJsons) {

		let results = resultJsons[0];

		if (resultJsons.length > 0) {
			// API結果データとして整形する

			// 観測日時降順にソート
			results.levels.sort(function(a,b){
				if(a.date < b.date) return 1;
				if(a.date > b.date) return -1;
				return 0;
			});

			// 観測日時がarea_levelsの日時より未来のデータを除外
			if (req.params.date != "") {
				for (let i = 0; i < results.levels.length - 1; i++) {
					if (results.levels[i].date <= req.params.date) {
						results.levels = results.levels.slice(i, results.levels.length);
						break;
					}
				}
			}

			// 水位情報が151件以上の場合、古いデータを削減
			if (results.levels.length > 150) {
				results.levels = results.levels.slice(0, 150);
			}
			results.length = results.levels.length;
		}

		let dummyData = {
			apiId: req.apiId,
			params: req.params,
			tag: req.tag,
			macro: req.macro,
			result: results
		};

		return dummyData;
	}
}

/**
 * Class GetRankingFormatter
 * ランキング情報（地域観測所情報）ファイル取得用のレスポンス整形クラス
 * ------------------------------------------------------------------------------
 * @LIS 20200302 新規作成
 * ==============================================================================
 */
export class GetRankingFormatter extends IFormatter {
	/**
	 * コンストラクタ
	 * @constructor
	 * @return {GetRankingFormatter} GetRankingFormatterクラスのインスタンス
	 * ----------------------------------------------------------------------------
	 */
	constructor() {
		super("getRanking");
	}

	format(req, resultJsons) {

		// API結果データとして整形する
		let results = {};
		let obss = new Array();

		for (const result of resultJsons) {
			for (const obs of result.obss) {
				// 一つの配列に統合する
				obss.push(obs);
			}
		}

		// 観測所番号昇順にソート
		obss.sort(function(a,b){
		    if(a.code < b.code) return -1;
		    if(a.code > b.code) return 1;
		    return 0;
		});
		// ランキングポイント降順にソート
		obss.sort(function(a,b){
		    if(a.point < b.point) return 1;
		    if(a.point > b.point) return -1;
		    return 0;
		});

		results["obss"] = obss;

		let dummyData = {
			apiId: req.apiId,
			params: req.params,
			tag: req.tag,
			macro: req.macro,
			result: results
		};

		return dummyData;
	}
}

/**
 * Class GetRiverObsFormatter
 * 河川関連観測所一覧ファイル取得用のレスポンス整形クラス
 * ------------------------------------------------------------------------------
 * @LIS 20200326 新規作成
 * ==============================================================================
 */
export class GetRiverObsFormatter extends IFormatter {
	/**
	 * コンストラクタ
	 * @constructor
	 * @return {GetRiverObsFormatter} GetRiverObsFormatterクラスのインスタンス
	 * ----------------------------------------------------------------------------
	 */
	constructor() {
		super("getRiverObs");
	}

	format(req, resultJsons) {

		// API結果データとして整形する
		let dummyData = {
			apiId: req.apiId,
			params: req.params,
			tag: req.tag,
			macro: req.macro,
			result: resultJsons[0]
		};

		return dummyData;
	}
}