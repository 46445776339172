import {
  _CNST
} from "./const.js";
/**
 * マップウィンドウクラス（メインウィンドウ）
 * ------------------------------------------------------------------------------
 * @LIS 20180406 LIS 新規作成
 * ==============================================================================
 */
export class MapWin {

  /**
   * コンストラクタ
   * @param {string} tagId mapを表示するHTMLタグのid名
   * @param {object} configData getConfigリクエストで取得した設定内容 
   * ----------------------------------------------------------------------------
   */
  constructor(tagId, configData) {
    /** HTMLの地図表示tagID */
    this.id = tagId;
    /** 画面設定内容 */
    this.config = configData;
    /** 地図タイル格納配列 */
    this.mapInfoTiles = [];

    /** タイルを */
    this.config.mapTileUrls.forEach(tile => {
      this.mapInfoTiles.push(tile);
    });

    // leaflet地図オブジェクト作成
    this.mapObj = L.map(this.id, {
      attributionControl: false,
      zoomControl: false,
      center: this.config.initCenter,
      zoom: this.config.initZoom,
      minZoom: this.config.minZoom,
      maxZoom: this.config.maxZoom,
      maxBounds: this.config.mapRange
    });
    this.tileIndex = this.config.initTileIndex;

    //地図タイル表示
    this.mapTiles = [];
    for (var idx in this.mapInfoTiles) {
      this.mapTiles[idx] = L.tileLayer(this.mapInfoTiles[idx].url, {
        maxZoom: this.config.maxZoom,
        attribution: this.mapInfoTiles[idx].attrb,
        id: "map" + idx
      });
    }
    this.mapTiles[this.tileIndex].addTo(this.mapObj);

    //河川タイル
    this.rvrTile = L.tileLayer(this.config.riverTileUrl, {
      maxZoom: this.config.riverTileRange.max,
      minZoom: this.config.riverTileRange.min,
      attribution: "",
      id: "riverMap",
      tms: true
    });
    this.rvrTile.addTo(this.mapObj);

    //観測所表示フラグ初期化
    this._dispOBS = 1;
    //河川表示フラグ初期化
    this._dispRVR = 1;
    //CCTV表示フラグ初期化
    this._dispCCTV = 1;
    //テレメータ水位観測所表示フラグ初期化
    this._dispTMOBS = 1;
    //テレメータCCTV表示フラグ初期化
    this._dispTMCCTV = 1;

    //観測所アイコン(通常)
    this.obsNmlIcon = L.icon({
      iconUrl: this.config.topUrl + this.config.obsNmlIconUrl,
      iconSize: [25, 35],
      iconAnchor: [12, 35],
      popupAnchor: [0, -36]
    });
    //観測所アイコン(開始)
    this.obsStrIcon = L.icon({
      iconUrl: this.config.topUrl + this.config.obsStrIconUrl,
      iconSize: [25, 35],
      iconAnchor: [12, 35],
      popupAnchor: [0, -36]
    });
    //観測所アイコン(危険)
    this.obsWrnIcon = L.icon({
      iconUrl: this.config.topUrl + this.config.obsWrnIconUrl,
      iconSize: [25, 35],
      iconAnchor: [12, 35],
      popupAnchor: [0, -36]
    });
    //観測所アイコン(洪水)
    this.obsFldIcon = L.icon({
      iconUrl: this.config.topUrl + this.config.obsFldIconUrl,
      iconSize: [25, 35],
      iconAnchor: [12, 35],
      popupAnchor: [0, -36]
    });
    //観測所アイコン(欠測)
    this.obsNonIcon = L.icon({
      iconUrl: this.config.topUrl + this.config.obsNonIconUrl,
      iconSize: [25, 35],
      iconAnchor: [12, 35],
      popupAnchor: [0, -36]
    });
    //CCTVアイコン
    this.cctvIcon = L.icon({
      iconUrl: this.config.topUrl + this.config.cctvIconUrl,
      iconSize: [25, 35],
      iconAnchor: [12, 35],
      popupAnchor: [0, -36]
    });
    //現在位置アイコン
    this.locationIcon = L.icon({
      iconUrl: this.config.topUrl + this.config.locationIconUrl,
      iconSize: [15, 20],
      iconAnchor: [7, 20],
      popupAnchor: [0, -18]
    });
    //テレメータ観測所アイコン (ノーマル　無変化)
    this.tmObsNmlNoChangeIcon = L.icon({
      iconUrl: this.config.topUrl + this.config.tmObsIcon.nmlNoChangeUrl,
      iconSize: [25, 35],
      iconAnchor: [12, 35],
      popupAnchor: [0, -36]
    });
    //テレメータ観測所アイコン (ノーマル　上昇)
    this.tmObsNmlUpIcon = L.icon({
      iconUrl: this.config.topUrl + this.config.tmObsIcon.nmlUpUrl,
      iconSize: [25, 35],
      iconAnchor: [12, 35],
      popupAnchor: [0, -36]
    });
    //テレメータ観測所アイコン (ノーマル　下降)
    this.tmObsNmlDownIcon = L.icon({
      iconUrl: this.config.topUrl + this.config.tmObsIcon.nmlDownUrl,
      iconSize: [25, 35],
      iconAnchor: [12, 35],
      popupAnchor: [0, -36]
    });
    //テレメータ観測所アイコン (ワーニング　無変化)
    this.tmObsWrnNoChangeIcon = L.icon({
      iconUrl: this.config.topUrl + this.config.tmObsIcon.wrnNoChangeUrl,
      iconSize: [25, 35],
      iconAnchor: [12, 35],
      popupAnchor: [0, -36]
    });
    //テレメータ観測所アイコン (ワーニング　上昇)
    this.tmObsWrnUpIcon = L.icon({
      iconUrl: this.config.topUrl + this.config.tmObsIcon.wrnUpUrl,
      iconSize: [25, 35],
      iconAnchor: [12, 35],
      popupAnchor: [0, -36]
    });
    //テレメータ観測所アイコン (ワーニング　下降)
    this.tmObsWrnDownIcon = L.icon({
      iconUrl: this.config.topUrl + this.config.tmObsIcon.wrnDownUrl,
      iconSize: [25, 35],
      iconAnchor: [12, 35],
      popupAnchor: [0, -36]
    });
    //テレメータ観測所アイコン (異常　無変化)
    this.tmObsEmgNoChangeIcon = L.icon({
      iconUrl: this.config.topUrl + this.config.tmObsIcon.emgNoChangeUrl,
      iconSize: [25, 35],
      iconAnchor: [12, 35],
      popupAnchor: [0, -36]
    });
    //テレメータ観測所アイコン (異常　上昇)
    this.tmObsEmgUpIcon = L.icon({
      iconUrl: this.config.topUrl + this.config.tmObsIcon.emgUpUrl,
      iconSize: [25, 35],
      iconAnchor: [12, 35],
      popupAnchor: [0, -36]
    });
    //テレメータ観測所アイコン (異常　下降)
    this.tmObsEmgDownIcon = L.icon({
      iconUrl: this.config.topUrl + this.config.tmObsIcon.emgDownUrl,
      iconSize: [25, 35],
      iconAnchor: [12, 35],
      popupAnchor: [0, -36]
    });
    //テレメータ観測所アイコン（基準なし　無変化）
    this.tmObsNoStdNoChangeIcon = L.icon({
      iconUrl: this.config.topUrl + this.config.tmObsIcon.noStdNoChangeUrl,
      iconSize: [25, 35],
      iconAnchor: [12, 35],
      popupAnchor: [0, -36]
    })
    //テレメータ観測所アイコン（基準なし　上昇）
    this.tmObsNoStdUpIcon = L.icon({
      iconUrl: this.config.topUrl + this.config.tmObsIcon.noStdUpUrl,
      iconSize: [25, 35],
      iconAnchor: [12, 35],
      popupAnchor: [0, -36]
    })
    //テレメータ観測所アイコン（基準なし　下降）
    this.tmObsNoStdDownIcon = L.icon({
      iconUrl: this.config.topUrl + this.config.tmObsIcon.noStdDownUrl,
      iconSize: [25, 35],
      iconAnchor: [12, 35],
      popupAnchor: [0, -36]
    })
    //テレメータ観測所アイコン (欠測)
    this.tmObsMissIcon = L.icon({
      iconUrl: this.config.topUrl + this.config.tmObsIcon.missUrl,
      iconSize: [25, 35],
      iconAnchor: [12, 35],
      popupAnchor: [0, -36]
    });
    //テレメータCCTVアイコン
    this.tmCctvIcon = L.icon({
      iconUrl: this.config.topUrl + this.config.tmCctvIconUrl,
      iconSize: [25, 35],
      iconAnchor: [12, 35],
      popupAnchor: [0, -36]
    });

    this.mapObj.createPane("paneArea").style.zIndex = _CNST.Z_IDX + 10;
    this.mapObj.createPane("paneRiver").style.zIndex = _CNST.Z_IDX + 30;
    this.mapObj.createPane("paneTmCctv").style.zIndex = _CNST.Z_IDX + 40;
    this.mapObj.createPane("paneCctv").style.zIndex = _CNST.Z_IDX + 50;
    this.mapObj.createPane("paneTmObs").style.zIndex = _CNST.Z_IDX + 60;
    this.mapObj.createPane("paneObs").style.zIndex = _CNST.Z_IDX + 70;
    this.mapObj.createPane("paneOverTmObs").style.zIndex = _CNST.Z_IDX + 80;
  }

  /**
   * イベント登録処理
   * @param {string} eventType イベント名 
   * @param {function} eventFunc イベント時実行関数
   * ----------------------------------------------------------------------------
   */
  addEvent(eventType, eventFunc) {
    this.mapObj.on(eventType, eventFunc);
  }

  /**
   * 地図中心地移動
   * @param {number} lon 経度 
   * @param {number} lat 緯度
   * @param {number} zoom ズームレベル
   * ----------------------------------------------------------------------------
   */
  move(lon, lat, zoom) {
    var zm = null;
    if (zoom == null) {
      zm = this.mapObj.getZoom();
    } else {
      zm = zoom;
    }
    this.mapObj.setView(new L.latLng(lat, lon), zm);
  }

  /**
   * 中心座標取得
   * @return {Object} 経度緯度情報
   * ----------------------------------------------------------------------------
   */
  getCenter() {
    return this.mapObj.getCenter();
  }

  /**
   * ズーム変更
   * @param {number} zoom ズームレベル 
   * ----------------------------------------------------------------------------
   */
  setZoom(zoom) {
    this.mapObj.setZoom(zoom);
  }

  /**
   * ズーム取得
   * @return {number} ズームレベル 
   * ----------------------------------------------------------------------------
   */
  getZoom(zoom) {
    return this.mapObj.getZoom();
  }

  /**
   * タイル地図変更
   * @param {number} index タイルインデックス番号 
   * ----------------------------------------------------------------------------
   */
  changeTile(index) {
    if (this.tileIndex == index) return;

    this.mapObj.removeLayer(this.mapTiles[this.tileIndex]);

    this.tileIndex = index;
    this.mapTiles[this.tileIndex].addTo(this.mapObj);
  }

  /**
   * 現在表示中の地図タイルインデックス取得
   * @return {number} タイルインデックス
   * ----------------------------------------------------------------------------
   */
  getTileIndex() {
    return this.tileIndex;
  }

  /**
   * 観測所表示状態SETTER
   * ----------------------------------------------------------------------------
   */
  set dispOBS(flg) {
    this._dispOBS = flg;
  }

  /**
   * 観測所表示状態GETTER
   * ----------------------------------------------------------------------------
   */
  get dispOBS() {
    return this._dispOBS;
  }

  /**
   * 河川タイル表示処理
   * ----------------------------------------------------------------------------
   */
  showRiverTile() {
    if (!this.mapObj.hasLayer(this.rvrTile)) {
      this.mapObj.addLayer(this.rvrTile);
    }
  }

  /**
   * 河川タイル非表示処理
   * ----------------------------------------------------------------------------
   */
  hideRiverTile() {
    if (this.mapObj.hasLayer(this.rvrTile)) {
      this.mapObj.removeLayer(this.rvrTile);
    }
  }

  /**
   * 河川表示状態SETTER
   * ----------------------------------------------------------------------------
   */
  set dispRVR(flg) {
    this._dispRVR = flg;
  }

  /**
   * 河川表示状態GETTER
   * ----------------------------------------------------------------------------
   */
  get dispRVR() {
    return this._dispRVR;
  }

  /**
   * CCTV表示状態SETTER
   * ----------------------------------------------------------------------------
   */
  set dispCCTV(flg) {
    this._dispCCTV = flg;
  }

  /**
   * CCTV表示状態GETTER
   * ----------------------------------------------------------------------------
   */
  get dispCCTV() {
    return this._dispCCTV;
  }

  /**
   * TM水位観測所表示状態SETTER
   * ----------------------------------------------------------------------------
   */
  set dispTMOBS(flg) {
    this._dispTMOBS = flg;
  }

  /**
   * TM水位観測所表示状態GETTER
   * ----------------------------------------------------------------------------
   */
  get dispTMOBS() {
    return this._dispTMOBS;
  }

  /**
   * TMCCTV表示状態SETTER
   * ----------------------------------------------------------------------------
   */
  set dispTMCCTV(flg) {
    this._dispTMCCTV = flg;
  }

  /**
   * TMCCTV表示状態GETTER
   * ----------------------------------------------------------------------------
   */
  get dispTMCCTV() {
    return this._dispTMCCTV;
  }






  /**
   * マップオブジェクトGetter
   * ----------------------------------------------------------------------------
   */
  get map() {
    return this.mapObj;
  }


  /**
   * 通常時アイコン返却
   * ----------------------------------------------------------------------------
   */
  getNormalIcon() {
    return this.obsNmlIcon;
  }

  /**
   * 観測開始時アイコン返却
   * ----------------------------------------------------------------------------
   */
  getStartIcon() {
    return this.obsStrIcon;
  }

  /**
   * 危険時アイコン返却
   * ----------------------------------------------------------------------------
   */
  getWarnIcon() {
    return this.obsWrnIcon;
  }

  /**
   * 洪水時アイコン返却
   * ----------------------------------------------------------------------------
   */
  getFloodIcon() {
    return this.obsFldIcon;
  }

  /**
   * 欠測時アイコン返却
   * ----------------------------------------------------------------------------
   */
  getNonIcon() {
    return this.obsNonIcon;
  }

  /**
   * 現在位置アイコン返却
   * ----------------------------------------------------------------------------
   */
  getLocationIcon() {
    return this.locationIcon;
  }

  /**
   * CCTVアイコン返却
   * ----------------------------------------------------------------------------
   */
  getCctvIcon() {
    return this.cctvIcon;
  }

  /**
   * テレメータアイコン返却（通常　無変化）
   * ----------------------------------------------------------------------------
   */
  getTmNmlNoChangeIcon() {
    return this.tmObsNmlNoChangeIcon;
  }

  /**
   * テレメータアイコン返却（通常　上昇）
   * ----------------------------------------------------------------------------
   */
  getTmNmlUpIcon() {
    return this.tmObsNmlUpIcon;
  }

  /**
   * テレメータアイコン返却（通常　下降）
   * ----------------------------------------------------------------------------
   */
  getTmNmlDownIcon() {
    return this.tmObsNmlDownIcon;
  }

  /**
   * テレメータアイコン返却（警戒　無変化）
   * ----------------------------------------------------------------------------
   */
  getTmWrnNoChangeIcon() {
    return this.tmObsWrnNoChangeIcon;
  }

  /**
   * テレメータアイコン返却（警戒　上昇）
   * ----------------------------------------------------------------------------
   */
  getTmWrnUpIcon() {
    return this.tmObsWrnUpIcon;
  }

  /**
   * テレメータアイコン返却（警戒　下降）
   * ----------------------------------------------------------------------------
   */
  getTmWrnDownIcon() {
    return this.tmObsWrnDownIcon;
  }

  /**
   * テレメータアイコン返却（異常　無変化）
   * ----------------------------------------------------------------------------
   */
  getTmEmgNoChangeIcon() {
    return this.tmObsEmgNoChangeIcon;
  }

  /**
   * テレメータアイコン返却（異常　上昇）
   * ----------------------------------------------------------------------------
   */
  getTmEmgUpIcon() {
    return this.tmObsEmgUpIcon;
  }

  /**
   * テレメータアイコン返却（異常　下降）
   * ----------------------------------------------------------------------------
   */
  getTmEmgDownIcon() {
    return this.tmObsEmgDownIcon;
  }

  /**
   * テレメータアイコン返却（基準なし　無変化）
   * ----------------------------------------------------------------------------
   */
  getTmNoStdNoChangeIcon() {
    return this.tmObsNoStdNoChangeIcon;
  }

  /**
   * テレメータアイコン返却（基準なし　上昇）
   * ----------------------------------------------------------------------------
   */
  getTmNoStdUpIcon() {
    return this.tmObsNoStdUpIcon;
  }

  /**
   * テレメータアイコン返却（基準なし　下降）
   * ----------------------------------------------------------------------------
   */
  getTmNoStdDownIcon() {
    return this.tmObsNoStdDownIcon;
  }

  /**
   * テレメータアイコン返却（欠測）
   * ----------------------------------------------------------------------------
   */
  getTmMissIcon() {
    return this.tmObsMissIcon;
  }

  /**
   * テレメータCCTVアイコン返却
   * ----------------------------------------------------------------------------
   */
  getTmCctvIcon() {
    return this.tmCctvIcon;
  }

}