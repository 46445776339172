import PageLog from "./pagelog";

export default class {
  constructor(config) {
    this.config = config;
    this.nmBtnElm = $("<img class=\"opinfo\" id=\"nminfoBtn\" src=\"./img/nminfo.png\">").appendTo("body");
    this.emBtnElm = $("<img class=\"opinfo\" id=\"eminfoBtn\" src=\"./img/eminfo.png\">").appendTo("body");
    this.nmWinElm = $("<div class=\"opinfoWin\" id=\"nminfoWin\"></div>").appendTo("body");
    this.emWinElm = $("<div class=\"opinfoWin\" id=\"eminfoWin\"></div>").appendTo("body");
    this.nmDatElm = $("<div></div>").appendTo(this.nmWinElm);
    this.emDatElm = $("<div></div>").appendTo(this.emWinElm);

    this.nmClose = $("<img src=\"img/close.png\">").appendTo(this.nmWinElm);
    this.emClose = $("<img src=\"img/close.png\">").appendTo(this.emWinElm);
  }

  create(data, config) {
    if (data == null) return;
    this.config = config;

    this.nmBtnElm.on("click", { win: this.nmWinElm, conf: this.config }, this.show);
    this.emBtnElm.on("click", { win: this.emWinElm, conf: this.config }, this.show);

    this.nmClose.on("click", { win: this.nmWinElm }, this.close);
    this.emClose.on("click", { win: this.emWinElm }, this.close);

    if (data.normal != null && data.normal != "") {
      this.nmBtnElm.css("display", "block");
      this.nmDatElm.html(data.normal);
    }
    if (data.emergency != null && data.emergency != "") {
      this.emBtnElm.css("display", "block");
      this.emDatElm.html(data.emergency);
    }
  }

  show(event) {
    event.data.win.css("display", "block");
    if (event.data.win.attr("id") == "nminfoWin") {
      PageLog.log(event.data.conf.pageLogUrl.nInfoUrl);
    } else if (event.data.win.attr("id") == "eminfoWin") {
      PageLog.log(event.data.conf.pageLogUrl.eInfoUrl);
    }

    var ww = $(window).width();
    var wh = $(window).height();
    var cw = event.data.win.width();
    var ch = event.data.win.height();
    var top = (wh / 2) + (ch / 2);
    var left = (ww / 2) - (cw / 2);
    event.data.win.css("top", top + "px");
    event.data.win.css("left", left + "px");
  }

  close(event) {
    event.data.win.css("display", "none");
  }
}