/**
 * CCTV画像拡大ダイアログ部品
 * ------------------------------------------------------------------------------
 * @LIS 20180521 新規作成
 * ==============================================================================
 */
export default class {
  /**
   * コンストラクタ
   */
  constructor() {
    // CCTV画像拡大ダイアログの作成
    this.cctvZoomDlgBk = $('<div id="cctvZoomDlgBk"></div>').appendTo("body");
    this.cctvZoomDlg = $('<div id="cctvZoomDlg"></div>').appendTo(
      this.cctvZoomDlgBk
    );
    this.cctvZoomHead = $('<div id="cctvZoomHead"></div>').appendTo(
      this.cctvZoomDlg
    );
    this.cctvZoomFrame = $(
      '<div id="cctvZoomFrame"><div id="cctvZoomFrameTime">----/--/-- --:--</div><div id="cctvZoomFrameImage"></div></div>'
    ).appendTo(this.cctvZoomDlg);
    this.cctvZoomFoot = $('<div id="cctvZoomFoot"></div>').appendTo(
      this.cctvZoomDlg
    );

    // クローズボタンの実装
    this.cctvZoomDlgClose = $('<img src="img/close.png">').appendTo(
      this.cctvZoomHead
    );
    this.cctvZoomDlgClose.on(
      "click",
      {
        win: this.cctvZoomdlgBk,
      },
      this.close
    );

    $("#cctvZoomDlg").setExtVal("instance", this);
  }

  /**
   * cctv拡大ダイアログの作成
   * @param {String} url
   */
  create(url, time) {
    var dlgInst = $("#cctvZoomDlg").getExtVal("instance");

    dlgInst.show();
    dlgInst.cctvZoomImgStyle(url, time);
  }
  /**
   * cctv拡大ダイアログの表示
   */
  show() {
    $("#cctvZoomDlgBk").css("display", "block");

    //幅中心に持っていく
    var scrWidth = $(window).width();
    var dlgWidth = $("#cctvZoomDlg").width();
    var xpos = (scrWidth - dlgWidth) / 2;
    $("#cctvZoomDlg").css("left", xpos + "px");

    //高さ中心に持っていく
    var scrHeight = $(window).height();
    var dlgHeight = $("#cctvZoomDlg").height();
    var ypos = (scrHeight - dlgHeight) / 2;
    $("#cctvZoomDlg").css("top", ypos + "px");
  }

  /**
   * cctv画像の表示設定
   */
  cctvZoomImgStyle(url, time) {
    if (time != null) {
      $("#cctvZoomFrameTime").html(time);
      $("#cctvZoomFrameTime").css("display", "block");
    } else {
      $("#cctvZoomFrameTime").css("display", "none");
    }
    $("#cctvZoomFrameImage").css("background-image", "url(" + url + ")");
  }

  /**
   * cctv拡大ダイアログの非表示
   */
  close() {
    $("#cctvZoomDlg").setExtVal("url", null);
    $("#cctvZoomDlgBk").css("display", "none");
  }

  /**
   * ダイアログが開いているかどうか確認
   * @return {boolean} true(開いている)、false(閉じている)
   * ----------------------------------------------------------------------------
   */
  isOpen() {
    if ($("#cctvZoomDlgBk").css("display") == "block") {
      return true;
    }
    return false;
  }

  /**
   * 再描画処理
   * @param {Object} event
   */
  redraw(event) {
    var dlgInst = $("#cctvZoomDlg").getExtVal("instance");
    // ダイアログの幅中心算出
    var scrWidth = $(window).width();
    var dlgWidth = $("#cctvZoomDlg").width();
    var xpos = (scrWidth - dlgWidth) / 2;
    $("#cctvZoomDlg").css("left", xpos + "px");

    // ダイアログの高さ中心算出
    var scrHeight = $(window).height();
    var dlgHeight = $("#cctvZoomDlg").height();
    var ypos = (scrHeight - dlgHeight) / 2;
    $("#cctvZoomDlg").css("top", ypos + "px");
  }
}
