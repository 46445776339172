import {
	_CNST
} from "./const.js";

// getConfig(ConfigApi)で取得する設定情報
var _config = null;


/**
 * Class GetFileUrlMaker
 * File取得API用のURL作成クラス
 * ------------------------------------------------------------------------------
 * @LIS 20200212 新規作成
 * ==============================================================================
 */
export class FileUrlMaker {

	/**
	 * コンストラクタ
	 * @constructor
	 * @return {Requester} Requesterクラスのインスタンス
	 * ----------------------------------------------------------------------------
	 */
	constructor() {
		this.urlMakerMap = new Map();
		const getConfigUrlsMaker = new GetConfigUrlsMaker();
		const getOptionalInfoUrlsMaker = new GetOptionalInfoUrlsMaker();
		const getPositionUrlsMaker = new GetPositionUrlsMaker();
		const getAreasInfoUrlsMaker = new GetAreasInfoUrlsMaker();
		const getRiversUrlsMaker = new GetRiversUrlsMaker();
		const getAreasLevelsUrlsMaker = new GetAreasLevelsUrlsMaker();
		const getLevelsUrlsMaker = new GetLevelsUrlsMaker();
		const getRankingUrlsMaker = new GetRankingUrlsMaker();
		const getRiverObsUrlsMaker = new GetRiverObsUrlsMaker();

		this.urlMakerMap.set(getConfigUrlsMaker.apiId, getConfigUrlsMaker);
		this.urlMakerMap.set(getOptionalInfoUrlsMaker.apiId, getOptionalInfoUrlsMaker);
		this.urlMakerMap.set(getPositionUrlsMaker.apiId, getPositionUrlsMaker);
		this.urlMakerMap.set(getAreasInfoUrlsMaker.apiId, getAreasInfoUrlsMaker);
		this.urlMakerMap.set(getRiversUrlsMaker.apiId, getRiversUrlsMaker);
		this.urlMakerMap.set(getAreasLevelsUrlsMaker.apiId, getAreasLevelsUrlsMaker);
		this.urlMakerMap.set(getLevelsUrlsMaker.apiId, getLevelsUrlsMaker);
		this.urlMakerMap.set(getRankingUrlsMaker.apiId, getRankingUrlsMaker);
		this.urlMakerMap.set(getRiverObsUrlsMaker.apiId, getRiverObsUrlsMaker);

	}

	/**
	 * コンストラクタ
	 * @param {Object} _request RequesterRequestに登録されているリクエスト情報
	 * ----------------------------------------------------------------------------
	 */
	registRequest(_request) {
		this.request = _request;
	}

	/**
	 * コンフィグ設定引き渡し
	 * param {Object} config
	 * ----------------------------------------------------------------------------
	 */
	setConfig(config) {
		_config = config;
	}

	/**
	 * URL生成処理
	 * 登録されているリクエスト情報からファイル取得URLを作成する
	 */
	getUrls() {
		if (!this.request) {
			return null;
		}

		let urlObj =  this.urlMakerMap.get(this.request.apiId);
		return urlObj.getUrls(this.request);
	}

}

export class IUrlsMaker {

	constructor(_apiId) {
		this._apiId = _apiId;
	}

	get apiId () {
		return this._apiId;
	}
}

/**
 * Class GetConfigUrlsMaker
 * 画面設定情報ファイル取得用のURL作成クラス
 * ------------------------------------------------------------------------------
 * @LIS 20200225 新規作成
 * ==============================================================================
 */
export class GetConfigUrlsMaker extends IUrlsMaker {

	/**
	 * コンストラクタ
	 * @constructor
	 * @return {Requester} Requesterクラスのインスタンス
	 * ----------------------------------------------------------------------------
	 */
	constructor() {
		super("getConfig");
	}

	// eslint-disable-next-line no-unused-vars
	getUrls(req) {
		let ret = new Array();
		ret.push(_CNST.GET_CONFIG_URL);
		return ret;
	}
}

/**
 * Class GetOptionalInfoUrlsMaker
 * 任意情報ファイル取得用のURL作成クラス
 * ------------------------------------------------------------------------------
 * @LIS 20200225 新規作成
 * ==============================================================================
 */
export class GetOptionalInfoUrlsMaker extends IUrlsMaker {
	/**
	 * コンストラクタ
	 * @constructor
	 * @return {Requester} Requesterクラスのインスタンス
	 * ----------------------------------------------------------------------------
	 */
	constructor() {
		super("getOptionalInfo");
	}

	// eslint-disable-next-line no-unused-vars
	getUrls(req) {
		let ret = new Array();
		ret.push(_CNST.GET_OPTIONAL_INFO_URL);
		return ret;
	}
}

/**
 * Class GetPositionUrlsMaker
 * 位置情報ファイル取得用のURL作成クラス
 * ------------------------------------------------------------------------------
 * @LIS 20200212 新規作成
 * ==============================================================================
 */
export class GetPositionUrlsMaker extends IUrlsMaker {

	/**
	 * コンストラクタ
	 * @constructor
	 * @return {Requester} Requesterクラスのインスタンス
	 * ----------------------------------------------------------------------------
	 */
	constructor() {
		super("getPosition");
	}

	getUrls(req) {
		let ret = new Array();

		if (req.params.level === "town") {
			// 市町村情報の取得
			ret.push(_config.topUrl + _config.getPositionUrl.twnUrl);
			if (req.params.firstFlag) {
				// 初回起動時のファイル読込時
				ret.push(_config.topUrl + _config.getPositionUrl.prefUrl);
			}
		} else {
			// 都道府県の場合の処理
			ret.push(_config.topUrl + _config.getPositionUrl.prefUrl);
			if (req.params.firstFlag) {
				// 初回起動時のファイル読込時
				ret.push(_config.topUrl + _config.getPositionUrl.twnUrl);
			}
		}

		return ret;
	}
}

/**
 * Class GetAreasInfoUrlsMaker
 * 地域観測所情報ファイル取得用のURL作成クラス
 * ------------------------------------------------------------------------------
 * @LIS 20200302 新規作成
 * ==============================================================================
 */
export class GetAreasInfoUrlsMaker extends IUrlsMaker {

	/**
	 * コンストラクタ
	 * @constructor
	 * @return {Requester} Requesterクラスのインスタンス
	 * ----------------------------------------------------------------------------
	 */
	constructor() {
		super("getAreasInfo");
	}

	getUrls(req) {
		let ret = new Array();

		if (req.params.prefs) {
			for (const pref of req.params.prefs) {
				ret.push(_config.topUrl + _config.getAreasInfoUrl.replace("%", pref.code));
			}
		} else {
			for (const town of req.params.towns) {
				ret.push(_config.topUrl + _config.getAreasInfoUrl.replace("%", town.code));
			}
		}

		return ret;
	}
}

/**
 * Class GetRiversUrlsMaker
 * 地域関連河川一覧ファイル取得用のURL作成クラス
 * ------------------------------------------------------------------------------
 * @LIS 20200305 新規作成
 * ==============================================================================
 */
export class GetRiversUrlsMaker extends IUrlsMaker {

	/**
	 * コンストラクタ
	 * @constructor
	 * @return {Requester} Requesterクラスのインスタンス
	 * ----------------------------------------------------------------------------
	 */
	constructor() {
		super("getRivers");
	}

	getUrls(req) {
		let ret = new Array();

		if (req.params.prefs) {
			for (const pref of req.params.prefs) {
				ret.push(_config.topUrl + _config.getRiversUrl.replace("%", pref.code));
			}
		} else {
			for (const town of req.params.towns) {
				ret.push(_config.topUrl + _config.getRiversUrl.replace("%", town.code));
			}
		}

		return ret;
	}
}

/**
 * Class GetAreasLevelsUrlsMaker
 * 市町村別観測値一覧ファイル取得用のURL作成クラス
 * ------------------------------------------------------------------------------
 * @LIS 20200319 新規作成
 * ==============================================================================
 */
export class GetAreasLevelsUrlsMaker extends IUrlsMaker {

	/**
	 * コンストラクタ
	 * @constructor
	 * @return {Requester} Requesterクラスのインスタンス
	 * ----------------------------------------------------------------------------
	 */
	constructor() {
		super("getAreasLevels");
	}

	getUrls(req) {
		let ret = new Array();

		if (req.params.towns) {
			for (const town of req.params.towns) {
				ret.push(_config.topUrl + _config.getAreasLevelsUrl.replace("%", town.code));
			}
		}

		return ret;
	}
}

/**
 * Class GetLevelsUrlsMaker
 * 指定観測所観測値一覧ファイル取得用のURL作成クラス
 * ------------------------------------------------------------------------------
 * @LIS 20200324 新規作成
 * ==============================================================================
 */
export class GetLevelsUrlsMaker extends IUrlsMaker {

	/**
	 * コンストラクタ
	 * @constructor
	 * @return {Requester} Requesterクラスのインスタンス
	 * ----------------------------------------------------------------------------
	 */
	constructor() {
		super("getLevels");
	}

	getUrls(req) {
		let ret = new Array();
		ret.push(_config.topUrl + _config.getLevelsUrl.replace("%", req.params.obscode));
		return ret;
	}
}

/**
 * Class GetRankingUrlsMaker
 * ランキング情報（地域観測所情報）ファイル取得用のURL作成クラス
 * ------------------------------------------------------------------------------
 * @LIS 20200525 新規作成
 * ==============================================================================
 */
export class GetRankingUrlsMaker extends IUrlsMaker {

	/**
	 * コンストラクタ
	 * @constructor
	 * @return {Requester} Requesterクラスのインスタンス
	 * ----------------------------------------------------------------------------
	 */
	constructor() {
		super("getRanking");
	}

	getUrls(req) {
		let ret = new Array();

		if (req.params.prefs) {
			for (const pref of req.params.prefs) {
				ret.push(_config.topUrl + _config.getAreasInfoUrl.replace("%", pref.code));
			}
		} else {
			for (const town of req.params.towns) {
				ret.push(_config.topUrl + _config.getAreasInfoUrl.replace("%", town.code));
			}
		}

		return ret;
	}
}

/**
 * Class GetRiverObsUrlsMaker
 * 河川関連観測所一覧ファイル取得用のURL作成クラス
 * ------------------------------------------------------------------------------
 * @LIS 20200326 新規作成
 * ==============================================================================
 */
export class GetRiverObsUrlsMaker extends IUrlsMaker {

	/**
	 * コンストラクタ
	 * @constructor
	 * @return {Requester} Requesterクラスのインスタンス
	 * ----------------------------------------------------------------------------
	 */
	constructor() {
		super("getRiverObs");
	}

	getUrls(req) {
		let ret = new Array();
		ret.push(_config.topUrl + _config.getRiverObsUrl.replace("%", req.params.river));
		return ret;
	}
}