import { MenuBtn, MenuToggle } from "./menubtn.js";

/**
 * メニューボタンパネルクラス
 * ------------------------------------------------------------------------------
 * @LIS 20180411 新規作成
 * ==============================================================================
 */
export default class {

  /**
   * コンストラクタ
   * ----------------------------------------------------------------------------
   */
  constructor() {
    //ボタン配列生成
    this.btns = [];
  }

  /**
   * ボタン登録
   * @param {number} index ボタン番号 
   * @param {string} icon アイコンURL 
   * @param {function} clickFunc クリック時の関数 
   * @return {Object} ボタンオブジェクト
   * ----------------------------------------------------------------------------
   */
  registBtn(index, icon, clickFunc) {
    var btn = new MenuBtn(icon);
    btn.element.css("top", (14 + ((50 + 8) * index)) + "px");
    btn.element.appendTo("body");
    btn.element.on("click", { instance: btn }, clickFunc);
    this.btns[index] = btn;
    return btn;
  }

  /**
   * トグル登録
   * @param {number} index ボタン番号 
   * @param {Array} icons アイコンURL配列
   * @param {function} clickFunc クリック時の関数
   * @return {Object} トグルオブジェクト
   * ----------------------------------------------------------------------------
   */
  registToggle(index, icons, clickFunc) {
    var btn = new MenuToggle(icons);
    btn.element.css("top", (14 + ((50 + 8) * index)) + "px");
    btn.element.appendTo("body");
    btn.element.on("click", { instance: btn }, clickFunc);
    this.btns[index] = btn;
  }

  /**
   * ボタンオブジェクト取得
   * @param {number} idx ボタンのインデックス番号 
   * @return {Object} ボタンオブジェクト
   * ----------------------------------------------------------------------------
   */
  getBtnObject(idx){
    return this.btns[idx];
  }

  /**
   * ボタン表示（個別指定）
   * @param {number} index ボタン番号
   * ----------------------------------------------------------------------------
   */
  show(index){
    this.btns[index].element.show();
  }

  /**
   * ボタン非表示
   * @param {number} index ボタン番号 
   * ----------------------------------------------------------------------------
   */
  hide(index){
    this.btns[index].element.hide();
  }

  /**
   * 全ボタン表示
   * ----------------------------------------------------------------------------
   */
  showAll(){
    this.btns.forEach(btn => btn.element.show());
  }

  /**
   * 全ボタン非表示
   * ----------------------------------------------------------------------------
   */
  hideAll(){
    this.btns.forEach(btn => btn.element.hide());
  }
}