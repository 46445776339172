export default class {

  constructor(config) {
    this.config = config;
    this.firstaccessUrl = this.config.sidemenuSettings.firstaccessUrl;

    //ダイアログ背景作成
    var bk = $("<div id=\"firstAccessDlgBk\"></div>", {
      css: { "z-index": 4000 }
    }).appendTo("body");
    
    bk.load("./firstaccessdlg.html", function(response, status, xhr) {
      // 初回アクセスダイアログクローズボタン
      $("#firstAccessDlgClose").on("click", function (evnt) {
        $("#firstAccessDlgFrame").empty();
        $("#firstAccessDlgBk").css("display", "none");
      });
    });
  }

  set firstaccessHtml(h) {
    var tmp = h.replace(/html/g, "div");
    tmp = tmp.replace(/body/g, "hoge");
    this._firstaccesshtml = tmp;
    this._firstaccessBody = $(tmp).find("hoge").html();
  }

  get firstaccesHtml() {
    return this._firstaccesshtml;
  }

  /**
   * 初回アクセスダイアログ表示
   */
  show() {
    $("#firstAccessDlgBk").css("display", "block");

    //幅中心に持っていく
    var scrWidth = $(window).width();
    var dlgWidth = $("#firstAccessDlg").width();
    var xpos = (scrWidth - dlgWidth) / 2;
    $("#firstAccessDlg").css("left", xpos + "px");

    //高さ中心に持っていく
    var scrHeight = $(window).height();
    var dlgHeight = $("#firstAccessDlg").height();
    var ypos = (scrHeight - dlgHeight) / 2;
    $("#firstAccessDlg").css("top", ypos + "px");

    this.dispFirstAccess();
  }

  /**
   * 初回アクセス表示
   */
  dispFirstAccess() {
    if ($("#firstAccessFrame")[0] == undefined) {
      var firstAccessFrame = $("<div id=\"firstAccessFrame\"></div>");
      $(firstAccessFrame).appendTo("#firstAccessDlgContent").html(this._firstaccessBody);
    }
  }

  /**
   * 再描画処理
   * @param {Object} event 
   */
  redraw(event) {
    if ($("#firstAccessDlgBk").css("display") == "block") {
      setTimeout(function (inst) {
        inst.show();
      }, 200, this);
    }
  }
}