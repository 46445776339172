/**
 * メニューボタン表示切替ボタンクラス
 * ------------------------------------------------------------------------------
 * @LIS 20180411 新規作成
 * ==============================================================================
 */
export default class {

  /**
   * コンストラクタ
   * @param {function} clickFunc クリック時の処理 
   * ----------------------------------------------------------------------------
   */
  constructor(clickFunc) {
    this.mode = true;
    this.element = $("<div>▲</div>");
    this.element.attr("id", "menuDispBtn");
    this.element.addClass("menu-disp-btn-on");
    this.element.on("click", clickFunc);
    $("body").append(this.element);
  }

  /**
   * モード変更（表示・非表示）
   * ----------------------------------------------------------------------------
   */
  changeMode() {
    if (this.mode == true) {
      this.mode = false;
      this.element.html("▼アイコン表示");
      this.element.removeClass("menu-disp-btn-on");
      this.element.addClass("menu-disp-btn-off");
    } else {
      this.mode = true;
      this.element.html("▲");
      this.element.removeClass("menu-disp-btn-off");
      this.element.addClass("menu-disp-btn-on");
    }

    return this.mode;
  }
}