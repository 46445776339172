/**
 * ハイドログラフクラス
 * ------------------------------------------------------------------------------
 */
export default class {

  /**
   * コンストラクタ
   * @param {Object} opt 描画設定 
   * ----------------------------------------------------------------------------
   */
  constructor(opt) {
    //ソース内設定項目
    this.fontSize = 10; //フォントサイズ9ptのピクセル
    this.pointSize = 4; // 観測点の丸の大きさ

    //必須パラメータチェック
    //描画領域ID
    if (opt.htmlId == null) {
      throw "描画するタグのIDが指定されていません。";
    }
    this.htmlId = opt.htmlId;
    //$(this.htmlId).css("overflow-x", "scroll");
    //$(this.htmlId).css("overflow-y", "hidden");

    //処理ID（モード）
    if (opt.id == null) {
      throw "処理の種類（ID）が指定されていません。";
    }
    this.mode = opt.id;

    //ツールチップの表示文言
    this.tipValueStr = "堤防天端からの高さ"; //swin -- 堤防天端からの高さ //ph2 -- Levee crown
    this.missingStr = "欠測"; //swin -- 欠測//ph2 -- Data not available

    //任意パラメータチェック
    //エラー
    this.error = { flag: null, message: null, color: null };
    if (opt.error == null) {
      this.error.flag = false;
      this.error.message = "グラフの作成に失敗しました。";
      this.error.color = "rgb(255,0,0)";
    } else {
      this.error = opt.error;
    }
    var tipElm = $("<div id=\"valueTip\"></div>")
    tipElm.append("<div id=\"tipDate\"></div>");
    tipElm.append("<div id=\"tipValueLine\"></div>");
    tipElm.appendTo("body");
    //console.log("append body");
  }

  /**
   * グラフ描画処理
   * @param {Object} values 描画データ 
   * ----------------------------------------------------------------------------
   */
  draw(values) {
    //パラメータチェック
    if (this.mode === "swin") {
      this._checkValueSwin(values);
    }
    else if (this.mode == "ph2") {
      this._checkValuePh2(values);
    }
    if (this.error.flag == true) {
      this.dispError();
      return 0;
    }

    this.levels = values.obsValue;

    //描画領域の取得
    this.screenSettings = this._getScreenSize(this.htmlId);

    //描画単位などの計算
    this.paintSettings = this._calcPaintSetting();

    //両方の基本値が揃ったので補足
    //グラフ領域の幅
    this.screenSettings.graphPxWidth = this.paintSettings.oneValuePxWidth * (values.obsValue.length + 1);

    //画面クリア
    $(this.htmlId).html("");

    //CANVAS作成
    this._createCanvas();

    this._mvTimerId = 0;
    $(this.htmlId + "_graphBase").on("mousemove", { inst: this }, this._mouseMove);
    $(this.htmlId + "_graphBase").on("mouseout", { inst: this }, this._mouseOut);

  }
  dispError() {
    $(this.htmlId).html("");
    var elm = $("<canvas id=\"err\"></canvas>").appendTo(this.htmlId);
    elm.attr("width", $(this.htmlId).width());
    elm.attr("height", $(this.htmlId).height());
    elm.css("width", "100%");
    elm.css("height", "100%");

    setTimeout(function (el, err) {
      var w = el.width();
      var h = el.height();
      var ctx = el.getContext();
      ctx.set

      ctx.fillStyle = "rgb(255,192,192)";
      ctx.fillRect(0, 0, w, h);

      ctx.fillStyle = err.color;
      ctx.font = "bold 1.0em sans-serif";
      ctx.textAlign = "center";
      ctx.fillText(err.message, w / 2, h / 2);
    }, 700, elm, this.error);
  }

  /**
   * グラフクリア
   */
  clear() {
    $(this.htmlId).html("");
  }

  _mouseOut(evnt) {
    if (evnt.data.inst._mvTimerId != 0) {
      clearTimeout(evnt.data.inst._mvTimerId);
    }
    $("#valueTip").css("display", "none");
  }
  _mouseMove(evnt) {
    if (evnt.data.inst._mvTimerId != 0) {
      clearTimeout(evnt.data.inst._mvTimerId);
      evnt.data.inst._mvTimerId = 0;
    }
    evnt.data.inst._mvTimerId = setTimeout(evnt.data.inst._popupTip, 700, evnt.data.inst, evnt.clientX, evnt.clientY);
  }

  _popupTip(inst, x, y) {

    var fElm = $(inst.htmlId)[0];
    var gElm = $(this.htmlId + "_graphBase")[0];

    var rect = fElm.getBoundingClientRect();
    var zeroX = Math.floor(rect.left + inst.screenSettings.scalePxWidth);
    var scrollX = fElm.scrollLeft;
    var posX = x - zeroX + scrollX;
    var baseWidth = inst.paintSettings.oneValuePxWidth;
    var idx = Math.floor(posX / baseWidth);
    var val = inst.levels[idx];

    if (val != null) {
      //値クリア
      $("#tipValueLine").html("");

      // 観測値がnullでない場合
      if (val.elevation != null) {
        // 観測値の設定
        $("#tipValueLine").html(inst.tipValueStr);

        var dspVal = (val.elevation - inst.bank) * 100;
        dspVal = Math.round(dspVal) / 100;
        dspVal = dspVal.toFixed(2);
        $("#tipValueLine").append("<span id=\"tipValue\"></span>");
        $("#tipValue").html(dspVal);

      } else {
        //console.log("hydro.js--val:" + JSON.stringify(val));
        $("#tipValueLine").append("<span id=\"tipValue\"></span>");
        if (val.elevationWord)
          $("#tipValue").html(val.elevationWord);
        else
          $("#tipValue").html(inst.missingStr);
      }

      var ww = $("body").width();
      var w = Math.ceil($("#valueTip").width() / 2);
      if ((x + w) > ww) {
        w = w + ((x + w + 4) - ww);
      }
      $("#valueTip").css("display", "block");
      $("#tipDate").html(inst.levels[idx].date);
      $("#valueTip").css("top", (y + 2) + "px");
      $("#valueTip").css("left", (x - w) + "px");

    }

    inst._mvTimerId = 0;
  }

  /**
   * 描画データチェック（危機管理型用）
   * @param {Object} values 描画データ
   * ----------------------------------------------------------------------------
   */
  _checkValueSwin(values) {
    if (values.level == null || values.level.length == 0) {
      throw "水位の表示設定がありません。";
    }

    //水位の基準と色設定
    this.levelStyle = values.level[0];
    //基準値の設定
    this.baseLines = [];
    for (var ix = 1; ix < values.level.length; ix++) {
      this.baseLines.push(values.level[ix]);
    }
    if (this.baseLines.length < 2) {
      throw "基準値の設定が不足しています。（観測開始水位と氾濫開始水位は必須）"
    }

    //堤防高取得
    if (values.bank == null) {
      throw "堤防高の指定がありません。";
    }
    this.bank = values.bank;

    //観測値
    if (values.obsValue.length == 0) {
      this.error.flag = true;
      this.error.message = "表示する内容がありません。";
    }
  }

  /**
   * 描画データチェック（英語化Ph2用）
   * @param {Object} values 描画データ
   * ----------------------------------------------------------------------------
   */
  _checkValuePh2(values) {
    if (values.level == null || values.level.length == 0) {
      throw "水位の表示設定がありません。";
    }

    //水位の基準と色設定
    this.levelStyle = values.level[0];
    //基準値の設定
    this.baseLines = [];
    for (var ix = 1; ix < values.level.length; ix++) {
      this.baseLines.push(values.level[ix]);
    }

    //堤防高取得
    if (values.bank == null) {
      throw "堤防高の指定がありません。";
    }
    this.bank = values.bank;

    //河床高
    if (values.zeroHigh == null) {
      //throw "河床標高の指定がありません。";
      let smlVal = 99999;
      for (const lv of values.level) {
        const wk = lv.value - 0;
        if (typeof wk === "number" && !isNaN(wk)) {
          if (wk < smlVal) {
            smlVal = wk;
          }
        }
      }
      if (smlVal == 0 && values.obsValue.length != 0) {
        smlVal = values.bank - 1;
      } else if (smlVal == 0 && values.obsValue.length == 0) {
        smlVal = values.bank - 1;
      } else {
        smlVal = values.bank + (smlVal * 2);
      }

      values.obsValue.forEach(val => {
        if (smlVal > val.elevation && val.elevationWord == "通常") {
          //console.log("debug: elevation: " + (val.elevation - values.bank));
          if ((val.elevation - values.bank) > -30)
            smlVal = val.elevation;
        }
      });
      //console.log("riverZero: " + smlVal);
      this.riverZero = smlVal;
    } else {
      this.riverZero = values.zeroHigh;
    }

    //観測値
    if (values.obsValue.length == 0) {
      this.error.flag = true;
      this.error.message = "表示する内容がありません。";
    }
  }

  /**
   * 画面描画用の採寸
   * @param {String} htmlId 描画領域タグのID属性 
   * ----------------------------------------------------------------------------
   */
  _getScreenSize(htmlId) {
    var ret = {};
    var w1 = $(htmlId).innerWidth();
    var w2 = $(htmlId).width();
    var h1 = $(htmlId)[0].scrollHeight;
    var h2 = $(htmlId).height();
    //console.log("w1/h1, w2/h2", w1, h1, w2, h2);
    if (w1 > w2) {
      ret.maxWidth = w2;
    } else {
      ret.maxWidth = w1;
    }
    if (h1 > h2) {
      ret.maxHeight = h2;
    } else {
      ret.maxHeight = h1;
    }
    ret.graphPxHeight = ret.maxHeight - ((this.fontSize + 3) * 2) - (this.fontSize / 2); //上下マージン2、上下文字間2の想定(2,文字,1)(1,文字,2)
    ret.scalePxWidth = (this.fontSize / 2) * 8; //標高を表す8桁数字の幅
    ret.scalePxHeight = (ret.graphPxHeight / (this.fontSize * 4)) * 100;
    ret.scalePxHeight = Math.floor(ret.scalePxHeight) / 100;
    //グラフ背景の幅
    ret.backPxWidth = ret.maxWidth - ret.scalePxWidth;
    //console.log("ret:", ret);
    return ret;
  }

  /**
   * グラフ描画用の値を取得
   * ----------------------------------------------------------------------------
   */
  _calcPaintSetting() {

    var result = {};
    var baseHeight = 0;
    var totalHeight = 0;

    //描画底になる高さを取得
    if (this.mode == "swin") {
      var startLevel = 0;
      var fladLevel = 0;
      var tmpArray = this.baseLines.filter((line) => {
        return line.levelName == "start";
      });
      startLevel = tmpArray[0];
      tmpArray = [];
      var tmpArray = this.baseLines.filter((line) => {
        return line.levelName == "flad";
      });
      fladLevel = tmpArray[0];
      if (startLevel == null || fladLevel == null) {
        throw "必要な基準値が設定されていません。";
      }

      baseHeight = fladLevel.value - startLevel.value;

      // 天端高から最低観測値までの距離
      var elevations = [];
      var minData = null;
      this.levels.forEach(d => elevations.push(d.elevation));
      elevations.forEach(e => {
        if (minData === null || (minData > e && e != null)) {
          minData = e;
        }
      })
      var minEle = this.bank - minData;
      // 最低描画点（上限）-- (天端高 - 観測開始)*(1 + 0.2)
      var upperMinEle = baseHeight * 1.2
      // 最低描画点（下限）-- (天端高 - 観測開始)*4
      var lowerMinEle = baseHeight * 4

      // 最低描画点(targetMinEle)の算出
      // case 1: minEle <= 最低描画点（上限）
      // case 2: 最低描画点（上限） < minEle <= 最低描画点（下限）
      // case 3: 最低描画点（下限） < minEle

      var targetMinEle;
      if (minEle <= upperMinEle) {
        // case 1
        targetMinEle = upperMinEle;
      } else if (minEle <= lowerMinEle) {
        // case 2
        targetMinEle = minEle;
      } else {
        // case 3
        targetMinEle = lowerMinEle;
      }

      totalHeight = targetMinEle + 1;
    }
    else if (this.mode == "ph2") {
      baseHeight = this.bank - this.riverZero;
      totalHeight = baseHeight + 1;
    }
    result.baseHeight = baseHeight;
    result.totalHeight = totalHeight;
    result.cmPxHeight = ((this.screenSettings.graphPxHeight / totalHeight) / 100) * 100;
    result.cmPxHeight = Math.floor(result.cmPxHeight) / 100;
    result.topValue = 100;
    result.oneValuePxWidth = (this.fontSize * 3) + 12; //数字1文字の幅をフォントサイズの半分とみなして計算(12/31)　＋　左右に6pxの余白(12)

    return result;
  }

  /**
   * 各レイヤーキャンバス作成
   * ----------------------------------------------------------------------------
   */
  _createCanvas() {

    var framePosX = 0;
    var framePosY = 0;

    //フレームの絶対座標位置を取得
    var rect = $(this.htmlId)[0].getBoundingClientRect();
    framePosX = rect.left;
    framePosY = rect.top;

    //htmlIdの先頭の#を削除
    var htmlIdElm = this.htmlId.replace(/#/g, "");

    var backElm = $("<canvas id=\"" + htmlIdElm + "_graphBack\">");
    var baseElm = $("<canvas id=\"" + htmlIdElm + "_graphBase\">");
    var scaleElm = $("<canvas id=\"" + htmlIdElm + "_graphScale\">");

    backElm.attr("width", this.screenSettings.backPxWidth);
    backElm.attr("height", this.screenSettings.maxHeight - (this.fontSize / 2));
    backElm.css("position", "fixed");
    backElm.css("top", (framePosY + (this.fontSize / 2)) + "px");
    backElm.css("left", framePosX + this.screenSettings.scalePxWidth + "px");
    backElm.css("width", this.screenSettings.backPxWidth + "px");
    backElm.css("height", (this.screenSettings.maxHeight - (this.fontSize / 2)) + "px");

    var graphWidth = this.screenSettings.graphPxWidth + this.screenSettings.scalePxWidth;
    if (graphWidth < this.screenSettings.maxWidth) {
      graphWidth = this.screenSettings.maxWidth;
    }
    baseElm.attr("width", graphWidth);
    baseElm.attr("height", this.screenSettings.maxHeight - (this.fontSize / 2));
    baseElm.css("position", "absolute");
    baseElm.css("top", (this.fontSize / 2) + "px");
    baseElm.css("left", "0px");
    baseElm.css("width", graphWidth + "px");
    baseElm.css("height", (this.screenSettings.maxHeight - (this.fontSize / 2)) + "px");

    scaleElm.attr("width", this.screenSettings.scalePxWidth);
    scaleElm.attr("height", this.screenSettings.maxHeight);
    scaleElm.css("position", "fixed");
    scaleElm.css("top", framePosY + "px");
    scaleElm.css("left", framePosX + "px");
    scaleElm.css("width", this.screenSettings.scalePxWidth + "px");
    scaleElm.css("height", this.screenSettings.maxHeight + "px");

    $(this.htmlId).html("");
    $(this.htmlId).append(backElm);
    $(this.htmlId).append(baseElm);
    $(this.htmlId).append(scaleElm);

    //描画用のコンテキスト作成
    var backContext = backElm.getContext();
    var baseContext = baseElm.getContext();
    var scaleContext = scaleElm.getContext();

    //画像解像度調整
    //this.ratio = window.devicePixelRatio;
    this.ratio = 1;
    /*
    var ratioBase = 0.5;
    backContext.scale(ratioBase, ratioBase);
    baseContext.scale(ratioBase, ratioBase);
    scaleContext.scale(ratioBase, ratioBase);
    */
    //this.ratio = 1;

    //背景描画
    this._paintBack(backContext);

    //Y軸メモリ描画
    this._paintScale(scaleContext);

    //グラフ描画
    this._paintGraph(baseContext);

    setTimeout(this._animation, 10, $(this.htmlId), 20, this._animation);
  }

  /**
   * アニメーション実行
   * @param {Object} elm 
   * @param {number} point 
   * @param {Function} func 
   * ----------------------------------------------------------------------------
   */
  _animation(elm, point, func) {
    elm.setWidthScrollPos(point);
    if (point <= 100) {
      setTimeout(func, 10, elm, point + 1, func);
    }
  }

  /**
   * 背景レイヤー描画
   * @param {Object} ctx Canvasコンテキスト 
   * ----------------------------------------------------------------------------
   */
  _paintBack(ctx) {

    var ratio = this.ratio;
    var x = 0;
    var y = 0;
    var h = 0;
    var w = (this.screenSettings.backPxWidth * ratio);
    var cm = this.paintSettings.cmPxHeight;
    var f = this.fontSize;

    this.baseLines.forEach(line => {
      //背景カラー・基準線
      if (line.levelName != "obsLevel") {
        //背景カラー
        y = (((line.value * - 1) + 1) - line.height) * 100 * ratio * cm;
        h = line.height * 100 * ratio * cm;
        ctx.fillStyle = line.background;
        //console.log("背景ボックス name x y w h", line.levelName, x, y, w, h);
        ctx.fillRect(x, y, w, h);
      }
    });

    var hScale = this._getHeightScale(this.fontSize, cm);
    var hScalePx = hScale * cm;
    var startH = 100;
    var fullH = this.paintSettings.totalHeight * cm * 100;

    ctx.setLineDash([1, 2]);
    ctx.strokeStyle = "rgb(128, 128, 128)";
    ctx.lineWidth = 0.25 * ratio;
    for (var ix = 0; ix < (fullH / cm); ix += hScale) {
      ctx.beginPath();
      ctx.moveTo(0, ix * cm);
      ctx.lineTo(w, ix * cm);
      ctx.stroke();
    }

    this.baseLines.forEach(line => {
      //背景カラー・基準線
      if (line.levelName != "obsLevel") {
        //基準線
        ctx.setLineDash([]);
        ctx.strokeStyle = line.color;
        ctx.lineWidth = 1 * ratio;
        ctx.beginPath();
        ctx.moveTo(0, ((line.value * -1) + 1) * 100 * ratio * cm);
        ctx.lineTo(w, ((line.value * -1) + 1) * 100 * ratio * cm);
        ctx.stroke();
        //ガイド文字
        ctx.fillStyle = line.color;
        ctx.font = (f + (4 * ratio)) + "px sans-serif";
        ctx.textAlign = "right";
        //console.log("背景ボックス文字 lavel x y org cm", line.label, w - 2, ((line.value * - 1) + 1) * 100 * ratio * cm, line.value, cm);
        ctx.fillText(line.label, w - 2, ((line.value * - 1) + 1) * 100 * ratio * cm);
      }
    });

  }

  /**
   * Y軸目盛りレイヤー描画
   * @param {Object} ctx キャンバスコンテキスト 
   * ----------------------------------------------------------------------------
   */
  _paintScale(ctx) {

    var cm = this.paintSettings.cmPxHeight * this.ratio;
    var w = this.screenSettings.scalePxWidth * this.ratio;
    var w1 = (this.screenSettings.scalePxWidth - 1) * this.ratio;
    var h = this.paintSettings.totalHeight * cm * 100 * this.ratio;
    var f = this.fontSize * this.ratio;

    ctx.fillStyle = "rgb(255,255,255)";
    ctx.fillRect(0, 0, w, this.screenSettings.maxHeight);

    ctx.strokeStyle = "rgb(0,0,0)";
    ctx.lineWidth = 2 * this.ratio;
    ctx.beginPath();
    ctx.moveTo(w1, this.fontSize / 2);
    ctx.lineTo(w1, h);
    ctx.stroke();

    var hScale = this._getHeightScale(this.fontSize, cm);
    var hScalePx = hScale * cm;
    var startH = 100;

    for (var ix = 0; ix < (h / cm); ix += hScale) {

      var mh = startH / 100;

      ctx.fillStyle = "rgb(0,0,0)";
      ctx.font = f + "px sans-serif";
      ctx.textAlign = "right";
      ctx.fillText(mh + "m", w - 4, (ix * cm) + f);

      startH -= hScale;
    }

  }

  /**
   * Y軸メモリ幅判定
   * @param {number} f フォントサイズ 
   * @param {number} cm cmあたりの描画ピクセル数 
   * ----------------------------------------------------------------------------
   */
  _getHeightScale(f, cm) {
    if ((cm * 100) / f > 4) {
      return 100;
    } else {
      return 200;
    }
  }


  /**
   * グラフレイヤー描画
   * @param {Object} ctx キャンバスコンテキスト 
   * ----------------------------------------------------------------------------
   */
  _paintGraph(ctx) {

    var ratio = this.ratio;
    var cm = this.paintSettings.cmPxHeight * this.ratio;
    var w0 = this.screenSettings.scalePxWidth * this.ratio;
    var one = this.paintSettings.oneValuePxWidth * this.ratio;
    var count = 0;
    var h = this.paintSettings.totalHeight * 100 * cm * this.ratio;
    var dt = "";
    var f = this.fontSize * this.ratio;

    ctx.fillStyle = "rgb(0,0,0)";
    ctx.font = f + "px sans-serif"

    this.levels.forEach(level => {
      //縦線
      var x = w0 + (one * count);
      ctx.setLineDash([1, 2]);
      ctx.strokeStyle = "rgb(128, 128, 128)";
      ctx.lineWidth = 0.25 * ratio;
      ctx.beginPath();
      ctx.moveTo(x + (one / 2), 0);
      ctx.lineTo(x + (one / 2), h);
      ctx.stroke();
      count++;

      //X軸観測日時
      var d = level.date.split(" ");
      var wk = d[0].split("/");
      var date = wk[1] + "/" + wk[2];
      var time = d[1];
      if (dt != date) {
        dt = date;
        ctx.textAlign = "center";
        ctx.fillText(dt, x + (one / 2), h + f + 2);
      }
      ctx.fillText(time, x + (one / 2), h + (f * 2) + 4);
    });

    //観測値の線を引く
    for (var idx in this.levels) {
      var level = this.levels[idx - 0];
      var nextLevel = this.levels[idx - 0 + 1];

      var val1 = 0;
      var val2 = 0;
      var col1 = null;
      var col2 = null;
      if (level.elevation != null && nextLevel != null && nextLevel.elevation != null) {
        val1 = (level.elevation - this.bank) * -1;
        val2 = (nextLevel.elevation - this.bank) * -1;
        col1 = this._getOverColor(val1);
        col2 = this._getOverColor(val2);
        var x1 = w0 + (one * (idx - 0)) + (one / 2);
        var y1 = (val1 + 1) * 100 * cm;
        var x2 = w0 + (one * ((idx - 0) + 1)) + (one / 2);
        var y2 = (val2 + 1) * 100 * cm;
        if (col1 == null || col2 == null) {
          continue;
        }

        ctx.setLineDash([4, 3]);
        ctx.strokeStyle = col2.color;
        ctx.lineWidth = 2 * ratio;
        ctx.beginPath();
        ctx.moveTo(x1, y1);
        ctx.lineTo(x2, y2);
        ctx.stroke();
      }
    }
    //観測値の丸を書く
    for (var idx in this.levels) {
      var level = this.levels[idx - 0];

      var val1 = 0;
      var col1 = null;
      if (level.elevation != null) {
        val1 = (level.elevation - this.bank) * -1;
        col1 = this._getOverColor(val1);
        var x1 = w0 + (one * (idx - 0)) + (one / 2);
        var y1 = (val1 + 1) * 100 * cm;
        if (col1 == null) {
          col1 = {};
          col1.color = "rgba(96,96,256,0.7)"
        }

        ctx.setLineDash([]);
        ctx.strokeStyle = col1.color;
        ctx.fillStyle = "rgb(255,255,255)";
        ctx.lineWidth = 3 * ratio;
        ctx.beginPath();
        ctx.arc(x1, y1, (6 * ratio), 0, Math.PI * 2, false);
        ctx.fill();
        ctx.stroke();
      }
    }

  }

  /**
   * 水位による色設定取得
   * @param {number} v 堤防からの水位 
   * ----------------------------------------------------------------------------
   */
  _getOverColor(v) {
    for (var key in this.baseLines) {
      var idx = key - 0;
      var baseLine = this.baseLines[idx];
      var val = v * -1;
      if (val > 0) {
        return this.baseLines[0];
      }
      var min = baseLine.value;
      var max = baseLine.value + baseLine.height;
      if (val >= min && val < max) {
        return baseLine;
      }
    }
    return null;
  }
}

