/**
 * メニューボタンクラス
 * ------------------------------------------------------------------------------
 * @LIS 20180406 新規作成
 * ==============================================================================
 */
export class MenuBtn {

  /**
   * コンストラクタ
   * @param {string} icon 表示ICONのURL 
   * @return 作成したDOM Element
   * ----------------------------------------------------------------------------
   */
  constructor(icon) {
    this._element = $("<img />", {
      src: icon,
      "class": "menu-btn"
    });

    //return this.element;
  }

  get element(){
    return this._element;
  }

  set element(elm){
    this._element = elm;
  }
}

export class MenuToggle {
  /**
   * コンストラクタ
   * @param {string} icon 表示ICONのURL 
   * @return 作成したDOM Element
   * ----------------------------------------------------------------------------
   */
  constructor(icons) {
    this._element = $("<img />", {
      src: icons[0],
      "class": "menu-btn"
    });

    this._element.setExtVal("onIcon", icons[1]);
    this._element.setExtVal("offIcon", icons[0]);
    this._element.setExtVal("toggle", false);
    this._element.on("click", { instance: this._element }, this.click);
    //return this.element;
  }

  /**
   * 共通クリック処理
   * @param {Object} evnt 
   * ----------------------------------------------------------------------------
   */
  click(evnt) {
    var elm = evnt.data.instance
    var toggle = elm.getExtVal("toggle");
    if (toggle) {
      elm.attr("src", elm.getExtVal("offIcon"));
      elm.setExtVal("toggle", false);
    } else {
      elm.attr("src", elm.getExtVal("onIcon"));
      elm.setExtVal("toggle", true);
    }
  }

  /**
   * ボタン状態変更
   * @param {boolean} onoff ture: on false: off 
   */
  setStatus(onoff){
    if (onoff == true){
      this._element.attr("src", this._element.getExtVal("onIcon"));
      this._element.setExtVal("toggle", true);
    } else {
      this._element.attr("src", this._element.getExtVal("offIcon"));
      this._element.setExtVal("toggle", false);
    }
  }

  get element(){
    return this._element;
  }

  set element(elm){
    this._element = elm;
  }
}