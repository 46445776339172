import {
	_CNST
} from "./const.js";

// 取得したファイルのキャッシュ置き場
const fileCacheMap = new Map();

/**
 * 位置情報ファイル取得用のレスポンス整形処理
 * ------------------------------------------------------------------------------
 * @LIS 20200306 新規作成
 * ==============================================================================
 */
export default class {

	/**
	 * コンストラクタ
	 * @constructor
	 * ----------------------------------------------------------------------------
	 */
	constructor() {}

	/**
	 * リクエスト個別送信処理（GET）
	 * @param {Object} req リクエストデータ
	 * @param {Array<Object>} resultJsons 取得ファイルデータ一覧
	 * @param {String} polUrl ポリゴン情報ファイルURL
	 * @return 整形済レスポンスデータ
	 * ----------------------------------------------------------------------------
	 */
	async format(req, resultJsons, polUrl) {
		// 返却用レスポンスデータ
		let results = {};
		// 表示範囲内の都道府県（市町村）リスト
		let positionMap = new Map();
		// 表示範囲の中心点を含む都道府県（市町村）リスト
		let centerList = new Array();
		// 中心点の都道府県（市町村）コード
		var centerCode = "";
		// 表示範囲の中心点経度
		const centerLon = (req.params.min.lon + req.params.max.lon) / 2;
		// 表示範囲の中心点緯度
		const centerLat = (req.params.min.lat + req.params.max.lat) / 2;

		// 取得ファイル情報から、表示範囲内のデータをリスト化
		let indexes = new Array();
		for (const json of resultJsons) {
			if (req.params.level === "town") {
				if (json.towns) {
					indexes = json.towns;
				}
			} else {
				if (json.prefs) {
					indexes = json.prefs;
				}
			}
		}

		for (const index of indexes) {
			let pushFlg = true;

			// 都道府県が表示範囲外であるかチェック
			if (index.maxLon < req.params.min.lon) {
				pushFlg = false;
			} else if (index.maxLat < req.params.min.lat) {
				pushFlg = false;
			} else if (index.minLon > req.params.max.lon) {
				pushFlg = false;
			} else if (index.minLat > req.params.max.lat) {
				pushFlg = false;
			}

			// データをリストに追加
			if (pushFlg) {
				if (req.params.level === "town") {
					const position = {
							"code": index.code,
                            "mlitCode": index.mlitCode,
							"name": index.name
					};
					positionMap.set(index.code, position);
				} else {
					const position = {
							"code": index.code,
							"name": index.name
					};
					positionMap.set(index.code, position);
				}

				// 表示範囲の中心点を含む都道府県をリストアップする
				if ((centerLon >= index.minLon && centerLon <= index.maxLon) &&
						(centerLat >= index.minLat && centerLat <= index.maxLat)) {
					centerList.push(index.code);
				}
			}
		}

		// 中心の都道府県（市町村）を決定する
		if (centerList.length == 1) {
			centerCode = centerList[0];
		} else if (centerList.length > 1) {
			// 中心点を含む都道府県（市町村）が複数の場合、ポリゴン情報を取得する
			const polJsons = await this.sendFileGet(req, centerList, polUrl);

			// 取得したポリゴン情報に中心点が含まれるかチェックする。
			// CrossingNumberAlgorithm法で中心点がポリゴン領域内か判定する。
			grid_loop:
			for (var [key, value] of polJsons) {
				for (const polygons of value.polygons) {
					// 中心点から右方向にポリゴンの辺を奇数回通過する場合、ポリゴン領域内とする。
					var count = 0;
					for (var i = 0; i < polygons.coordinates.length - 1; i++) {
						const c1 = polygons.coordinates[i];
						const c2 = polygons.coordinates[i + 1];

						if (((c1.lat <= centerLat) && (c2.lat > centerLat))
								|| ((c1.lat > centerLat) && (c2.lat <= centerLat))) {
							const vt = (centerLat - c1.lat) / (c2.lat - c1.lat);
							if (centerLon < (c1.lon + (vt * (c2.lon - c1.lon)))) {
								count++;
							}
						}
					}

					// 中心点がポリゴン領域内だった場合、中心の都道府県（市町村）を決定してループ終了
					if (count % 2 == 1) {
						centerCode = key;
						break grid_loop;
					}
				}
			}
		}

		let positionList = new Array();
		let centerFlag = false

		// 中心の都道府県（市町村）を返却用レスポンスデータの先頭にする。
		if (centerCode.length > 0) {
			positionList.push(positionMap.get(centerCode));
			positionMap.delete(centerCode);
			centerFlag = true;
		}

		// 残りの取得情報を返却用レスポンスデータに設定する。
		for (var [key, value] of positionMap) {
			positionList.push(value);
		}

		if (req.params.level === "town") {
			results["towns"] = positionList;
		} else {
			results["prefs"] = positionList;
		}

		// 中心の都道府県（市町村）の特定フラグを設定。
		results["centerFlag"] = centerFlag;

		return results;
	}

	/**
	 * リクエスト個別送信処理（ポリゴン情報ファイル取得）
	 * @param {Object} req リクエストデータ
	 * @param {Array<String>} centerList 中心点を含む都道府県（市町村）コード一覧
	 * @param {String} polUrl ポリゴン情報ファイルURL
	 * @return APIのresultデータ（コード値をkeyとするMap形式で返す）
	 * ----------------------------------------------------------------------------
	 */
	async sendFileGet(req, centerList, polUrl) {

		let promises = new Array();
		const polJsons = new Map();

		for (var code of centerList) {
			const url = polUrl.replace("%", code);

			if (fileCacheMap.has(url)) {
				// 取得済みのファイルはキャッシュ時刻を確認
				const file = fileCacheMap.get(url)
				const cacheTime = _CNST.FILE_GET_API.get("getPosition").cacheTime;
				const nowTime = new Date();

				if((nowTime.getTime() - file.getTime.getTime()) <= cacheTime) {
					// キャッシュ時間内のキャッシュデータが存在する場合、取得する
					if (file.json != null) {
						polJsons.set(code, file["json"]);
					}
					continue;
				}
			} else {
				const p = $.ajax({
					url: url,
					dataType: "json",
					scriptCharset: "utf-8",
					beforeSend: function (jqXHR, settings) {
						jqXHR.requestURL = settings.url;
						jqXHR.code = code;
					}
				}).then((json, textResponse, jqXHR) => {
					// 結果リストに保管
					polJsons.set(jqXHR.code, json);

					// 取得データと取得時刻をキャッシュに保管
					fileCacheMap.set(url, {"json": json, "getTime": new Date()});
				}).catch(e => {
					// ファイル取得できなくても、無視する
					console.log("sendFileGet Error:" + e);

					if (e.status == 404) {
						// 404エラーの場合、空ファイルをキャッシュにセット
						fileCacheMap.set(url, {"json": null, "getTime": new Date()});
					}
				});
				promises.push(p);
			}
		}
		// 処理終了待ち
		await Promise.all(promises);

		return polJsons;
	}
}