/**
 * ズームボタンクラス
 * ------------------------------------------------------------------------------
 * @LIS 20180411 新規作成
 * ==============================================================================
 */
export default class {

  /**
   * コンストラクタ
   * @param {string} icon 表示ICONのURL 
   * @return 作成したDOM Element
   * ----------------------------------------------------------------------------
   */
  constructor(icon) {
    this.element = $("<img />", {
      src: icon,
      "class": "zoom-btn"
    });

    return this.element;
  }
}
