import {
	_CNST
} from "./const.js";
import {
	FileUrlMaker
} from "./fileurlmaker.js";
import {
	FileResponseFormatter
} from "./fileresponseformatter.js";
import {
	GetDataMaker
} from "./getdatamaker.js";

// ファイル取得URL作成オブジェクト
const fileUrlMaker = new FileUrlMaker();
// ファイル取得URL作成オブジェクト
const fileResponseFormatter = new FileResponseFormatter();
// GET送信データ作成オブジェクト
const getDataMaker = new GetDataMaker();
// 取得したファイルのキャッシュ置き場
const fileCacheMap = new Map();


/**
 * Class Requester
 * Ajaxリクエスト親クラス
 * ------------------------------------------------------------------------------
 * @LIS 20180311 新規作成
 * ==============================================================================
 */
export class Requester {
	/**
	 * コンストラクタ
	 * @constructor
	 * @param {string} _url RiverAPIのリクエストURL
	 * @return {Requester} Requesterクラスのインスタンス
   * ----------------------------------------------------------------------------
	 */
	constructor(_url) {
		this.url = _url;
		this.method = 'POST';
		this._debug = false;
		this._auth = {};
	}

	/**
	 * デバッグモードの有効化.
	 * ・アクセス先のURLを上書きしRequesterで取得するJSONをモックファイルに置き換える
	 * @param {string} baseUrl モックjsonファイルの置かれているURL
   * ----------------------------------------------------------------------------
	 */
	debug(baseUrl) {
		this.url = baseUrl;
		this.method = 'GET';
		this._debug = true;
	}

	auth(user, passwd) {
		this._auth = {
			user: user,
			passwd: passwd
		};
	}

	/**
	 * リクエストインスタンスの作成.
	 * @return {RequesterRequest} RequesterRequestインスタンス
   * ----------------------------------------------------------------------------
	 */
	create() {
		return new RequesterRequest(this.url, this.method, this._auth, this._debug);
	}
}

/**
 * Class RequesterRequest
 * Ajaxリクエスト作成クラス
 * ------------------------------------------------------------------------------
 */
export class RequesterRequest {
	/**
	 * コンストラクタ
	 * @constructor
	 * @param {string} url RiverAPIリクストURL
	 * @param {function} method 'POST' or 'GET'
	 * @param {Object} auth 認証情報
	 * @param {boolean} _debug デバッグフラグ
	 * ----------------------------------------------------------------------------
	 */
	constructor(url, method, auth, _debug = false) {
		this.requests = new Array();
		this.methods = new Array();
		this.url = url;
		this.method = method;
		this._debug = _debug;
		this._errorMethod = null;
		this.auth = auth;
		// 仮実装
		this.resultsForGet = new Array();
	}

	/**
	 * リクエストエラーメソッド登録
	 * @param {function} method エラーメソッド
	 * ----------------------------------------------------------------------------
	 */
	static set errMethod(method) {
		this.errMethod = method;
	}

	/**
	 * リクエストエラーメソッド取得
	 * @return {function} エラーメソッド
	 * ----------------------------------------------------------------------------
	 */
	static get errMethod() {
		return this.errMethod;
	}

	/**
	 * リクエスト情報追加
	 * @param {string} requestId リクエストサービスID
	 * @param {Object} p リクエストパラメータ（各サービスに依存）
	 * @param {function} proc 成功時の呼び出しメソッド
	 * @param {string} tag リクエストタグ
	 * ----------------------------------------------------------------------------
	 */
	register(requestId, p = {}, proc, t) {
		var tag = "";
		//必ず識別子を持たせる
		if (t == null) {
			tag = requestId + "_" + this.createRandomStr();
		} else {
			tag = t;
		}

		let req = {
			apiId: requestId,
			params: p,
			tag: tag,
			macro: null
		};
		this.methods[tag] = proc;
		this.requests.push(req);
	}

	/**
	 * 直後呼び出しマクロリクエスト追加
	 * @param {string} requestId リクエストAPI ID
	 * @param {Object} p パラメータ
	 * @param {function} proc 成功時呼び出し関数
	 * @param {string} targetTag マクロ動作対象タグ
	 * @param {string} myTag リクエストタグ
	 * ----------------------------------------------------------------------------
	 */
	registerAfterMacro(requestId, p = {}, proc, targetTag, myTag) {
		var tag = "";
		//必ず識別子を持たせる
		if (myTag == null) {
			tag = requestId + "_" + this.createRandomStr();
		} else {
			tag = myTag;
		}

		let req = {
			apiId: requestId,
			params: p,
			tag: tag,
			macro: "After:" + targetTag
		};
		this.methods[tag] = proc;
		this.requests.push(req);
	}

	/**
	 * パラメータ引き継ぎマクロリクエスト追加
	 * @param {string} requestId リクエストAPI ID
	 * @param {function} proc 成功時呼び出し関数
	 * @param {string} targetTag マクロ動作対象タグ
	 * @param {string} myTag リクエストタグ
	 * ----------------------------------------------------------------------------
	 */
	registerParamMacro(requestId, proc, targetTag, myTag) {
		var tag = "";
		//必ず識別子を持たせる
		if (myTag == null) {
			tag = requestId + "_" + this.createRandomStr();
		} else {
			tag = myTag;
		}

		let req = {
			apiId: requestId,
			params: null,
			tag: tag,
			macro: "Param:" + targetTag
		};
		this.methods[tag] = proc;
		this.requests.push(req);
	}

	/**
	 * 編集後リクストJSON作成
	 * ----------------------------------------------------------------------------
	 */
	get requestData() {
		let data = {
			auth: this.auth,
			requests: this.requests
		};
		return data;
	}

	/**
	 * 個別リクエスト送信処理
	 * @param {*} ct コンテキスト（コールバックのthisになるデータ）
	 * ----------------------------------------------------------------------------
	 */
	async sendSeparate(ct) {

		// メインとサブを分けて保管
		let mainReqArray = new Array();
		let subReqMap = new Map();
		this.requests.forEach(req => {
			if (req.macro == null) {
				// メインリクエスト
				mainReqArray.push(req);
			} else {
				// サブリクエスト
				let key = this.getRequestKey(req);
				if (!subReqMap.has(key)) {
					let subReqArray = new Array();
					subReqArray.push(req);
					subReqMap.set(key, subReqArray);
				} else {
					subReqMap.get(key).push(req);
				}
			}
		});


		let array = new Array();
		for (let mainReq of mainReqArray) {

			let p;
			if (subReqMap.has(mainReq.tag)) {
				p = this.sendSubAfterMain(ct, mainReq, subReqMap.get(mainReq.tag));
			} else {
				p = this.sendSubAfterMain(ct, mainReq);
			}
			array.push(p);
		}
		await Promise.all(array);

		//登録してあったメソッドを呼び出す。
		for (let iy in this.resultsForGet) {
			let t = this.resultsForGet[iy].tag;
			let m = this.methods[t];
			m(this.resultsForGet[iy].result);
		}
	}

	/**
	 * 個別リクエスト送信処理
	 * メインリクエストと付随するサブリクエストの送信処理を行う
	 * @param {*} ct コンテキスト（コールバックのthisになるデータ）
	 * @param {Object} mainReq メインリクエストデータ
	 * @param {Object} subRequests サブリクエストの配列
	 * ----------------------------------------------------------------------------
	 */
	async sendSubAfterMain(ct, mainReq, subRequests) {

		let mainResult;
		if (_CNST.FILE_GET_API.has(mainReq.apiId)) {
			// JSONファイルの取得
			mainResult = await this.sendFileGet(ct, mainReq);
		} else if (_CNST.GET_API.includes(mainReq.apiId)) {
			// APIへGET問い合わせ
			mainResult = await this.sendApiGet(ct, mainReq);
		} else {
			// APIへPOST問い合わせ
			mainResult = await this.sendApiPost(ct, mainReq);
		}

		let promiseArray = new Array();
		if (subRequests) {
			// サブリクエストは非同期で並列実行する
			for (let subReq of subRequests) {
				subReq.params = mainResult;
				let p;
				if (_CNST.FILE_GET_API.has(subReq.apiId)) {
					// JSONファイルの取得
					p = this.sendFileGet(ct, subReq);
				} else if (_CNST.GET_API.includes(subReq.apiId)) {
					// APIへGET問い合わせ
					p = this.sendApiGet(ct, subReq);
				} else {
					// APIへPOST問い合わせ
					p = this.sendApiPost(ct, subReq);
				}
				promiseArray.push(p);
			}
			// 各処理終了待ち
			await Promise.all(promiseArray);
		}
	}
	/**
	 * リクエスト個別送信処理（ファイル取得）
	 * @param {*} ct コンテキスト（コールバックのthisになるデータ）
	 * @param {Object} req リクエストデータ
	 * @return APIのresultデータ
	 * ----------------------------------------------------------------------------
	 */
	async sendFileGet(ct, req) {

		// ファイルURLを取得する
		fileUrlMaker.registRequest(req);
		let urls = fileUrlMaker.getUrls();
		let resultsJson = new Array();

		let promises = new Array();
		const cacheFlg = _CNST.FILE_GET_API.get(req.apiId).cache;
		for(let url of urls) {
			if (cacheFlg && fileCacheMap.has(url)) {
				// 取得済みのファイルはキャッシュ時刻を確認
				const file = fileCacheMap.get(url)
				const cacheTime = _CNST.FILE_GET_API.get(req.apiId).cacheTime;
				const nowTime = new Date();

				if((nowTime.getTime() - file.getTime.getTime()) <= cacheTime) {
					// キャッシュ時間内のキャッシュデータが存在する場合、取得する
					if (file.json != null) {
					  resultsJson.push(file.json);
					}
					continue;
				}
			}

			const p = $.ajax({
				url: url,
				dataType: "json",
				scriptCharset: "utf-8",
				context: ct,
				beforeSend: function (jqXHR, settings) {
					jqXHR.requestURL = settings.url;
				}
			}).then((json, textResponse, jqXHR) => {
				// 結果リストに保管
				resultsJson.push(json);

				if (cacheFlg) {
					// 取得データと取得時刻をキャッシュに保管
					fileCacheMap.set(url, {"json": json, "getTime": new Date()});
				}
			}).catch(e => {
				// ファイル取得できなくても、無視する
				//console.log("sendFileGet Error:", url);

				if (cacheFlg && e.status == 404) {
					// 404エラーの場合、空ファイルをキャッシュにセット
					fileCacheMap.set(url, {"json": null, "getTime": new Date()});
				}
			});
			promises.push(p);
		}
		// 処理終了待ち
		await Promise.all(promises);

		// 結果を整形
		let responseData = await fileResponseFormatter.format(req, resultsJson);
		// 返却データを格納
		this.resultsForGet.push(responseData);

		return responseData.result;
	}

	/**
	 * リクエスト個別送信処理（GET）
	 * @param {*} ct コンテキスト（コールバックのthisになるデータ）
	 * @param {Object} req リクエストデータ
	 * @return APIのresultデータ
	 * ----------------------------------------------------------------------------
	 */
	async sendApiGet(ct, req) {

		// 送信データ整形
		getDataMaker.registRequest(req);
		let sendData = getDataMaker.getData();
		sendData = this.getSeparateSendData(sendData);

		let result;
		await $.ajax({
			type: "GET",
			url: this.url,
			data: {
				query : JSON.stringify(sendData)
			},
			dataType: "json",
			scriptCharset: "utf-8",
			context: ct
		}).done((data) => {
			result = data.results[0].result;
			this.procSuccessForSeparate(data);
		}).fail(this.procErrorForSeparate);

		return result;
	}

	/**
	 * リクエスト個別送信処理（POST）
	 * @param {*} ct コンテキスト（コールバックのthisになるデータ）
	 * @param {Object} req リクエストデータ
	 * @return APIのresultデータ
	 * ----------------------------------------------------------------------------
	 */
	async sendApiPost(ct, req) {

		// 送信データ取得
		let sendData  = this.getSeparateSendData(req);
		let result;
		await $.ajax({
			type: "POST",
			url: this.url,
			data: JSON.stringify(sendData),
			contentType: "application/json",
			dataType: "json",
			cache: true,
			scriptCharset: "utf-8",
			context: ct
		}).done((data) => {
			result = data.results[0].result;
			this.procSuccessForSeparate(data);
		}).fail(this.procErrorForSeparate);

		return result;
	}

	/**
	 * 通信成功時処理
	 * @param {Object} data api-coreからの戻り値
	 * @param {number} status
	 * @param {Object} jqXHR
	 * ----------------------------------------------------------------------------
	 */
	procSuccessForSeparate(data) {
		//api-coreでエラーを拾った場合
		if (data.status != 0) {
			//エラー関数が登録されていればそれを呼び出す。
			if (this.errMethod != null) {
				this.errMethod();
				return;
			}
			throw new Error("RequestError");
		}
		this.resultsForGet.push(data.results[0]);
	}

	/**
	 * 通信エラー時
	 * @param {Object} jqXHR
	 * @param {number} status
	 * @param {Object} errorThrown
	 * ----------------------------------------------------------------------------
	 */
	procErrorForSeparate(jqXHR, status, errorThrown) {
		//エラー関数が登録されていればそれを呼び出す。
		if (this.errMethod != null) {
			this.errMethod();
			return;
		}
		throw new Error("HttpError");
	}

	/**
	 * 個別送信リクエストデータ作成処理
	 * @param {Object} req リクエストデータ
	 * ----------------------------------------------------------------------------
	 */
	getSeparateSendData(req) {

		// 個別送信はメインリクエストとして扱うためmacroをnullにする
		req.macro = null;

		let requests = new Array();
		requests.push(req);
		let sendData  = {
			auth: this.auth,
			requests: requests
		};

		return sendData;
	}

	/**
	 * リクエストデータからmacroのキーを取り出す処理
	 * @param {Object} req リクエストデータ
	 * ----------------------------------------------------------------------------
	 */
	getRequestKey(req) {

		if (req.macro == null) {
			return null;
		} else {
			if (req.macro.startsWith("Param:")) {
				return req.macro.replace("Param:","");
			} else if (req.macro.startsWith("After:")) {
				return req.macro.replace("After:","");
			}
		}
	}

	/**
	 * リクエスト送信処理
	 * @param {*} ct コンテキスト（コールバックのthisになるデータ）
	 * ----------------------------------------------------------------------------
	 */
	async submit(ct) {
		let url = this._debug
			? this.url + this.requests.map(req => { return req.apiId; }).join('') + '.json'
			: this.url;

		if (ct == null) {
			ct = this;
		} else {
			ct.errMethod = this.errMethod;
			ct.methods = this.methods;
		}

		let separateFlg = false;
		this.requests.forEach(req => {
			if (_CNST.FILE_GET_API.has(req.apiId)) {
				// ファイル取得APIかGETAPIが含まれる場合はGET送信フラグON
				separateFlg = true;
			} else if (_CNST.GET_API.includes(req.apiId)) {
				// GETAPIが含まれる場合はGET送信フラグON
				separateFlg = true;
			}
		});
		if (separateFlg) {
			//GETが混じっているため個別送信
			await this.sendSeparate(ct);
		} else {
			// POSTのみのためまとめて送信
			let __jqXHR__ = await $.ajax({
				type: this.method,
				url: url,
				data: JSON.stringify(this.requestData),
				contentType: "application/json",
				dataType: "json",
				cache: true,
				scriptCharset: "utf-8",
				context: ct
			}).done(this.procSuccess)
				.fail(this.procError);

		}
		this.requests.splice(0, this.requests.length);
	}

	/**
	 * 通信成功時処理
	 * @param {Object} data api-coreからの戻り値
	 * @param {number} status
	 * @param {Object} jqXHR
	 * ----------------------------------------------------------------------------
	 */
	procSuccess(data, status, jqXHR) {

		//api-coreでエラーを拾った場合
		if (data.status != 0) {
			//エラー関数が登録されていればそれを呼び出す。
			if (this.errMethod != null) {
				this.errMethod();
				return;
			}
			throw new Error("RequestError");
		}

		//登録してあったメソッドを呼び出す。
		for (var ix in data.results) {
			var t = data.results[ix].tag;
			var m = this.methods[t];
			m(data.results[ix].result, status, jqXHR);
		}

	}

	/**
	 * 通信エラー時
	 * @param {Object} jqXHR
	 * @param {number} status
	 * @param {Object} errorThrown
	 * ----------------------------------------------------------------------------
	 */
	procError(jqXHR, status, errorThrown) {
		//エラー関数が登録されていればそれを呼び出す。
		if (this.errMethod != null) {
			this.errMethod();
			return;
		}
		throw new Error("HttpError");
	}

	/**
	 * ランダム文字列生成
	 * @return {string} ランダムに発生させた８桁の文字列
	 * ----------------------------------------------------------------------------
	 */
	createRandomStr() {
		var len = 8;
		var wds = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
		var wdsLen = wds.length;
		var result = "";
		for (var i = 0; i < len; i++) {
			result += wds[Math.floor(Math.random() * wdsLen)];
		}
		return result;
	}

	/**
	 * コンフィグ設定引き渡し
	 * param {string} config
	 * ----------------------------------------------------------------------------
	 */
	setConfig(config) {
		// ファイル取得URL作成オブジェクトにコンフィグを引き渡す
		fileUrlMaker.setConfig(config)

		// レスポンス整形作成オブジェクトにコンフィグを引き渡す
		fileResponseFormatter.setConfig(config)
	}
}
