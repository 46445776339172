export default class {
  constructor(config) {
    this.config = config;
    this.manualUrl = this.config.sidemenuSettings.manualUrl;
    this.attentionUrl = this.config.sidemenuSettings.informationUrl;

    //ダイアログ背景作成
    var bk = $("<div id=\"sideMenuDlgBk\"></div>", {
      css: { "z-index": 3000 }
    }).appendTo("body");

    bk.load("./sidemenudlg.html", function (response, status, xhr) {
      // サイドメニューダイアログクローズボタン
      $("#sideMenuDlgClose").on("click", function (evnt) {
        $("#sideMenuDlgContent").empty();
        $("#sideMenuDlgBk").css("display", "none");
      });
    });
  }

  set attentionHtml(h) {
    var tmp = h.replace(/<html/g, "<div");
    tmp = tmp.replace(/<body/g, "<hoge");
    this._attentionHtml = tmp;
    this._attentionBody = $(tmp).find("hoge").html();
  }

  get attentionHtml() {
    return this._attentionHtml;
  }

  /**
   * サイドメニュー処理
   */
  show(type) {
    $("#sideMenuDlgBk").css("display", "block");

    //幅中心に持っていく
    var scrWidth = $(window).width();
    var dlgWidth = $("#sideMenuDlg").width();
    var xpos = (scrWidth - dlgWidth) / 2;
    $("#sideMenuDlg").css("left", xpos + "px");

    //高さ中心に持っていく
    var scrHeight = $(window).height();
    var dlgHeight = $("#sideMenuDlg").height();
    var ypos = (scrHeight - dlgHeight) / 2;
    $("#sideMenuDlg").css("top", ypos + "px");

    if (type == "manual") {
      this.dispManual(this.manualUrl);
      $("#manualFrame").css("display", "block");
      $("#attentionFrame").css("display", "none");
    }

    if (type == "attention") {
      this.dispAttention(this.attentionUrl);
      $("#manualFrame").css("display", "none");
      $("#attentionFrame").css("display", "block");
    }
  }

  /**
   * マニュアル表示
   * @param {String} manualUrl 
   */
  dispManual(manualUrl) {
    if ($("manualFrame")[0] == undefined) {
      var manualFrame = $("<div id=\"manualFrame\"></div>");
      $(manualFrame).appendTo("#sideMenuDlgContent");

      var manualItem = $("<img id=\"sideMenuManual\" src=\"" + manualUrl + "\"></img>");
      $(manualItem).appendTo("#manualFrame");
    }
  }

  /**
   * 利用注意表示
   * @param {String} attentionUrl 
   */
  dispAttention(attentionUrl) {
    var attentionFrame = $("<div id=\"attentionFrame\"></div>");
    $(attentionFrame).appendTo("#sideMenuDlgContent").html(this._attentionBody);
  }
}