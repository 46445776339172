/**
 * 定数オブジェクト定義
 * ------------------------------------------------------------------------------
 * @LIS 20180411 新規作成
 * ==============================================================================
 */
export var _CNST = {
  //RiverApiへのリクエストURL
  API_URL: "/swin/api/",
  //エラー画面のURL
  ERR_URL: "./error.html",
  //基準となるZ-INDEXの値
  Z_IDX: 1900,
  QT_MSG: "この観測値は異常値の疑いがあります。",

  // ファイル取得APIリスト
  FILE_GET_API: new Map([
    ["getConfig", { cache: false, cacheTime: 0 }],
    ["getOptionalInfo", { cache: false, cacheTime: 0 }],
    ["getPosition", { cache: true, cacheTime: 5000 }],
    ["getAreasInfo", { cache: true, cacheTime: 5000 }],
    ["getRivers", { cache: true, cacheTime: 5000 }],
    ["getAreasLevels", { cache: true, cacheTime: 5000 }],
    ["getLevels", { cache: true, cacheTime: 5000 }],
    ["getRanking", { cache: true, cacheTime: 5000 }],
    ["getRiverObs", { cache: true, cacheTime: 5000 }],
  ]),
  // GET取得APIリスト
  GET_API: ["getObsInfo", "searchTown", "GetCctvDetail", "GetObsDetail"],

  // 画面設定情報のファイルURL（試験用）
  GET_CONFIG_URL: "/swin/files/conf/mapconfig.json",
  // 画面設定情報のファイルURL（本番用）
  //GET_CONFIG_URL: "/files/conf/mapconfig.json",
  // 任意情報のファイルURL（試験用）
  GET_OPTIONAL_INFO_URL: "/swin/files/optional/info.json",
  // 任意情報のファイルURL（本番用）
  //GET_OPTIONAL_INFO_URL: "/files/optional/info.json",
};
