import { DateFormatUtil } from "./util.js";

/**
 * タイトルパネルクラス
 * ------------------------------------------------------------------------------
 * @LIS 20180411 新規作成
 * ==============================================================================
 */
export default class {

  /**
   * コンストラクタ
   * @constructor
   * ----------------------------------------------------------------------------
   */
  constructor() {
    $("<div></div>", {
      css: { "z-index": 2000 }
    }).appendTo("body").load("./title.html");
  }

  /**
   * 日付設定
   * @param {Date} date
   * ---------------------------------------------------------------------------- 
   */
  setTime(date) {
    var dateStr = DateFormatUtil.format(date, "YYYY/MM/DD");
    var timeStr = DateFormatUtil.format(date, "hh:mm");
    $("#dateBlock").html(dateStr);
    $("#timeBlock").html(timeStr);
  }
}