
/**
 * ハイドログラフ拡大ダイアログ部品
 * ------------------------------------------------------------------------------
 * @LIS 2018 新規作成
 * ==============================================================================
 */
export default class {

  /**
   * コンストラクタ
   */
  constructor() {
    // ハイドログラフ拡大ダイアログの作成
    this.hydroZoomDlgBk = $("<div id=\"hydroZoomDlgBk\"></div>").appendTo("body");
    this.hydroZoomDlg = $("<div id=\"hydroZoomDlg\"></div>").appendTo(this.hydroZoomDlgBk);
    this.hydroZoomHead = $("<div id=\"hydroZoomHead\"></div>").appendTo(this.hydroZoomDlg);
    this.hydroZoomFrame = $("<div id=\"hydroZoomFrame\"></div>").appendTo(this.hydroZoomDlg);
    this.hydroZoom = $("<div id=\"hydroZoom\"></div>").appendTo(this.hydroZoomFrame);
    this.hydroZoomFoot = $("<div id=\"hydroZoomFoot\"></div>").appendTo(this.hydroZoomDlg);

    // クローズボタンの実装
    this.hydroZoomDlgClose = $("<img src=\"img/close.png\">").appendTo(this.hydroZoomHead);
    this.hydroZoomDlgClose.on("click", { win: this.hydroZoomdlgBk }, this.close);

    $("#hydroZoomDlg").setExtVal("instance", this);
  }

  /**
   * ハイドログラフ拡大ダイアログの表示
   */
  show() {
    $("#hydroZoomDlgBk").css("display", "block");

    //幅中心に持っていく
    var scrWidth = $(window).width();
    var dlgWidth = $("#hydroZoomDlg").width();
    var xpos = (scrWidth - dlgWidth) / 2;
    $("#hydroZoomDlg").css("left", xpos + "px");

    //高さ中心に持っていく
    var scrHeight = $(window).height();
    var dlgHeight = $("#hydroZoomDlg").height();
    var ypos = (scrHeight - dlgHeight) / 2;
    $("#hydroZoomDlg").css("top", ypos + "px");
  }

  /**
   * ハイドログラフ拡大ダイアログの非表示
   */
  close() {
    $("#hydroZoomDlgBk").css("display", "none");
  }

  /**
   * ダイアログが開いているかどうか確認
   * @return {boolean} true(開いている)、false(閉じている)
   * ----------------------------------------------------------------------------
   */
  isOpen() {
    if ($("#hydroZoomDlgBk").css("display") == "block") {
      return true;
    }
    return false;
  }

  /**
   * 再描画処理
   * @param {Object} event 
   */
  redraw(event) {
    var dlgInst = $("#hydroZoomDlg").getExtVal("instance");
    // ダイアログの幅中心算出
    var scrWidth = $(window).width();
    var dlgWidth = $("#hydroZoomDlg").width();
    var xpos = (scrWidth - dlgWidth) / 2;
    $("#hydroZoomDlg").css("left", xpos + "px");
    
    // ダイアログの高さ中心算出
    var scrHeight = $(window).height();
    var dlgHeight = $("#hydroZoomDlg").height();
    var ypos = (scrHeight - dlgHeight) / 2;
    $("#hydroZoomDlg").css("top", ypos + "px");
  }
}