import ZoomBtn from "./zoombtn.js";

/**
 * ズームパネルクラス
 * ------------------------------------------------------------------------------
 * @LIS 20180411 新規作成
 * ==============================================================================
 */
export default class{

  /**
   * コンストラクタ
   * @constructor
   * @param {Object} map leaflet Mapのオブジェクト 
   * ----------------------------------------------------------------------------
   */
  constructor(map) {
    const TOP = 38;
    this.mapObj = map;

    this.zoomInBtn = new ZoomBtn("./img/zoom/zoom_in.png");
    this.zoomOutBtn = new ZoomBtn("./img/zoom/zoom_out.png");
    this.zoomInBtn.on("click", {btn: this}, this.zoomIn);
    this.zoomInBtn.css("top", TOP + "px");
    this.zoomOutBtn.on("click", {btn: this}, this.zoomOut);
    this.zoomOutBtn.css("top", (TOP + 40 + 4) + "px");
    $("body").append(this.zoomInBtn);
    $("body").append(this.zoomOutBtn);
  }

  /**
   * ズームインボタンクリック処理
   * @param {Object} event イベントオブジェクト
   * ----------------------------------------------------------------------------
   */
  zoomIn(event) {
    event.data.btn.mapObj.zoomIn();
  }

  /**
   * ズームアウトボタンクリック処理
   * @param {Object} event イベントオブジェクト
   * ----------------------------------------------------------------------------
   */
  zoomOut(event) {
    event.data.btn.mapObj.zoomOut();
  }
}