/**
 * Class GetDataMaker
 * GETAPI用の送信データ作成クラス
 * ------------------------------------------------------------------------------
 * @LIS 20200402 新規作成
 * ==============================================================================
 */
export class GetDataMaker {

	/**
	 * コンストラクタ
	 * @constructor
	 * @return {Requester} Requesterクラスのインスタンス
	 * ----------------------------------------------------------------------------
	 */
	constructor() {
		this.dataMakerMap = new Map();
		const getObsInfoDataMaker = new GetObsInfoDataMaker();
		const searchTownDataMaker = new SearchTownDataMaker();
		const getCctvDetailDataMaker = new GetCctvDetailDataMaker();
		const getObsDetailDataMaker = new GetObsDetailDataMaker();

		this.dataMakerMap.set(getObsInfoDataMaker.apiId, getObsInfoDataMaker);
		this.dataMakerMap.set(searchTownDataMaker.apiId, searchTownDataMaker);
		this.dataMakerMap.set(getCctvDetailDataMaker.apiId, getCctvDetailDataMaker);
		this.dataMakerMap.set(getObsDetailDataMaker.apiId, getObsDetailDataMaker);
	}

	/**
	 * コンストラクタ
	 * @param {Object} _request RequesterRequestに登録されているリクエスト情報
	 * ----------------------------------------------------------------------------
	 */
	registRequest(_request) {
		this.request = _request;
	}

	/**
	 * 送信データ生成処理
	 * 登録されているリクエスト情報からGET送信データを作成する
	 */
	getData() {
		if (!this.request) {
			return null;
		}

		let dataObj =  this.dataMakerMap.get(this.request.apiId);
		return dataObj.getData(this.request);
	}

}

export class IDataMaker {

	constructor(_apiId) {
		this._apiId = _apiId;
	}

	get apiId () {
		return this._apiId;
	}
}

/**
 * Class GetObsInfoDataMaker
 * 観測所詳細情報取得用の送信データ作成クラス
 * ------------------------------------------------------------------------------
 * @LIS 20200407 新規作成
 * ==============================================================================
 */
export class GetObsInfoDataMaker extends IDataMaker {

	/**
	 * コンストラクタ
	 * @constructor
	 * @return {Requester} Requesterクラスのインスタンス
	 * ----------------------------------------------------------------------------
	 */
	constructor() {
		super("getObsInfo");
	}

	getData(req) {
		return req;
	}
}

/**
 * Class SearchTownDataMaker
 * 市町村検索用の送信データ作成クラス
 * ------------------------------------------------------------------------------
 * @LIS 20200408 新規作成
 * ==============================================================================
 */
export class SearchTownDataMaker extends IDataMaker {

	/**
	 * コンストラクタ
	 * @constructor
	 * @return {Requester} Requesterクラスのインスタンス
	 * ----------------------------------------------------------------------------
	 */
	constructor() {
		super("searchTown");
	}

	getData(req) {
		return req;
	}
}

/**
 * Class GetCctvDetailDataMaker
 * getCctvDetail送信データ作成クラス
 * ------------------------------------------------------------------------------
 * @LIS 20200410 新規作成
 * ==============================================================================
 */
export class GetCctvDetailDataMaker extends IDataMaker {

	/**
	 * コンストラクタ
	 * @constructor
	 * @return {Requester} Requesterクラスのインスタンス
	 * ----------------------------------------------------------------------------
	 */
	constructor() {
		super("GetCctvDetail");
	}

	getData(req) {
		return req;
	}
}

/**
 * Class GetObsDetailDataMaker
 * getObsDetailの送信データ作成クラス
 * ------------------------------------------------------------------------------
 * @LIS 20200410 新規作成
 * ==============================================================================
 */
export class GetObsDetailDataMaker extends IDataMaker {

	/**
	 * コンストラクタ
	 * @constructor
	 * @return {Requester} Requesterクラスのインスタンス
	 * ----------------------------------------------------------------------------
	 */
	constructor() {
		super("GetObsDetail");
	}

	getData(req) {
		return req;
	}
}