export default class {

  constructor() {
    // メッセージボックスの作成
    this.msgBoxBk = $("<div id=\"msgBoxBk\"></div>").appendTo("body");
    this.msgBoxElm = $("<div id=\"msgBox\"></div>").appendTo(this.msgBoxBk);
    this.msgDatElm = $("<div id=\"msgData\"></div>").appendTo(this.msgBoxElm);

    // クローズボタンの実装
    this.msgBoxClose = $("<img src=\"img/close.png\">").appendTo(this.msgBoxElm);
    this.msgBoxClose.on("click", { win: this.msgBoxBk }, this.close);

    $("#msgBox").setExtVal("instance", this);
  }

  /**
   * お知らせ情報を表示するボックスの作成
   * @param {String} data お知らせ情報 
   */
  createInfoBox(data) {
    var inst = $("#msgBox").getExtVal("instance");
    inst.createMsgBox("info", data);
  }

  /**
   * エラー情報を表示するボックスの作成
   * @param {String} data エラー情報
   */
  createErrorBox(data) {
    var inst = $("#msgBox").getExtVal("instance");
    inst.createMsgBox("error", data);
  }

  /**
   * メッセージボックスの作成
   * @param {String} type 作成するボックスのスタイル 
   * @param {String} data 表示する情報
   */
  createMsgBox(type, data) {
    if (data == null || data == "") return;

    var inst = $("#msgBox").getExtVal("instance");
    // ボックススタイルの判定
    if (type == "info") {
      $(inst.msgBoxElm).addClass("info");
    } else if (type == "error") {
      $(inst.msgBoxElm).addClass("error");
    }

    inst.msgDatElm.html(data);
    inst.show();
  }
  /**
   * メッセージボックスの表示
   * @param {Object} event 
   */
  show(event) {
    var inst = $("#msgBox").getExtVal("instance");
    inst.msgBoxBk.css("display", "block");
　　
    // 表示位置の指定
    var ww = $(window).width();
    var wh = $(window).height();
    var cw = inst.msgBoxElm.width();
    var ch = inst.msgBoxElm.height();
    var top = (wh / 2) + (ch / 2);
    var left = (ww / 2) - (cw / 2);
    inst.msgBoxElm.css("top", top + "px");
    inst.msgBoxElm.css("left", left + "px");
  }
   /**
   * ダイアログが開いているかどうか確認する
   * @return {boolean} true(開いている)、false(閉じている)
   * ----------------------------------------------------------------------------
   */
  isOpen() {
    if ($("#msgBoxBk").css("display") == "block") {
      return true;
    }
    return false;
  }

  close(event) {
    event.data.win.css("display", "none");
  }
}