export async function authCityToken(req, tk) {
  var sbmter = req.create();
  var result = false;
  sbmter.register(
    "login",
    {
      token: tk,
    },
    (resultData) => {
      //console.log("login result.", resultData);
      if (resultData.logined) {
        result = true;
      }
    }
  );
  await sbmter
    .submit()
    .then(() => {
      //console.log("ログイン通信成功");
    })
    .catch((e) => {
      console.log("ログイン通信エラー", e);
    });
  //console.log("authCityToken result.", result);
  return result;
}
