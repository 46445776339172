/**
 * 危機管理型水位計画面メイン処理
 * ------------------------------------------------------------------------------
 * ＠LIS　20180406 新規作成
 * ==============================================================================
 */
import { Requester } from "./requester.js";
import { _CNST } from "./const.js";
import { MapWin } from "./mapwin.js";
import UrlUtil from "./urlutil.js";
import MenuPanel from "./menupanel.js";
import TitlePanel from "./titlepanel.js";
import MenuDispBtn from "./menudispbtn.js";
import AddressPanel from "./address.js";
import ZoomPanel from "./zoompanel.js";
import ListDlg from "./listdlg.js";
import ObsDlg from "./obsdlg.js";
import SideMenu from "./sidemenu.js";
import OpInfo from "./optionalinfo.js";
import LegendBox from "./legendbox.js";
import FirstAccessDlg from "./firstaccessdlg.js";
import MessageBox from "./messagebox.js";
import { DateFormatUtil } from "./util.js";
import PageLog from "./pagelog";
import * as city from "./city_auth";

//リクエスト送信オブジェクト
var _req = new Requester(_CNST.API_URL);
//マップ画面オブジェクト
var _mapWin = null;
//観測所一覧ダイアログ
var _listDlg = null;
//観測所ダイアログ
var _obsDlg = null;
//メニューパネルオブジェクト
var _menuPanel = null;
//タイトルパネルオブジェクト
var _titlePanel = null;
//メニュー表示切替ボタンオブジェクト
var _menuDispBtn = null;
//ズームボタンパネル
var _zoomPanel = null;
//住所表示パネル
var _addrPanel = null;
//サイドメニュー
var _sideMenu = null;
//任意情報
var _opInfo = null;

//地図イベントでのリクエスト送信制御用タイマーID
var _mapEvTimerId = null;
//getConfig(ConfigApi)で取得する設定情報
var _config = null;
//getPostion(PostionApi)で取得する情報
var _nowAreas = null;
//getAreasInfo(AreasInfoApi)で取得する情報
var _nowAreasInfo = null;
//getRivers(RiverApi)で取得する情報
var _nowRivers = null;
//getAreasLevels(AreasLevelsApi)で取得する情報
var _nowCurrentLevels = null;
//特殊横断設定情報
var _spSurfaceList = null;

//中心地マーカー
var _locationMaker = null;

//画面強調レイヤ格納配列
var _areaLayers = [];
//市町村強調レイヤグループ
var _townLayerGroup = L.layerGroup();
//都道府県強調レイヤグループ
var _prefLayerGroup = L.layerGroup();
//河川JSONレイヤグループ
var _riverLayerGroup = L.layerGroup();
//河川GEOJSON配列
var _riverGJSONs = [];
//CCTVレイヤグループ
var _cctvLayerGroup = L.layerGroup();
//CCTV GEOJSON配列
var _cctvGJSONs = [];
//観測所レイヤグループ
var _obsLayerGroup = L.layerGroup();
//観測所GEOJSON配列
var _obsGJSONs = [];
var _obsGJSONdatas = [];

//TM水位観測所レイヤグループ
var _tmObsLayerGroup = L.layerGroup();
//TM 観測所GeoJSON配列
var _tmObsGJSONs = [];
var _tmObsGJSONdatas = [];

var _tmOmitObs = [];

//超過TM水位観測所レイヤグループ
var _overTmObsLayerGroup = L.layerGroup();
//超過TM 観測所GeoJSON配列
var _overTmObsGJSON = null;
var _overTmObsGJSONdata = null;
//超過TM取得時刻
var _overTmGetterTime = null;

//凡例
var _legendBox = null;

//初回アクセス表示
var _firstAccessDlg = null;
//現在地取得エラー
var _messageBox = null;

//市町村認証トークン情報
var _cityToken = null;

//観測所ダイアログ表示フラグ
var _dialogFlag = false;
//観測所ダイアログ初期表示フラグ
var _dialogShowFlag = false;
//getPosition用ファイル初期読込フラグ
var _firstFlag = true;

/**
 * 初期処理
 *  ・設定をサーバに要求
 *  ・MapWinを作成
 * ------------------------------------------------------------------------------
 */
$(document).ready(function () {
  //凡例BOX作成
  _legendBox = new LegendBox();

  //任意情報を作成
  _opInfo = new OpInfo();

  //tooltipを作成
  $('<div id="toolTip">' + _CNST.QT_MSG + "</div>").appendTo("body");

  //現在地取得エラーを作成
  _messageBox = new MessageBox();

  //RequesterReqest生成
  var sbmter = _req.create();

  //リクエスト生成
  sbmter.register(
    "getConfig",
    {
      sys: "swin",
    },
    function (receiveData) {
      //受信したデータを設定
      _config = receiveData;
      //console.log("debug:", _config);
      sbmter.setConfig(_config);
    }
  );

  //任意情報取得
  sbmter.register("getOptionalInfo", {}, dispOptionalInfo);

  //リクエスト送信
  sbmter
    .submit()
    .then(async () => {
      //MapWinを作成（地図表示）
      _mapWin = new MapWin("map", _config);

      //パラメータ分解
      var params = UrlUtil.parse(location.href);

      // 202005add 市町村認証
      if (_config.cityMode) {
        var tk = params.tk;
        var tkrec = await city.authCityToken(_req, tk);
        //console.log("tk or tkrec", tk, tkrec);
        if (!tk || !tkrec) {
          location.href = _config.cityErrPage;
          return;
        }
        _cityToken = tk;
      }

      //パラメータで指定された状態に変更する
      setMapStateToParam(params);

      //ListDlgを作成
      _listDlg = new ListDlg(_config);

      //ObsDlgを作成
      _obsDlg = new ObsDlg(_mapWin.map, _config);

      //SideMenuを作成
      _sideMenu = new SideMenu(_config, rewriteUrl, params);

      //地図のイベント登録
      _mapWin.addEvent("zoomend", zoomMap);
      _mapWin.addEvent("moveend", moveMap);
      _mapWin.addEvent("locationfound", locationSuccess);
      _mapWin.addEvent("locationerror", locationError);

      //画面初期処理呼び出し
      initApp(params);
      //コンテンツ表示
      ctrlContents();
      _firstFlag = false;
      //初回アクセス判定(localStorage)
      checkFirstAccess();
      //特殊横断設定の取得
      getSpSurfaceList();
    })
    .catch((e) => {
      console.log("エラーをキャッチしました。 :", e, _config);
      //location.href = _CNST.ERR_URL;
    });
});

/**
 * 画面初期処理（地図表示後）
 * ------------------------------------------------------------------------------
 */
function initApp(params) {
  //画面リサイズ（回転含む）
  $(window).on("orientationchange resize", function (event) {
    _obsDlg.redraw(event);
    if (_obsDlg.isOpen()) {
      setTimeout(flayObsPosition, 700, _obsDlg.obsData, _obsDlg.latlngData);
    }
    _sideMenu.redraw(event);
    if (_firstAccessDlg != null) {
      _firstAccessDlg.redraw(event);
    }
    if (_listDlg.isOpen()) {
      _listDlg.redraw(event);
    }
    if (_messageBox.isOpen()) {
      _messageBox.show(event);
    }
  });

  //<< コンポーネント作成・表示・イベント登録 >>
  //メニューパネル生成
  _menuPanel = new MenuPanel();

  //メニューボタン（サイドメニュー）
  _menuPanel.registBtn(0, _config.menuIcons[0][0], sideMenuMBtnClick);
  //メニューボタン（更新）
  _menuPanel.registBtn(1, _config.menuIcons[1][0], renewMBtnClick);
  //メニューボタン（現在位置）
  _menuPanel.registToggle(2, _config.menuIcons[2], locationMBtnClick);
  //メニューボタン（観測所一覧）
  _menuPanel.registToggle(3, _config.menuIcons[3], obslistMBtnClick);
  //メニューボタン（凡例）
  _menuPanel.registToggle(4, _config.menuIcons[4], legendMBtnClick);

  //タイトル
  _titlePanel = new TitlePanel();
  setTimeout(() => {
    _titlePanel.setTime(new Date());
  }, 400);

  //メニューON・OFFボタン
  _menuDispBtn = new MenuDispBtn(clickMenuDispBtn);

  //ズームボタン
  _zoomPanel = new ZoomPanel(_mapWin.map);

  //アドレスパネル
  _addrPanel = new AddressPanel();
}

/**
 * パラメータ指定で地図の状態を変更する
 * @param {Object} params URLパラメータで指定された内容
 * ------------------------------------------------------------------------------
 */
function setMapStateToParam(params) {
  //パラメータに合わせて表示変更（ズーム）（中心位置）
  if (params.zm != null && params.clat != null && params.clon != null) {
    _mapWin.move(+params.clon, +params.clat, +params.zm);
  }
  if (params.zm != null && (params.clat == null || params.clon == null)) {
    _mapWin.setZoom(+params.zm);
  }
  if (params.zm == null && params.clat != null && params.clon != null) {
    _mapWin.move(+params.clon, +params.clat);
  }

  //タイル違い
  if (params.t != null) {
    _mapWin.changeTile(+params.t);
  }

  if (params.dobs != null) {
    _mapWin.dispOBS = params.dobs;
  }
  if (params.dtv != null) {
    _mapWin.dispCCTV = params.dtv;
  }
  if (params.drvr != null) {
    _mapWin.dispRVR = params.drvr;
  }
  if (params.dtmobs != null) {
    _mapWin.dispTMOBS = params.dtmobs;
  }
  if (params.dtmtv != null) {
    _mapWin.dispTMCCTV = params.dtmtv;
  }
}

//*******************************************************************************
//地図系イベント
//*******************************************************************************

/**
 * ズーム終了時イベント処理
 * @param {object} evnt leaflet Evnt object
 * ------------------------------------------------------------------------------
 */
function zoomMap(evnt) {
  if (_mapEvTimerId != null) {
    clearTimeout(_mapEvTimerId);
  }

  //観測所ダイアログ表示中は実行しない
  if (!_dialogFlag) {
    _mapEvTimerId = setTimeout(ctrlContents, _config.eventWaitTime);
  }
}

/**
 * 中心地移動終了時イベント処理
 * @param {object} evnt leaflet Evnt object
 * ------------------------------------------------------------------------------
 */
function moveMap(evnt) {
  if (_mapEvTimerId != null) {
    clearTimeout(_mapEvTimerId);
  }

  // 観測所ダイアログ表示中は実行しない
  if (!_dialogFlag) {
    _mapEvTimerId = setTimeout(ctrlContents, _config.eventWaitTime);
  }
}

/**
 * 現在位置取得成功時イベント処理
 * @param {object} evnt leaflet Evnt object
 * ------------------------------------------------------------------------------
 */
function locationSuccess(evnt) {
  if (!evnt.coords) return;
  var latlng = L.latLng(evnt.coords.latitude, evnt.coords.longitude);
  _locationMaker = L.marker(latlng, {
    icon: _mapWin.getLocationIcon(),
  }).addTo(_mapWin.map);
  var zoom = _mapWin.map.getZoom();
  if (zoom < 12) {
    setTimeout(
      (latlng, zoom) => {
        _mapWin.map.setView(latlng, zoom);
      },
      400,
      latlng,
      12
    );
  } else {
    //_mapWin.map.setView(latlng, zoom);
    setTimeout(
      (latlng, zoom) => {
        _mapWin.map.setView(latlng, zoom);
      },
      400,
      latlng,
      zoom
    );
  }
}

/**
 * 現在位置取得失敗時イベント処理
 * @param {object} evnt leaflet Evnt object
 * ------------------------------------------------------------------------------
 */
function locationError(error) {
  // メニューパネルのGPSアイコン表示切替
  var btn = _menuPanel.getBtnObject(2);
  btn.setStatus(false);

  // エラー内容判定
  var errorMsg = null;
  if (error.code == 1) {
    errorMsg =
      "<p>GPS測位に失敗しました。<br />SSL通信でアクセスし直してください。</p>";
  } else if (error.code == 2) {
    errorMsg =
      "<p>GPS測位に失敗しました。<br />SSL通信でアクセスし直してください。</p>";
  } else {
    errorMsg =
      "<p>屋外など電波を受信しやすい場所で、現在地ボタンを押して下さい。</p>";
  }
  // エラーメッセージボックス作成
  _messageBox.createErrorBox(errorMsg);
}

/**
 * コンテンツ表示切替（ズーム、移動時に発生する）
 * ------------------------------------------------------------------------------
 */
function ctrlContents() {
  //時刻更新
  setTimeout(() => {
    _titlePanel.setTime(new Date());
  }, 400);

  //コンテンツ表示
  var zoom = _mapWin.getZoom();

  //画面の最小、最大座標取得
  var mapBounds = _mapWin.map.getBounds();
  var minLatLng = mapBounds.getSouthWest();
  var maxLatLng = mapBounds.getNorthEast();
  var level = "town";
  if (isRange(_config.prefHighlightRange, zoom)) {
    level = "pref";
  }
  var prm = {
    level: level,
    min: {
      lon: minLatLng.lng,
      lat: minLatLng.lat,
    },
    max: {
      lon: maxLatLng.lng,
      lat: maxLatLng.lat,
    },
    firstFlag: _firstFlag,
  };

  //レクエスト生成
  var sbmter = createRequest(prm, zoom);

  //送信処理
  sbmter
    .submit()
    .then(() => {
      //住所表示
      dispAddr();
      //console.log("dispAddr ok");
      //地域強調表示
      dispHighLight();
      //console.log("dispHighLight ok");
      //河川タイル表示
      dispRiverTile(zoom);
      //console.log("dispRiverTile ok");
      //河川JSON表示
      dispRiver(zoom);
      //console.log("dispRiver ok");
      //CCTV表示
      dispCctv(zoom,false);
      //console.log("dispCctv ok");
      //TM水位観測所表示
      dispTmObs(zoom);
      //水位計表示
      dispObs(zoom);
      //console.log("dispObs ok");
      //超過TM水位観測所表示
      dispOverTmObs(zoom);

      //URL書き換え
      rewriteUrl(zoom);
      //console.log("rewriteUrl ok");
    })
    .catch((e) => {
      //失敗時 >>>
      console.log("送信処理に失敗しました。", e);
    });
}

/**
 * コンテンツ情報取得のための基本情報の取得リクエストを用意する。
 * @param {Object} prm パラメータ
 * @param {number} zoom ズームレベル
 * @return {Object} RequesterRequestインスタンスを返却
 * ------------------------------------------------------------------------------
 */
function createRequest(prm, zoom) {
  //地域取得 + 強調情報取得 + 河川取得(?)
  var sbmter = _req.create();
  //地域取得
  sbmter.register(
    "getPosition",
    prm,
    function (data) {
      _nowAreas = data;
    },
    "getPostion_ctrlContents"
  );
  //地域情報取得
  sbmter.registerParamMacro(
    "getAreasInfo",
    function (data) {
      _nowAreasInfo = data;
    },
    "getPostion_ctrlContents"
  );
  //河川情報取得
  sbmter.registerParamMacro(
    "getRivers",
    function (data) {
      _nowRivers = data;
    },
    "getPostion_ctrlContents"
  );
  if (isRange(_config.obsRange, zoom)) {
    sbmter.registerParamMacro(
      "getAreasLevels",
      function (data) {
        _nowCurrentLevels = data.levels;
      },
      "getPostion_ctrlContents"
    );
  }

  return sbmter;
}

/**
 * 住所情報表示
 * ------------------------------------------------------------------------------
 */
function dispAddr() {
  if (_nowAreas.centerFlag == true) {
    if (_nowAreas.towns != null && _nowAreas.towns.length != 0) {
      _addrPanel.address = _nowAreas.towns[0].name;
    } else {
      _addrPanel.address = _nowAreas.prefs[0].name;
    }
  } else {
    _addrPanel.address = "";
  }
}

/**
 * 地域強調表示
 * ------------------------------------------------------------------------------
 */
function dispHighLight() {
  var datas = null;
  var mode = "";

  //パネル準備
  if (_nowAreasInfo.towns != null) {
    //強調表示(都道府県パネルがあれば削除、市町村パネルがなければ追加)
    if (_mapWin.map.hasLayer(_prefLayerGroup)) {
      _prefLayerGroup.remove();
    }
    if (!_mapWin.map.hasLayer(_townLayerGroup)) {
      _townLayerGroup.setZIndex(_CNST.Z_IDX + 10);
      _townLayerGroup.addTo(_mapWin.map);
    }

    //datas = _nowAreasInfo.towns;
    datas = [];
    _nowAreasInfo.towns.forEach((rec) => {
      if (rec.count.obs != 0) {
        datas.push(rec);
      }
    });
    mode = "TOWN";
  } else {
    //強調表示(市町村パネルがあれば削除、都道府県パネルがなければ追加)
    if (_mapWin.map.hasLayer(_townLayerGroup)) {
      _townLayerGroup.remove();
    }
    if (!_mapWin.map.hasLayer(_prefLayerGroup)) {
      _prefLayerGroup.setZIndex(_CNST.Z_IDX + 10);
      _prefLayerGroup.addTo(_mapWin.map);
    }

    datas = [];
    _nowAreasInfo.prefs.forEach((rec) => {
      if (rec.count.obs != 0) {
        datas.push(rec);
      }
    });
    mode = "PREF";
  }

  //地域強調表示
  createAreaLayers(datas).then(() => {
    for (var ix in datas) {
      if (mode === "TOWN") {
        if (datas[ix].remove != null && datas[ix].remove == 1) {
          _townLayerGroup.removeLayer(_areaLayers[datas[ix].code]);
          continue;
        }

        if (
          _areaLayers[datas[ix].code] != null &&
          _areaLayers[datas[ix].code] != -1 &&
          !_townLayerGroup.hasLayer(_areaLayers[datas[ix].code])
        ) {
          _townLayerGroup.addLayer(_areaLayers[datas[ix].code]);
        }
      } else if (mode === "PREF") {
        if (datas[ix].remove != null && datas[ix].remove == 1) {
          _prefLayerGroup.removeLayer(_areaLayers[datas[ix].code]);
          continue;
        }

        if (
          _areaLayers[datas[ix].code] != null &&
          _areaLayers[datas[ix].code] != -1 &&
          !_prefLayerGroup.hasLayer(_areaLayers[datas[ix].code])
        ) {
          _prefLayerGroup.addLayer(_areaLayers[datas[ix].code]);
        }
      }
    }
  });
}

/**
 * 河川タイル表示
 * @param {number} zoom ズームレベル
 * ------------------------------------------------------------------------------
 */
function dispRiverTile(zoom) {
  if (isRange(_config.riverTileRange, zoom) && _mapWin.dispRVR == 1) {
    _mapWin.showRiverTile();
  } else {
    _mapWin.hideRiverTile();
  }
}

/**
 * 河川GEOJSON表示
 * @param {number} zoom ズームレベル
 * ------------------------------------------------------------------------------
 */
function dispRiver(zoom) {
  //レイヤーグループの準備
  if (!isRange(_config.riverJsonRange, zoom)) {
    if (_mapWin.map.hasLayer(_riverLayerGroup)) {
      _mapWin.map.removeLayer(_riverLayerGroup);
      _riverLayerGroup.clearLayers();
      _riverGJSONs = [];
    }
    return;
  }

  if (!_mapWin.map.hasLayer(_riverLayerGroup)) {
    _riverLayerGroup.setZIndex(_CNST.Z_IDX + 30);
    _mapWin.map.addLayer(_riverLayerGroup);
  }

  //既存の読み込み済みGEOJSONで再取得しなくて良いものは再利用する。
  for (var idx in _riverGJSONs) {
    var hit = false;
    for (var ix in _nowRivers.rivers) {
      if (idx == _nowRivers.rivers[ix].code + "") {
        hit = true;
      }
    }
    if (!hit) {
      _riverLayerGroup.removeLayer(_riverGJSONs[idx]);
      delete _riverGJSONs[idx];
    }
  }

  //既存の読み込み済みGEOJSONで配列_obsGJSONs内にないものをリクエストする。
  for (var i in _nowRivers.rivers) {
    var areaCode = _nowRivers.rivers[i].code + "";
    if (_riverGJSONs[areaCode] == null) {
      var url = _config.geoJsonUrl + "rvr/" + areaCode + ".json";
      $.ajax({
        url: url,
        beforeSend: function (jqXHR, settings) {
          jqXHR.requestURL = settings.url;
        },
      })
        .then((gjson, textResponse, jqXHR) => {
          var rvrName = gjson.features[0].properties.W05_004;

          //エリアコードをURLから取得する
          var areaCode = jqXHR.requestURL.match(
            ".+/(.+?).[a-z]+([?#;].*)?$"
          )[1];

          //GEOJsonを作成し配列に格納
          _riverGJSONs[areaCode] = L.geoJson(gjson, {
            pane: "paneRiver",
            //表示処理（太さ変えるなど）
            style: function (feature) {
              var retStyle = {
                color: _config.riverStyle.color,
                weight: _config.riverWeight[feature.properties.W05_003],
                opacity: _config.riverStyle.opacity,
              };
              return retStyle;
            },
            onEachFeature: function(feature, layer) {
              layer.bindTooltip(feature.properties.W05_004, { sticky: true });
            },
          });

          _riverLayerGroup.addLayer(_riverGJSONs[areaCode]);
        })
        .catch(() => {});
    }
  }
  // サイドメニューの河川ラインcheck判定
  if (!$("#riverCheckBox").prop("checked")) {
    _mapWin.map.removeLayer(_riverLayerGroup);
  }
}

/**
 * CCTV表示
 * @param {number} zoom ズームレベル
 * @param {boolean} clearFlag 初期化フラグ（キャッシュを使わない） サイドメニューのCCTV画像、簡易カメラ画像のチェックマーク切り替え時にコールバックされた場合のみTrue
 * ------------------------------------------------------------------------------
 */
function dispCctv(zoom,clearFlag) {

  //レイヤーグループの準備
  if (!isRange(_config.cctvRange, zoom)) {
    if (_mapWin.map.hasLayer(_cctvLayerGroup)) {
      _mapWin.map.removeLayer(_cctvLayerGroup);
      _cctvLayerGroup.clearLayers();
      _cctvGJSONs = [];
    }
    return;
  }

  // CCTV,簡易カメラのフラグ変更をトリガーにコールバックされた場合にレイヤーを初期化する
  // 初期化フラグがTrueの場合はレイヤーを初期化する 
  if (clearFlag) {
    _mapWin.map.removeLayer(_cctvLayerGroup);
    _cctvLayerGroup.clearLayers();
    _cctvGJSONs = [];
  }

  if (!_mapWin.map.hasLayer(_cctvLayerGroup)) {
    _cctvLayerGroup.setZIndex(_CNST.Z_IDX + 50);
    _mapWin.map.addLayer(_cctvLayerGroup);
  }

  //既存の読み込み済みGEOJSONで再取得しなくて良いものは再利用する。
  for (var idx in _cctvGJSONs) {
    var hit = false;
    for (var ix in _nowAreas.towns) {
      if (idx == _nowAreas.towns[ix].code + "") {
        hit = true;
      }
    }
    if (!hit && _cctvGJSONs[idx] == "") {
      _cctvLayerGroup.removeLayer(_cctvGJSONs[idx]);
      delete _cctvGJSONs[idx];
    }
  }

  // 市町村コードのケタ揃え
  var mlitCodes = [];
  for (var i in _nowAreas.towns) {
    var mlitCode = _nowAreas.towns[i].mlitCode;
    if (mlitCode.length == 6) {
      mlitCode = "0" + mlitCode;
    }
    mlitCodes[mlitCode] = mlitCode;
  }

  for (var areaCode in mlitCodes) {

    // 処理実行フラグを初期化
    var flag = true;

    // CCTV,簡易カメラのフラグ変更の確認
    // 初期化フラグがTrueの場合はキャッシュを使わないため、キャッシュの存在確認を行わない。
    // 初期化フラグがFalseの場合はキャッシュがある場合、以降の処理をスキップし、キャッシュを使用する。
    if (!clearFlag) {
      flag = _cctvGJSONs[areaCode] == null;
    }

    if (flag) {
      var url = _config.geoJsonUrl + "cctv/" + areaCode + ".json";
      $.ajax({
        url: url,
        beforeSend: function (jqXHR, settings) {
          jqXHR.requestURL = settings.url;
        },
      })
        .then((gjson, textResponse, jqXHR) => {
          //エリアコードをURLから取得する
          var areaCode = jqXHR.requestURL.match(
            ".+/(.+?).[a-z]+([?#;].*)?$"
          )[1];

          //GEOJsonを作成し配列に格納
          _cctvGJSONs[areaCode] = L.geoJson(gjson, {

            //CCTVを出す処理
            pointToLayer: function (feature, latlng) {

              // カメラの種別を確認し、対応するアイコンを作成
              // 4 = cctv
              // 1,2,3 = tmcctv (1は来ない想定)
              if (4 == feature.properties.kind){
                if ($("#cctvCheckBox").prop("checked")) {
                  var ic = _mapWin.getCctvIcon();

                  var mk = null;
                  if (ic != null) {
                    mk = L.marker(latlng, {
                      icon: ic,
                      properties: feature.properties,
                      latlng: latlng,
                      zIndexOffset: _CNST.Z_IDX + 51,
                      pane: "paneCctv",
                    });
                  }

                  mk.on("click", clickCctv);
                  return mk;
                }
              } else {
                if ($("#tmCctvCheckBox").prop("checked")) {
                  var ic = _mapWin.getTmCctvIcon();

                  var mk = null;
                  if (ic != null) {
                    mk = L.marker(latlng, {
                      icon: ic,
                      properties: feature.properties,
                      latlng: latlng,
                      zIndexOffset: _CNST.Z_IDX + 51,
                      pane: "paneTmCctv",
                    });
                  }

                  mk.on("click", clickCctv);
                  return mk;
                }
              }
            },
          });
          _cctvGJSONs[areaCode].setZIndex(_CNST.Z_IDX + 50);
          _cctvLayerGroup.addLayer(_cctvGJSONs[areaCode]);
        })
        .catch((e) => {
          // 404エラーの場合、空ファイルを配列に格納
          var cd = e.requestURL.match(".+/(.+?).[a-z]+([?#;].*)?$")[1];
          _cctvGJSONs[cd] = {};
        });
    }
  }
}

/**
 * 観測所表示
 * @param {number} zoom ズームレベル
 * ------------------------------------------------------------------------------
 */
function dispObs(zoom) {
  //レイヤーグループの準備
  if (!isRange(_config.obsRange, zoom)) {
    if (_mapWin.map.hasLayer(_obsLayerGroup)) {
      _mapWin.map.removeLayer(_obsLayerGroup);
      _obsLayerGroup.clearLayers();
      _obsGJSONs = [];
      _obsGJSONdatas = [];
    }
    return;
  }

  if (!_mapWin.map.hasLayer(_obsLayerGroup)) {
    _obsLayerGroup.setZIndex(_CNST.Z_IDX + 70);
    _mapWin.map.addLayer(_obsLayerGroup);
  }

  //既存の読み込み済みGEOJSONで再取得しなくて良いものは再利用する。
  for (var idx in _obsGJSONs) {
    var hit = false;
    for (var ix in _nowAreasInfo.towns) {
      if (
        idx == _nowAreasInfo.towns[ix].code + "" &&
        _nowAreasInfo.towns[ix].count.obs != 0
      ) {
        hit = true;
      }
    }
    if (!hit) {
      _obsLayerGroup.removeLayer(_obsGJSONs[idx]);
      delete _obsGJSONs[idx];
    }
  }

  //既存の読み込み済みGEOJSONで配列_obsGJSONs内にないものをリクエストする。
  for (var i in _nowAreasInfo.towns) {
    //対象市町村に観測所がない場合、その後の処理を行わない
    if (_nowAreasInfo.towns[i].count.obs == "0") {
      continue;
    }
    var areaCode = _nowAreasInfo.towns[i].code + "";
    if (_obsGJSONs[areaCode] == null) {
      var url = _config.geoJsonUrl + "obs/" + areaCode + ".json";
      $.ajax({
        url: url,
        beforeSend: function (jqXHR, settings) {
          jqXHR.requestURL = settings.url;
        },
      })
        .then((gjson, textResponse, jqXHR) => {
          //エリアコードをURLから取得する
          var areaCode = jqXHR.requestURL.match(
            ".+/(.+?).[a-z]+([?#;].*)?$"
          )[1];

          //GEOJsonデータを格納しておく
          _obsGJSONdatas[areaCode] = gjson;

          //GEOJsonを作成し配列に格納
          _obsGJSONs[areaCode] = createObsJson(gjson);
          _obsGJSONs[areaCode].setZIndex(_CNST.Z_IDX + 70);
          _obsLayerGroup.addLayer(_obsGJSONs[areaCode]);
        })
        .catch(() => {});
    } else {
      _obsLayerGroup.removeLayer(_obsGJSONs[areaCode]);
      delete _obsGJSONs[areaCode];
      _obsGJSONs[areaCode] = createObsJson(_obsGJSONdatas[areaCode]);
      _obsGJSONs[areaCode].setZIndex(_CNST.Z_IDX + 70);
      _obsLayerGroup.addLayer(_obsGJSONs[areaCode]);
    }
  }
  // サイドメニューの水位計check判定
  if (!$("#obsCheckBox").prop("checked")) {
    _mapWin.map.removeLayer(_obsLayerGroup);
  }
}

/**
 * 観測所レイヤ―作成処理
 * @param {object} gjson GeoJsonデータ
 */
function createObsJson(gjson) {
  return L.geoJson(gjson, {
    //表示するしないフラグの処理
    filter: function (feature, layer) {
      if (feature.properties.pause == 1) return false;
      var code = feature.properties.code;
      //カレント情報から該当の情報を抽出
      var levels = _nowCurrentLevels.filter((rec) => {
        return rec.code == code;
      });

      var level = levels[0];
      if (level == null) return false;
      return level.pause == 0;
    },

    //観測所を出す処理
    pointToLayer: function (feature, latlng) {
      var code = feature.properties.code;
      //カレント情報から該当の情報を抽出
      var levels = _nowCurrentLevels.filter((rec) => {
        return rec.code == code;
      });
      var level = levels[0];

      var ic = null;

      // 欠測
      if (level.missing != 0) {
        ic = _mapWin.getNonIcon();
      }
      // 通常
      else if (level.over == 0) {
        ic = _mapWin.getNormalIcon();
      }
      // 観測開始
      else if (level.over == 1) {
        ic = _mapWin.getStartIcon();
      }
      // 危険水位超過
      else if (level.over == 11) {
        ic = _mapWin.getWarnIcon();
      }
      // 氾濫
      else if (level.over == 111 || level.over == 101) {
        ic = _mapWin.getFloodIcon();
      } else {
        console.log("不明アイコン");
        return null;
      }

      var mk = null;
      if (ic != null) {
        mk = L.marker(latlng, {
          icon: ic,
          properties: feature.properties,
          latlng: latlng,
          zIndexOffset: _CNST.Z_IDX + 71,
          pane: "paneObs",
        });
      }
      mk.on("click", clickObs);
      //console.log(JSON.stringify(mk));
      return mk;
    },
  });
}

/**
 * 超過テレメータ水位観測所表示
 * @param {number} zoom ズームレベル
 * ------------------------------------------------------------------------------
 */
function dispOverTmObs(zoom) {
  //レイヤーグループの準備
  if (!isRange(_config.overTmObsRange, zoom)) {
    if (_mapWin.map.hasLayer(_overTmObsLayerGroup)) {
      _mapWin.map.removeLayer(_overTmObsLayerGroup);
      _overTmObsLayerGroup.clearLayers();
      _overTmObsGJSON = null;
    }
    return;
  }

  if (!_mapWin.map.hasLayer(_overTmObsLayerGroup)) {
    _overTmObsLayerGroup.setZIndex(_CNST.Z_IDX + 80);
    _mapWin.map.addLayer(_overTmObsLayerGroup);
  }

  //
  var timeDiff = null;
  var diff;
  if (_overTmGetterTime != null) {
    diff = new Date().getTime() - _overTmGetterTime.getTime();
    timeDiff = diff / (1000 * 60);
  }

  var crntDate = null;
  var tm_url;
  var url;
  //初回超過TMデータ読み込み or 前回データ取得から10分経過している場合、データを読み込む
  if (_overTmObsGJSON == null || timeDiff == null || timeDiff >= 10) {

    tm_url = _config.tmApiUrl + "/system/tmCrntTime.json";
    $.ajax({
      type: "GET",
      url: tm_url,
      dataType: "json",
    }).then((data, status, jqXHR)=> {
      crntDate = new Date(data.crntObsTime);

      //all.jsonの取得
      //var url = _config.tmGeoJsonUrl + "obsGeo/all.json";
      url = _config.tmGeoJsonUrl + "/overobs/stg/" + DateFormatUtil.format(crntDate, "YYYYMMDD") + "/"
                                        + DateFormatUtil.format(crntDate, "hhmm") + "/over-obs-create.json";
      //console.log("url: " + url);
      //var url = _config.tmGeoJsonUrl + ""
      $.ajax({
        url: url,
        beforeSend: function (jqXHR, settings) {
          jqXHR.requestURL = settings.url;
        },
      })
        .then((gjson, textResponse, jqXHR) => {
          //エリアコードをURLから取得する
          //var areaCode = jqXHR.requestURL.match(".+/(.+?).[a-z]+([?#;].*)?$")[1];

          //GEOJsonデータを格納しておく
          _overTmObsGJSONdata = gjson;

          //GEOJsonを作成し配列に格納
          _overTmObsGJSON = createOverTmObsJson(gjson, 81);
          _overTmObsGJSON.setZIndex(_CNST.Z_IDX + 80);
          _overTmObsLayerGroup.addLayer(_overTmObsGJSON);

          //現在時刻の取得
          _overTmGetterTime = new Date();
        })
        .catch((e) => {});
    }).catch((e)=> {
      throw "観測時刻の取得に失敗しました。";
    });

  } else {
    _overTmObsLayerGroup.removeLayer(_overTmObsGJSON);
    _overTmObsGJSON = null;
    _overTmObsGJSON = createOverTmObsJson(_overTmObsGJSONdata, 81);
    _overTmObsGJSON.setZIndex(_CNST.Z_IDX + 80);
    _overTmObsLayerGroup.addLayer(_overTmObsGJSON);
  }
  // サイドメニューの超過TM水位計check判定
  if (!$("#tmObsCheckBox").prop("checked")) {
    _mapWin.map.removeLayer(_overTmObsLayerGroup);
  }
}

/**
 * 超過テレメータ水位観測所レイヤ―作成処理
 * @param {object} gjson GeoJsonデータ
 * @param {number} zIndex アイコンのインデックス
 * ------------------------------------------------------------------------------
 */
function createOverTmObsJson(gjson, zIndex) {
  return L.geoJson(gjson, {
    //観測所を出す処理
    pointToLayer: function (feature, latlng) {
      //表示するアイコンの設定
      let ic = null;

      if (
        feature.properties.stg_ltst_chg == null &&
        feature.properties.stg_ltst_chg_ccd == null &&
        feature.properties.obs_time == null &&
        feature.properties.stg_ovlvl == null &&
        feature.properties.stg_ccd == null
      ) {
        ic = _mapWin.getTmMissIcon();
      //} else if (feature.properties.stageFlag > 70) {
      } else if (feature.properties.stg_ccd > 70) {
        // 欠測の場合(河川水位内容コードが70超)
        ic = _mapWin.getTmMissIcon();
      } else {
        //超過判定 1:氾濫危険水位超過 2:避難判断水位超過 6:平常水位 9:判定不能（白）
        switch (feature.properties.stg_ovlvl) {
          case 0:
          case 10:
          case 30:
            i = 6;
            break;
          case 60:
            i = 2;
            break;
          case 80:
            i = 1;
            break;
          default:
            i = 9;
            break;
        }

        let chgFlg = _convChangeValue(feature.properties.stg_ltst_chg_ccd, feature.properties.stg_ltst_chg);

        const iconFlg = i + "_" + chgFlg;
        //console.log("overobs: iconFlg = " + iconFlg);

        switch (iconFlg) {
          // 氾濫危険水位超過
          case "1_none":
            ic = _mapWin.getTmEmgNoChangeIcon();
            break;
          case "1_up":
            ic = _mapWin.getTmEmgUpIcon();
            break;
          case "1_down":
            ic = _mapWin.getTmEmgDownIcon();
            break;
          // 避難判断水位超過
          case "2_none":
            ic = _mapWin.getTmWrnNoChangeIcon();
            break;
          case "2_up":
            ic = _mapWin.getTmWrnUpIcon();
            break;
          case "2_down":
            ic = _mapWin.getTmWrnDownIcon();
            break;
          // 上記以外の場合はアイコンを表示しない。
          default:
            break;
        }
      }

      let mk = null;
      if (ic != null) {
        mk = L.marker(latlng, {
          icon: ic,
          properties: feature.properties,
          latlng: latlng,
          zIndexOffset: _CNST.Z_IDX + zIndex,
          pane: "paneOverTmObs",
        });
        //観測所クリック時の処理
        mk.on("click", clickObs);
      }
      return mk;
    },
  });
}

/**
 * テレメータ水位観測所表示
 * @param {number} zoom ズームレベル
 * ------------------------------------------------------------------------------
 */
async function dispTmObs(zoom) {
  //レイヤーグループの準備
  //表示レベルでない時に対象レイヤを持っていたら削除する
  if (!isRange(_config.tmObsRange, zoom)) {
    if (_mapWin.map.hasLayer(_tmObsLayerGroup)) {
      _mapWin.map.removeLayer(_tmObsLayerGroup);
      _tmObsLayerGroup.clearLayers();
      _tmObsGJSONs = [];
      _tmObsGJSONdatas = [];
    }
    return;
  }

  //テレメータ水位観測所レイヤの作成
  if (!_mapWin.map.hasLayer(_tmObsLayerGroup)) {
    _tmObsLayerGroup.setZIndex(_CNST.Z_IDX + 60);
    _mapWin.map.addLayer(_tmObsLayerGroup);
  }

  //既存の読み込み済みGEOJSONで再取得しなくて良いものは再利用する。
  for (const idx in _tmObsGJSONs) {
    let hit = false;
    for (const ix in _nowAreasInfo.towns) {
      if (idx == _nowAreasInfo.towns[ix].mlitCode + "") {
        hit = true;
      }
    }
    if (!hit) {
      _tmObsLayerGroup.removeLayer(_tmObsGJSONs[idx]);
      delete _tmObsGJSONs[idx];
    }
  }

  //既存の読み込み済みGEOJSONで配列_tmObsGJSONs内にないものをリクエストする。
  const mlitCodes = [];
  for (const i in _nowAreasInfo.towns) {
    mlitCodes[_nowAreasInfo.towns[i].mlitCode] =
      _nowAreasInfo.towns[i].mlitCode;
  }

  const tm_url = _config.tmApiUrl + "/system/tmCrntTime.json";
  let crntDate;
  await $.ajax({
    type: "GET",
    url: tm_url,
    dataType: "json",
  }).done((data, status, jqXHR)=> {
    crntDate = new Date(data.crntObsTime);
  }).fail(()=> {
    throw "観測時刻の取得に失敗しました。";
  });

  //for (var i in _nowAreasInfo.towns) {
  //  var areaCode = _nowAreasInfo.towns[i].mlitCode + "";
  for (const areaCode in mlitCodes) {
    if (_tmObsGJSONs[areaCode] == null) {
      // 除外リストを取得し、GeoJSON生成に渡す処理を追加（川防参照対応）
      const omitUrl = "/swin/files/omt/" + areaCode + ".json";
      let omitList = null;
      if (_tmOmitObs[areaCode] == null) {
        await $.ajax({
          type: "GET",
          url: omitUrl,
          dataType: "json",
        }).then(
          (data, status, jqXHR) => {
            omitList = data;
            _tmOmitObs[areaCode] = omitList;
          },
          () => {
            omitList = null;
          }
        ).catch(() => {
          omitList = null;
        });
      } else {
        omitList = _tmOmitObs[areaCode];
        //console.log("case 3");
      }
      //console.log("case xxx");


      //var url = _config.tmGeoJsonUrl + "obsGeo/" + areaCode + ".json";
      const url = _config.tmGeoJsonUrl + "/obs/" + DateFormatUtil.format(crntDate, "YYYYMMDD") + "/"
                                     + DateFormatUtil.format(crntDate, "hhmm") + "/stg/" + (areaCode - 0) + ".json";
      $.ajax({
        url: url,
        beforeSend: function (jqXHR, settings) {
          jqXHR.requestURL = settings.url;
        },
      })
        .then((gjson, textResponse, jqXHR) => {
          //エリアコードをURLから取得する
          var areaCode = jqXHR.requestURL.match(
            ".+/(.+?).[a-z]+([?#;].*)?$"
          )[1];

          //GEOJsonデータを格納しておく
          _tmObsGJSONdatas[areaCode] = gjson;

          //GEOJsonを作成し配列に格納
          _tmObsGJSONs[areaCode] = createTmObsJson(gjson, omitList, 61);
          _tmObsGJSONs[areaCode].setZIndex(_CNST.Z_IDX + 60);
          _tmObsLayerGroup.addLayer(_tmObsGJSONs[areaCode]);
        })
        .catch((e) => {});
    } else {
      const omitList = _tmOmitObs[areaCode];
      _tmObsLayerGroup.removeLayer(_tmObsGJSONs[areaCode]);
      delete _tmObsGJSONs[areaCode];
      _tmObsGJSONs[areaCode] = createTmObsJson(_tmObsGJSONdatas[areaCode], omitList, 61);
      _tmObsGJSONs[areaCode].setZIndex(_CNST.Z_IDX + 60);
      _tmObsLayerGroup.addLayer(_tmObsGJSONs[areaCode]);
    }
  }

  //サイドメニューの水位計check判定
  if (!$("#tmObsCheckBox").prop("checked")) {
    _mapWin.map.removeLayer(_tmObsLayerGroup);
  }
}


function _convChangeValue(chg_ccd, chg) {
  let ret = "";
  //変化量判定 none:変化なし up:上昇 down:下降
  if (chg_ccd > 70) {
    ret = "none";
  } else {
    if (chg > 0) {
      ret = "up";
    } else if (chg < 0) {
      ret = "down";
    } else {
      ret = "none";
    }
  }
  return ret;
}

/**
 * テレメータ水位観測所レイヤ―作成処理
 * @param {object} gjson GeoJsonデータ
 * @param {number} zIndex アイコンのインデックス
 * ------------------------------------------------------------------------------
 */
function createTmObsJson(gjson, omitList, zIndex) {
  return L.geoJson(gjson, {
    //観測所を出す処理
    pointToLayer: function (feature, latlng) {

      //OmitListに登録されている観測所の場合は表示しない。
      if (omitList != null) {
        for (const obs of omitList.obslist) {
          if (obs == feature.properties.obs_fcd) {
            //console.log("omit!!: " + obs + "/" + feature.properties.obs_fcd);
            return null;
          }
        }
      }
      //console.log("not omit!!: " + feature.properties.obs_fcd);

      //表示するアイコンの設定
      let ic = null;

      //川防の市町村別水位観測所リストGeoJSONで使用するデータ項目
      //変化量関連２つ stg_ltst_chg（変化量）、stg_ltst_chg_ccd（変化量内容コード）
      //観測時刻１つ obs_time（観測時刻）
      //超過フラグ１つ stg_ovlvl（超過）
      //  超過フラグ内容：
      //     0:超過無し、
      //    10:水防団待機水位超過、
      //    30:氾濫注意水位超過、
      //    60:避難判断水位超過、
      //    80:氾濫危険水位超過、
      //    90:氾濫開始水位超過
      //観測値の内容コード１つ stg_ccd（内容コード）
      //計５つの変数を使用

      if (
        feature.properties.stg_ltst_chg == null &&
        feature.properties.stg_ltst_chg_ccd == null &&
        feature.properties.obs_time == null &&
        feature.properties.stg_ovlvl == null &&
        feature.properties.stg_ccd == null
      ) {
        ic = _mapWin.getTmMissIcon();
      } else if (feature.properties.stg_ccd > 70) {
        // 欠測の場合(河川水位内容コードが70超)
        ic = _mapWin.getTmMissIcon();
      } else {
        //超過判定 1:氾濫危険水位超過 2:避難判断水位超過 6:平常水位 9:判定不能（白）
        if (feature.properties.spcl_warn_stg == null && feature.properties.dng_stg == null) {
          i = 9;
        } else {
          switch (feature.properties.stg_ovlvl - 0) {
            case 0:
              i = 6;
              break;
            case 10:
              i = 6;
              break;
            case 30:
              i = 6;
              break;
            case 60:
              i = 2;
              break;
            case 80:
              i = 1;
              break;
            default:
              i = 9;
              break;
          }
        }
        //console.log("[" + i + "] stg_ovlvl: " + feature.properties.stg_ovlvl + "  obsFcd: " + feature.properties.obs_fcd);

        const chgFlg = _convChangeValue(feature.properties.stg_ltst_chg_ccd, feature.properties.stg_ltst_chg);

        const iconFlg = i + "_" + chgFlg;

        switch (iconFlg) {
          // 氾濫危険水位超過
          case "1_none":
            ic = _mapWin.getTmEmgNoChangeIcon();
            break;
          case "1_up":
            ic = _mapWin.getTmEmgUpIcon();
            break;
          case "1_down":
            ic = _mapWin.getTmEmgDownIcon();
            break;
          // 避難判断水位超過
          case "2_none":
            ic = _mapWin.getTmWrnNoChangeIcon();
            break;
          case "2_up":
            ic = _mapWin.getTmWrnUpIcon();
            break;
          case "2_down":
            ic = _mapWin.getTmWrnDownIcon();
            break;
          // 平常水位
          case "6_none":
            ic = _mapWin.getTmNmlNoChangeIcon();
            break;
          case "6_up":
            ic = _mapWin.getTmNmlUpIcon();
            break;
          case "6_down":
            ic = _mapWin.getTmNmlDownIcon();
            break;
          // 基準なし観測所水位
          case "9_none":
            ic = _mapWin.getTmNoStdNoChangeIcon();
            break;
          case "9_up":
            ic = _mapWin.getTmNoStdUpIcon();
            break;
          case "9_down":
            ic = _mapWin.getTmNoStdDownIcon();
            break;
          default:
            ic = _mapWin.getTmNmlNoChangeIcon();
            break;
        }
      }

      let mk = null;
      if (ic != null) {
        mk = L.marker(latlng, {
          icon: ic,
          properties: feature.properties,
          latlng: latlng,
          zIndexOffset: _CNST.Z_IDX + zIndex,
          pane: "paneTmObs",
        });
        //観測所クリック時の処理
        mk.on("click", clickObs);
      }
      return mk;
    },
  });
}

/**
 * 任意情報表示
 * @param {Object} info 任意情報
 * ------------------------------------------------------------------------------
 */
function dispOptionalInfo(info) {
  _opInfo.create(info, _config);
}

/**
 * 取得地域情報
 * @param {Array} areas 取得地域
 * ------------------------------------------------------------------------------
 */
async function createAreaLayers(areas) {
  for (var ix in areas) {
    if (_areaLayers[areas[ix].code] == null) {
      var url =
        _config.geoJsonUrl +
        "area/rough/" +
        formatAreaCode(areas[ix].code + "") +
        ".json";
      await $.getJSON(url, function (json) {
        if (areas[ix].count.over != 0 || areas[ix].count.obs != 0) {
          var layerJson = null;
          if (areas[ix].count.fld_over > 0) {
            layerJson = L.geoJson(json, {
              pane: "paneArea",
              style: _config.floodHighlightStyle,
            });
          } else if (areas[ix].count.warn_over > 0) {
            layerJson = L.geoJson(json, {
              pane: "paneArea",
              style: _config.warnHighlightStyle,
            });
          } else if (areas[ix].count.start_over > 0) {
            layerJson = L.geoJson(json, {
              pane: "paneArea",
              style: _config.startHighlightStyle,
            });
          } else {
            layerJson = L.geoJson(json, {
              pane: "paneArea",
              style: _config.highlightStyle,
            });
          }

          // 二重セット対策の為、再チェック
          if (_areaLayers[areas[ix].code] == null) {
            _areaLayers[areas[ix].code] = layerJson;
          }
        }
      })
        .then(function () {})
        .catch(function () {
          _areaLayers[areas[ix].code] = -1;
        });
    } else {
      if (areas[ix].count.over != 0 || areas[ix].count.obs != 0) {
        var style = null;
        if (areas[ix].count.fld_over > 0) {
          style = _config.floodHighlightStyle;
        } else if (areas[ix].count.warn_over > 0) {
          style = _config.warnHighlightStyle;
        } else if (areas[ix].count.start_over > 0) {
          style = _config.startHighlightStyle;
        } else {
          style = _config.highlightStyle;
        }
        _areaLayers[areas[ix].code].setStyle(style);
      } else {
        areas[ix]["remove"] = 1;
      }
    }
  }
}

/**
 * 地域コード頭０埋めフォーマット
 * ５桁にして返す。
 * @param {string} code 地域コード
 * @return {string} ５桁に桁埋めした地域コード
 * ------------------------------------------------------------------------------
 */
function formatAreaCode(code) {
  if (code.length == 4) return "0" + code;
  return code;
}

/**
 * CCTVカメラアイコンクリック処理
 * @param {Object} e イベントモジュール
 */
function clickCctv(e) {
  //観測所ダイアログを表示するためのパラメータ作成
  var param = {
    requester: _req,
    latlng: e.target.options.latlng,
    cctv: e.target.options.properties,
    msgBox: _messageBox,
  };

  //ファイル取得処理の抑制設定
  setObsDialog();

  //地図の表示変更
  _mapWin.map.setView(param.latlng, 15);
  setTimeout(flayObsPosition, 700, param.cctv, param.latlng);

  if (e.target.options.properties.cctvCode == null) {
    param.type = "swin";
    //ダイアログ表示
    _obsDlg.showCctv(param);
  } else {
    param.type = "TM";
    //ダイアログ表示
    _obsDlg.showTmCctv(param);
  }
}

/**
 * 川防のGeoJSONのPropertiesを英語版のGeoJSONのPropertiesに変換
 * @param {geojson_properties} prop 川防GeoJSONの水位観測所のproperties
 * @returns 英語版GeoJSONのProperties
 */
function _changePropertiesToObs(prop) {
  const cg = _convChangeValue(prop.stg_ltst_chg_ccd, prop.stg_ltst_chg);

  const resutl = {
    obsCode: prop.obs_fcd,
    over: prop.stg_ovlvl,
    rsysName: null,
    riverName: null,
    obsName: prop.obs_nm,
    change: cg,
    obsTime: prop.obs_time,
    stageFlg: prop.stg_ccd,
  }

  return resutl;
}

/**
 * 観測所アイコンクリック処理
 * @param {Object} e イベントモジュール
 * ------------------------------------------------------------------------------
 */
function clickObs(e) {
  // 観測所の観測日時取得
  let date = "";
  const code = e.target.options.properties.code;
  if (_nowCurrentLevels) {
    const levels = _nowCurrentLevels.filter((rec) => {
      return rec.code == code;
    });
    if (levels.length > 0) {
      date = levels[0].date;
    }
  } else {
    date = DateFormatUtil.format(new Date(), "YYYY-MM-DDThh:mm:00+09:00");
  }
  //console.log("DEBUG: date", date);
  let obs = null;
  if (e.target.options.properties.obs_fcd) {
    obs = _changePropertiesToObs(e.target.options.properties);
  } else {
    obs = e.target.options.properties;
  }

  //観測所ダイアログを表示するためのパラメータ作成
  const param = {
    requester: _req,
    latlng: e.target.options.latlng,
    //obs: e.target.options.properties,
    obs: obs,
    date: date,
    msgBox: _messageBox,
  };

  //ファイル取得処理の抑制設定
  setObsDialog();

  //地図の表示変更
  _mapWin.map.setView(param.latlng, 15);
  setTimeout(flayObsPosition, 700, param.obs, param.latlng);

  //特殊横断設定の判定
  for (let i = 0; i < _spSurfaceList.length; i++) {
    let cd = 0;
    if (isEmpty(param.obs.code)) {
      cd = param.obs.obsCode;
    } else {
      cd = param.obs.code;
    }
    if (cd === _spSurfaceList[i].obsCode) {
      param.spSurface = _spSurfaceList[i];
      break;
    }
  }

  if (e.target.options.properties.obs_fcd == null) {
    param.type = "swin";
    //ダイアログ表示
    _obsDlg.showObs(param);
  } else {
    param.type = "TM";
    //ダイアログ表示
    _obsDlg.showTmObs(param);
  }
}

/**
 * 観測所ダイアログ表示時のファイル取得抑制処理
 * ------------------------------------------------------------------------------
 */
function setObsDialog() {
  //観測所ダイアログ表示フラグ
  _dialogFlag = true;

  // ダイアログの初回表示時にイベントを設定
  if (!_dialogShowFlag) {
    _dialogShowFlag = true;

    // 観測所ダイアログを閉じた時のファイル読込処理設定
    $("#obsDlgClose, #msgBox > img").on("click", function () {
      if (_dialogFlag) {
        if (_mapEvTimerId != null) {
          clearTimeout(_mapEvTimerId);
        }

        _dialogFlag = false;
        ctrlContents();
      }
    });
  }
}

/**
 * 観測所位置移動
 * @param {Object} obs 観測所情報
 * @param {Object} latlng 観測所の緯度経度
 * ------------------------------------------------------------------------------
 */
function flayObsPosition(obs, latlng) {
  var lb = _mapWin.map.getBounds();
  var latpv = (lb.getSouthWest().lat - lb.getNorthEast().lat) / 4;
  var lngpv = (lb.getSouthWest().lng - lb.getNorthEast().lng) / 4;
  var newLatlng = [
    {
      lat: latlng.lat + latpv,
      lng: latlng.lng,
    },
    {
      lat: latlng.lat,
      lng: latlng.lng - lngpv,
    },
  ];

  if (window.innerHeight > window.innerWidth) {
    _mapWin.map.flyTo(newLatlng[0], 15);
  } else {
    _mapWin.map.flyTo(newLatlng[1], 15);
  }
}

/**
 * 範囲設定内判定
 * @param {Object} range
 * @param {number} val
 * ------------------------------------------------------------------------------
 */
function isRange(range, val) {
  if (val >= range.min && val <= range.max) {
    return true;
  }
  return false;
}

/**
 * URL書き換え処理
 * @param {number} zoom 現在ズーム
 * ------------------------------------------------------------------------------
 */
function rewriteUrl(zoom) {
  //ズーム文字列作成
  var zoomParam = "zm=" + zoom;

  //現在位置作成
  var latLng = _mapWin.getCenter();
  var latParam = "clat=" + latLng.lat;
  var lonParam = "clon=" + latLng.lng;

  //地図タイルインデックス
  var tileParam = "t=" + _mapWin.getTileIndex();

  //観測所表示状態
  var dpObsParam = "dobs=" + _mapWin.dispOBS;
  var dpRvrParam = "drvr=" + _mapWin.dispRVR;
  var dpCctvParam = "dtv=" + _mapWin.dispCCTV;
  var dpTmObsParam = "dtmobs=" + _mapWin.dispTMOBS;
  var dpTmCctvParam = "dtmtv=" + _mapWin.dispTMCCTV;

  var prmStr =
    "?" +
    zoomParam +
    "&" +
    latParam +
    "&" +
    lonParam +
    "&" +
    tileParam +
    "&" +
    dpObsParam +
    "&" +
    dpRvrParam +
    "&" +
    dpCctvParam +
    "&" +
    dpTmObsParam +
    "&" +
    dpTmCctvParam;

  var urlStr = _config.topUrl + prmStr;
  if (_cityToken != null) {
    urlStr = urlStr + "&tk=" + _cityToken;
  }
  history.replaceState("", "", urlStr);
  return urlStr;
}

//*******************************************************************************
//画面コントロール系イベント
//*******************************************************************************

/**
 * サイドメニュー表示ボタンクリック
 * @param {object} evnt JavaScriptイベントオブジェクト
 * ------------------------------------------------------------------------------
 */
function sideMenuMBtnClick(evnt) {

  var cctvCallback = function() {
    var zoom = _mapWin.getZoom();
    dispCctv(zoom,true);
  }
  

  _sideMenu.show(
    _obsLayerGroup,
    _cctvLayerGroup,
    _riverLayerGroup,
    _overTmObsLayerGroup,
    _tmObsLayerGroup,
    _mapWin,
    _CNST.Z_IDX,
    cctvCallback
  );
}

/**
 * 更新ボタンクリック
 * @param {object} evnt JavaScriptイベントオブジェクト
 * ------------------------------------------------------------------------------
 */
function renewMBtnClick(evnt) {
  //URL変更処理を実行
  var url = rewriteUrl(_mapWin.map.getZoom());
  //location.href = 変更したURL
  location.href = url;
}

/**
 * 現在位置表示ボタン（トグル）クリック
 * @param {object} evnt JavaScriptイベントオブジェクト
 * ------------------------------------------------------------------------------
 */
function locationMBtnClick(evnt) {
  if (evnt.data.instance.element.getExtVal("toggle")) {
    PageLog.log(_config.pageLogUrl.gpsUrl);

    var z = _mapWin.map.getZoom();
    //_mapWin.map.locate({ setView: true, maxZoom: z });
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(locationSuccess, locationError, {
        timeout: 5000,
        maximumAge: 30000,
      });
    }
  } else {
    _locationMaker.remove();
  }
}

/**
 * 観測所一覧ボタンクリック
 * @param {object} evnt JavaScriptイベントオブジェクト
 * ------------------------------------------------------------------------------
 */
function obslistMBtnClick(evnt) {
  _listDlg.show({
    areas: _nowAreas,
    rivers: _nowRivers,
    requester: _req,
    selectObs: listSelect,
  });
}

/**
 * 凡例表示ボタンクリック
 * @param {object} evnt JavaScriptイベントオブジェクト
 * ------------------------------------------------------------------------------
 */
function legendMBtnClick(evnt) {
  if (evnt.data.instance.element.getExtVal("toggle")) {
    _legendBox.show();
  } else {
    _legendBox.hide();
  }
}

/**
 * 観測所ダイアログで観測所をクリックした際の処理
 * @param {Object} obs クリックした観測所の情報
 * ------------------------------------------------------------------------------
 */
function listSelect(obs) {
  _listDlg.close();

  var latlng = {
    lat: obs.lat,
    lng: obs.lon,
  };
  _mapWin.map.flyTo(latlng, 15);
}

/**
 * メニューボタン表示切替ボタンクリック
 * @param {object} evnt JavaScriptイベントオブジェクト
 * ------------------------------------------------------------------------------
 */
function clickMenuDispBtn(evnt) {
  var mode = _menuDispBtn.changeMode();
  if (mode) {
    _menuPanel.showAll();
  } else {
    _menuPanel.hideAll();
  }
}

/**
 * 初回アクセス判定
 */
function checkFirstAccess() {
  // ストレージ内の前回アクセス日付の取得
  var storageDateStr = localStorage.getItem("access");

  // 前回アクセス日付と現在日付の差分
  var daysDiff = null;
  if (storageDateStr != null) {
    var dateStrArr = storageDateStr.split("/");
    if (dateStrArr.length == 3) {
      var storageDate = new Date(
        dateStrArr[0],
        dateStrArr[1] - 1,
        dateStrArr[2]
      );
      // 現在時刻の取得
      var nowDate = new Date();
      var msDiff = nowDate.getTime() - storageDate.getTime();
      daysDiff = Math.floor(msDiff / (1000 * 60 * 60 * 24));
    }
  }

  if (
    storageDateStr == null ||
    isNaN(daysDiff) ||
    daysDiff > 30 ||
    dateStrArr.length !== 3
  ) {
    _firstAccessDlg = new FirstAccessDlg(_config);

    //htmlをロードする
    var firstaccessUrl = _config.sidemenuSettings.firstaccessUrl;
    if (firstaccessUrl != null && firstaccessUrl != "") {
      $.ajax({
        url: firstaccessUrl,
      })
        .then((htmlStr, textResponse, jqXHR) => {
          _firstAccessDlg.firstaccessHtml = htmlStr;
          _firstAccessDlg.show();
        })
        .catch((e) => {});
    }
    var nowDateStr = DateFormatUtil.format(new Date(), "YYYY/MM/DD");
    localStorage.setItem("access", nowDateStr);
  }
}

/**
 * 特殊横断設定の取得
 */
function getSpSurfaceList() {
  //var url = "surface/spSurfaceList.json";
  var url = _config.spSurfaceListPath;
  $.ajax({
    url: url,
  })
    .then((json) => {
      if (json.obs_list == null) {
        _spSurfaceList = [];
        return;
      }
      var values = json.obs_list.map((data) => {
        var val = {
          obsCode: data.obsCode,
          disp: data.disp,
          max: data.max,
          min: data.min,
        };
        return val;
      });
      _spSurfaceList = values;
    })
    .catch(() => {
      _spSurfaceList = [];
    });
}
